import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import classNames from "classnames";
// import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import { Transition } from "./support";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ComboFilterDialogComponent = ({
  closeClick,
  isOpen,
  handleJsonExportBtnClick,
  handleCsvExportBtnClick,
  postExportData,
}) => {
  const classes = useStyles();

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={closeClick}
      scroll="paper"
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      fullWidth={false}
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="scroll-dialog-title"
        className={classes.root}
      >
        {/* <Typography variant="h6">{label}</Typography> */}
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={closeClick}
          toolTip="Zavieť dialógové okno"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <IconButton
            onClick={handleJsonExportBtnClick}
            toolTip="Stiahnuť JSON súbor"
          >
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={handleJsonExportBtnClick}
            color="black"
          >
            JSON
          </Button>
        </div>
        <div>
          <IconButton
            onClick={handleCsvExportBtnClick}
            toolTip="Stiahnuť CSV súbor"
          >
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={handleCsvExportBtnClick}
            color="black"
          >
            CSV
          </Button>
        </div>
        <div>
          <IconButton
            onClick={() => postExportData("xlsx")}
            toolTip="Stiahnuť XLSX súbor"
          >
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={() => postExportData("xlsx")}
            color="black"
          >
            XLSX
          </Button>
        </div>
        <div>
          <IconButton
            onClick={() => postExportData("pdf")}
            toolTip="Stiahnuť PDF súbor"
          >
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={() => postExportData("pdf")}
            color="black"
          >
            PDF
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ComboFilterDialogComponent.propTypes = {
  closeClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCsvExportBtnClick: PropTypes.func.isRequired,
  handleJsonExportBtnClick: PropTypes.func.isRequired,
  postExportData: PropTypes.func.isRequired,
  // onClick: PropTypes.func,
};

export default ComboFilterDialogComponent;
