import React from 'react';
import TextFields from '@material-ui/icons/TextFields';
import PropTypes from 'prop-types';

class TextFieldsComponent extends React.Component {
  render() {
    return (
      <TextFields
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    );
  }
}

TextFieldsComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
};

export default TextFieldsComponent;