import React from 'react';
import PropTypes from 'prop-types';
import Delete from '@material-ui/icons/Pets';


class DogIconComponent extends React.Component {
  render() {
    return (
      <Delete
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
      />
    );
  }
}

DogIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default DogIconComponent;
