import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class DebtorsIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M13,14c0,0.6-0.4,1-1,1H8
		c-0.6,0-2-3.4-2-4V9.4V8c0-0.6,1.4-4,2-4h4c0.6,0,1,0.4,1,1"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="8" x2="11" y2="8" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="4" y1="11" x2="11" y2="11" />
        </g>
      </SvgIcon>
    );
  }
}

DebtorsIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default DebtorsIconComponent;
