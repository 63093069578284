import { List, ListItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router";
import Button from "../../coraWebMComponents/inputs/Button.js";
import getSharedStyles from "../../coraWebMComponents/sharedStyles.js";
import { withLocalizationConsumer } from "../../coraWebMComponents/utils/withLocalization.js";
import MapDialogDMParking from "./ZoneMapDialogText.js";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  gridBorder: {
    marginTop: 15,
    border: "solid rgba(0,0,0,0.1) 1px",
    borderRadius: 0,
  },
  listWidth: {
    width: "80%",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    }

  },
  gridFlex: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  payBtn: {
    borderRadius: '30px',
    height: '55px',
    width: '40%',
  },
});

const MapDialogGrid = ({ times, classes, id, tooltip, ...otherprops }) => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/parkovanie", { state: { zona: id } });
  };
  const today = new Date().getDay() - 1;

  return (
    <>
      <MapDialogDMParking />
      <div className={classes.gridFlex}>
        <List classes={{ root: classNames(classes.listWidth, classes.gridBorder) }}>
          <ListItem dense style={{ padding: 0 }} key="0">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.05)",
                padding: 15,
                paddingBottom: 5,
                paddingTop: 5,
                width: "100%",
              }}
            >
              <Typography
                variant="body2"
                className={classes.textLeft}
                style={{ width: "20%" }}
              >
                {otherprops.translate(`parkingMap.day`)}
              </Typography>
              <Typography
                variant="body2"
                className={classes.textCenter}
                style={{ width: "20%" }}
              >
                {otherprops.translate(`parkingMap.timeFrom`)}
              </Typography>
              <Typography
                variant="body2"
                className={classes.textCenter}
                style={{ width: "20%" }}
              >
                {otherprops.translate(`parkingMap.timeTo`)}
              </Typography>
            </div>
          </ListItem>
          {times.map((time) => {
            return (
              <ListItem dense style={{ padding: 0 }} key={time.DEN}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor:
                      time.DEN % 2 === 0 ? "rgba(0,0,0,0.05)" : null,
                    padding: 15,
                    paddingBottom: 5,
                    paddingTop: 5,
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body2"
                    className={classes.textLeft}
                    style={{ width: "20%" }}
                  >
                    {otherprops.translate(`parkingMap.day${time.DEN}`)}
                  </Typography>
                  {time.D_DO ? (
                    <>
                      <Typography
                        variant="body2"
                        className={classes.textCenter}
                      >
                        {time.D_OD.replace(':', '.') + ' ' + otherprops.translate(`parkingShort.hours`)}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.textCenter}
                      >
                        {time.D_DO.replace(':', '.') + ' ' + otherprops.translate(`parkingShort.hours`)}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="body2"
                      className={classes.textCenter}
                      style={{ width: "70%" }}
                    >
                      {otherprops.translate("parkingMap.free")}
                    </Typography>
                  )}
                </div>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div style={{ paddingTop: 15 }}>
        <Typography variant="h6">{times[today] && times[today].C_MIN_J2 !== undefined && times[today].C_MIN_J2 !== null && otherprops.translate('parkingShort.firstRate') + ":"} {tooltip === 'bezplatné' ? otherprops.translate("parkingMap.freeRN") : tooltip}</Typography>
        {times[today] && times[today].C_MIN_J2 !== undefined && times[today].C_MIN_J2 !== null && <Typography variant="h6">{otherprops.translate('parkingShort.secondRate')}: {times[today]?.C_MIN_J_SK2}</Typography>}
        {times[today] && times[today].C_MIN_J3 !== undefined && times[today].C_MIN_J3 !== null && <Typography variant="h6">{otherprops.translate('parkingShort.thirdRate')}: {times[today]?.C_MIN_J_SK3}</Typography>}
      </div >
      <div style={{ paddingTop: 15 }}>
        <Button
          disabled={tooltip === "bezplatné"}
          onClick={redirect}
          variant="contained"
          type="success"
          color="secondary"
          className={classNames(classes.payBtn)}
        >
          {otherprops.translate("parkingMap.park")}
        </Button>
      </div>
    </>
  );
};

MapDialogGrid.propTypes = {
  times: PropTypes.array,
  id: PropTypes.number.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default withLocalizationConsumer(withStyles(styles)(MapDialogGrid));
