import React from 'react';
import EuroSymbol from '@material-ui/icons/EuroSymbol';
import PropTypes from 'prop-types';

class EuroSymbolIconComponent extends React.Component {
  render() {
    return (
      <EuroSymbol
        style={this.props.style}
      />
    )
  }
}

EuroSymbolIconComponent.propTypes = {
  style: PropTypes.object
}

export default EuroSymbolIconComponent;
