import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class ImportantObjectsIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M13,12.4
		c1.8,0.4,3,1.2,3,2.1c0,1.4-2.9,2.5-6.5,2.5S3,15.9,3,14.5c0-0.9,1.2-1.7,3-2.1"/>
          <g>
            <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
			M6,5.8C6,3.5,7.6,2,9.5,2C11.4,2,13,3.5,13,5.8C13,7.6,9.5,14,9.5,14S6,7.6,6,5.8z"/>
          </g>
          <circle fill="none" strokeMiterlimit="10" cx="9.5" cy="5.5" r="1" />
        </g>
      </SvgIcon>
    );
  }
}

ImportantObjectsIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default ImportantObjectsIconComponent;
