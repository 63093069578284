import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import classNames from 'classnames';
// import TextField from '../../coraWebMComponents/inputs/TextField';
import sortBy from 'lodash/sortBy';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@material-ui/icons/Close';
import { AutoSizer } from 'react-virtualized';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import IconButton from '../../coraWebMComponents/inputs/IconButton';
import FlatButton from '../../coraWebMComponents/inputs/FlatButton';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import { ColumnFlagParser } from '../../coraWebMComponents/utils/helper';

import * as adminModeUtils from '../../utils/adminModeUtils.js';
import { Transition } from './support';
import VirtualizedTable from './VirtualizedTable';
import * as userActions from '../../actions/userActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    boxSizing: "border-box"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit * 2,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  data1: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: "border-box"
  },
  dialogTitle: {
    fontSize: 24,
  },
  helpBoxWrapper: {
    backgroundColor: theme.palette.grey[200],
    boxSizing: "border-box",
    borderRadius: "0.3rem",
  },
  helpBoxTitle: {
  },
  helpBoxInfoIcon: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[400]

  },
  helpBoxItem: {
    marginBottom: '-0.2rem',
  },
  helpBoxLegenda: {
    width: "2.5rem",
    marginBottom: '-0.2rem',
  },
  helpBoxGraph: {
    height: '0.3rem',
  }
});

class AdminModeSpColumnsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      spColumns: null,
      rows: null,
    };
  }

  getColumns(classes) {
    const columns = [];

    columns.push({
      name: 'PorMriez',
      header: 'Poradie',
      width: 72,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          // variant="outlined"
          value={rowData['PorMriez']}
          onChange={this.handleChange(rowData.id, 'PorMriez')}
        />),
      cellProps: { align: 'left' }
    });

    columns.push({
      name: 'TtMriez',
      header: 'Titulok',
      width: 300,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          // variant="outlined"
          value={rowData['TtMriez']}
          onChange={this.handleChange(rowData.id, 'TtMriez')} />),
      cellProps: { align: 'left' }
    });

    // columns.push({
    //   name: 'TtMriez',
    //   header: 'Titulok v miežke',
    //   width: 300,
    //   cell: rowData => (
    //     <TextField
    //       id="outlined-bare"
    //       className={classes.textField}
    //       margin="normal"
    //       // variant="outlined"
    //       value={rowData['TtMriez']}
    //       onChange={this.handleChange(rowData.id, 'TtMriez')} />),
    //   cellProps: { align: 'left' }
    // });

    columns.push({
      name: 'LMriez',
      header: <div>Zobraz<br />v miežke</div>,
      cell: rowData => (
        <Checkbox
          checked={rowData['LMriez']}
          onChange={this.handleChange(rowData.id, 'LMriez')} />
      ),
      width: 72,
      cellProps: { style: { paddingRight: 0 } },
    });

    columns.push({
      name: 'LDet',
      header: <div>Zobraz<br />v detaile</div>,
      cell: rowData => (
        <Checkbox
          checked={rowData['LDet']}
          onChange={this.handleChange(rowData.id, 'LDet')} />
      ),
      width: 72,
      cellProps: { style: { paddingRight: 0 } },
    });

    const sirName = 'SirMriez';
    columns.push({
      name: sirName,
      header: <div>Šírka<br />v miežke</div>,
      width: 80,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          // variant="outlined"
          value={rowData[sirName]}
          onChange={this.handleChange(rowData.id, sirName)} />),
      cellProps: { align: 'left' }
    });
    return columns;
  }

  handleChange = (id, name) => event => {
    const { spColumns } = this.state;
    const rowData = spColumns.find(x => x.ISpFrmSql === id);
    switch (event.target.type) {
      case 'checkbox':
        if (name === 'LMriez' || name === 'LDet') {
          const fieldName = "I" + name.substring(1);
          const cfp = new ColumnFlagParser(rowData[fieldName]);
          if (cfp.lVisible !== event.target.checked) {
            cfp.lVisible = event.target.checked;
            rowData[fieldName] = cfp.iColumnFlag;
          }
        }
        rowData[name] = event.target.checked;
        break;

      default:
        rowData[name] = event.target.value;
        break;
    }
    this.setState({ spColumns });
    const rows = spColumns.map(rowData => {
      const row = { ...rowData, id: rowData['ISpFrmSql'] };
      return row;
    });
    this.setState({ rows });
  };

  /**
     * Nastavi rovnake hodnoty tym stlpcom, ktore su editovane jednym parametrom, ale hodnota sa ma nastavit do viacerych stlpcov. 
     * napr.: poradie, nazov
     *
     * @memberof AdminModeSpColumnsComponent
     */
  consolidateColumns = () => {
    const { spColumns } = this.state;
    spColumns.forEach(rowData => {
      rowData.PorDet = rowData.PorMriez;
      rowData.TtDet = rowData.TtMriez;
      rowData.SirDet = null; // v detaile nenastavujeme nikdy sirku
    });

    this.setState({ spColumns });
  };

  handleSaveClick = async () => {
    try {
      console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick.start`);
      this.setState({ isLoading: true });
      this.consolidateColumns();
      const data = await adminModeUtils.postSpColumns(this.props.primaryField, this.state.spColumns);
      console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick: postSpColumns {res: ${data}}`);
      this.setState({ spColumns: null, isLoading: false });
      this.props.onClose();
      console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick.end`);
      this.props.showMsg(data.UserMessage);
      await this.props.reloadSchema();
    }
    catch (e) {
      this.props.showErrorMsg("Error: Chyba pri komunikácii s databázou");
      console.log(`Error: ${e.message}`);
    }
  };

  async componentDidMount() {
    console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.start`);

    if (this.state.spColumns) {
      console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount: noload data {spColumns.length: ${this.state.spColumns.length}}`);
    }
    else {
      const data = await adminModeUtils.getSpColumns(this.props.primaryField);
      let newLocal = 0;
      try {
        // newLocal = data.Items.length;
        newLocal = data.Data.Items.length;
      } catch (error) {

      }

      console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.getSpColumns: [res=${newLocal}]`);
      this.setState({ spColumns: sortBy(data.Data.Items, rowData => rowData['PorMriez'] ? rowData['PorMriez'] : 1000000000 + rowData['ISpFrmSql']), isLoading: false }, () => {
        console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount: loaded data {spColumns.length: ${this.state.spColumns.length}}`);
      });

      const rows = sortBy(data.Data.Items, rowData => rowData['PorMriez'] ? rowData['PorMriez'] : 1000000000 + rowData['ISpFrmSql'])
        .map(rowData => {
          const row = { ...rowData, id: rowData['ISpFrmSql'] };
          return row;
        });
      this.setState({ rows: rows });
    }

    console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.end`);
  }

  render() {
    console.log(`AdminModeSpColumns[${this.props.params.id}].render.start`);

    if (!this.state.spColumns) {
      console.log(`AdminModeSpColumns[${this.props.params.id}].render: {spColumns: null}`);
      // return <CircularProgress loading={this.state.isLoading} />;
      return null;
    }

    const { classes, _params } = this.props;
    // const isDetail = params.id === 'spDetCols';
    // const porName = isDetail ? 'PorDet' : 'PorMriez';
    // const porName = ['PorMriez', 'PorDet'];
    const columns = this.getColumns(classes);

    const { spColumns, rows } = this.state;
    console.log(`AdminModeSpColumns[${this.props.params.id}].render: {spColumns.length: ${this.state.spColumns.length}}`);

    const HelpBanner = ({ helpData }) => {
      return (
        <Stack mt={1} p={1} flexDirection="column" className={classes.helpBoxWrapper} >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
            className={classes.helpBoxWrapper}
            position="relative"
          >
            <Typography variant="caption" className={classes.helpBoxTitle} whiteSpace="nowrap">
              Orientačné šírky stĺpcov
            </Typography>
            <InfoOutlinedIcon fontSize="large" className={classes.helpBoxInfoIcon} />
          </Stack>
          {helpData.map((width, index) => (
            <Stack key={index} flexDirection="row" alignItems="center" className={classes.helpBoxItem} >
              <Typography variant="body2" className={classes.helpBoxLegenda}>{width}</Typography>
              <Box
                className={classes.helpBoxGraph}
                sx={{
                  width: `${width}px`,
                  backgroundColor: `hsl(200, ${40 + index * Math.floor((100 - 40) / (helpData.length - 1))}%, 40%)`,
                }}
              ></Box>
            </Stack>
          ))}
        </Stack>
      );
    };

    return (
      <>
        {/* <CircularProgress loading={this.state.isLoading} /> */}
        <Dialog
          open={this.props.params.isOpen}
          onClose={this.props.onClose}
          scroll='paper'
          maxWidth={'md'}
          aria-labelledby="scroll-dialog-title"
          fullWidth={true}
          fullScreen
          TransitionComponent={Transition}
        >
          <DialogTitle id="scroll-dialog-title"
          //  className={classes.root}
          >
            <Typography variant="body1" className={classes.dialogTitle}>{this.props.params.title}</Typography>
            <HelpBanner helpData={[50, 100, 150, 200, 300, 400]} />
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.props.onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box className={classNames(classes.data1)}>
              <AutoSizer>
                {({ width, height }) => (
                  // <form className={classes.container} noValidate autoComplete="off">
                  <VirtualizedTable
                    data={rows}
                    columns={columns}
                    width={width}
                    height={height}
                    includeHeaders={true}
                    fixedRowCount={1}
                    rowHeight={40}
                  // orderBy={columns[0]?.name}
                  // orderDirection={SortDirection.ASC}
                  // isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => rowData.id && rowData.id === hoveredRowData.id}
                  // cellProps={(column, rowData) => {

                  //   const res = rowData && rowData.id
                  //     ? {}
                  //     : { align: 'center' };
                  //   return res;
                  // }}
                  />
                  // </form>
                )}
              </AutoSizer>
            </Box>
          </DialogContent>
          <DialogActions>
            <FlatButton
              key='Cancel'
              label='Zrušiť'
              color='primary'
              onClick={this.props.onClose}
            >
              {`Zrušiť`}
            </FlatButton>
            <FlatButton
              key='Save'
              label='Uložiť'
              color='secondary'
              onClick={this.handleSaveClick}
            >
              {`Uložiť`}
            </FlatButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

AdminModeSpColumnsComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = () => {
};

const mapDispatchToProps = dispatch => ({
  showMsg: (msg) => { dispatch(userActions.showMsg(msg)); },
  showErrorMsg: (errorMsg) => { dispatch(userActions.showErrorMsg(errorMsg)); }
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminModeSpColumnsComponent));