import { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip, Polygon } from "react-leaflet";
import MapZoneDialog from "./ZoneMapDialog";
import PlacesMapDialog from "./PlacesMapDialog";
import { withLocalizationConsumer } from "../../coraWebMComponents/utils/withLocalization";
import * as mapConsts from "../../constants/mapConsts";

const MapPolygons = ({ polygons, translate, type }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [focusedPolygon, setFocusedPolygon] = useState(null);

  return (
    <>
      {polygons &&
        polygons.map((marker) => {
          return (
            <Polygon
              positions={marker.coordinates}
              pathOptions={{ color: marker.color }}
              key={marker.key}
              interactive={true}
              eventHandlers={{
                click: () => {
                  setFocusedPolygon(marker);
                  setDialogOpen(true);
                },
              }}
            >
              {marker.title &&
                <Tooltip
                  className="mapTooltip"
                  direction="auto"
                  offset={[0, 0]}
                  opacity={1}
                  permanent
                  sticky
                >
                  {marker.title}
                  <br />
                  {marker.toolTip === "bezplatné"
                    ? translate("parkingMap.freeRN")
                    : marker.toolTip}
                </Tooltip>
              }
            </Polygon>
          );
        })}
      {type === mapConsts.MAP_PLACES &&
        <PlacesMapDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setFocusedPolygon(null);
          }}
          focusedPolygon={focusedPolygon}
        />}
      {type === mapConsts.MAP_ZONES &&
        <MapZoneDialog
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setFocusedPolygon(null);
          }}
          focusedPolygon={focusedPolygon}
        />}
    </>
  );
};

MapPolygons.propTypes = {
  polygons: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.array.isRequired,
      color: PropTypes.string.isRequired,
      key: PropTypes.number.isRequired,
      title: PropTypes.string,
      toolTip: PropTypes.string,
    })
  ),
  type: PropTypes.number.isRequired,
};

export default withLocalizationConsumer(MapPolygons);
