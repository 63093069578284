import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class SubsidiesIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="5,9 9,10.5 
		16.5,6.6 17.2,9.3 8,14.7 1.4,12 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="10.9,9.2 8.2,6 
		4,5 2.3,7.1 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="0,5 2,5 2,14 
		0,14 	"/>
        </g>
      </SvgIcon>
    );
  }
}

SubsidiesIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default SubsidiesIconComponent;
