import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
}

const listCreditDraw = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_CREDIT_DRAW_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_LIST_CREDIT_DRAW_SUCCESS:
      return { isLoading: false, data: action.res.Data?.Items ?? [], total: action.res.Data?.TotalRecords ?? 0 }

    case actionTypes.GET_LIST_CREDIT_DRAW_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default listCreditDraw;