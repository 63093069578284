import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import _ from 'lodash';
import { withStyles } from "@material-ui/core/styles";
import Stack from '@mui/material/Stack';
import Paper  from '@mui/material/Paper';
import { Typography } from '@material-ui/core';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import * as convert from '../../coraWebMComponents/utils/convert';

const styles = theme => ({
  ...getSharedStyles(theme),
  gridTtitleWrapper: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.grey[300],
    // border: "1px solid #999",
  },

  gridTitleText: {
    color: theme.palette.secondary.main,
  },

  gridTitleDate: {
    color: theme.palette.secondary.light,
  },
});

const GridTitle = forwardRef(({ classes, isMobile = true, frmData }, refGridTitle) => {
  const befTxt = frmData.befTxt?.TxtFrm ?? "";
  const lastRefresh = convert.spacesToNbsp(frmData.syncMvw?.LastRefresh ?? "");

  return (
    <Paper
     elevation={isMobile ? 2 : 0} className={classNames(classes.cardRoot, isMobile ? classes.mb3 : classes.mb0)}
    >
      <Stack
        ref={refGridTitle}
        className={classNames(classes.gridTtitleWrapper)}
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "bottom" : "flex-end"} /* bottom: ak su pod sebou nech prvy je vlavo; flex-end: ak su vedla seba nech oba su na dolny okraj */
        py={2}
        px={1}
        spacing={1}
      // mb={isMobile ? 2 : 0}
      >
        <Typography className={classNames(classes.gridTitleText)} variant='body2' style={{ listStylePosition: "inside" }}
          dangerouslySetInnerHTML={{ __html: `${befTxt}` }}
        />
        <Stack
          className='gridTitleDate'
          spacing={1}
          direction={'row'}
          justifyContent={"flex-end"}  /* na pravy okraj */
          alignSelf={'flex-end'} /* na spodny okraj */
          flexWrap={"nowrap"}
          pl={3}
          pt={1}
        >
          <Typography className={classNames(classes.gridTitleDate)} variant='body2'>Aktualizácia&nbsp;údajov:</Typography>
          <Typography className={classNames(classes.gridTitleDate)} variant='body2'>{lastRefresh}</Typography>
        </Stack>
      </Stack >
    </Paper>
  );
});

GridTitle.propTypes = {
  classes: PropTypes.object,
  isMobile: PropTypes.bool,
  frmData: PropTypes.object.isRequired,
};

export default (withStyles(styles)(GridTitle));
