import React, { useCallback, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { EditOutlined } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ReactDOMServer from "react-dom/server";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import Editor from "../../coraWebMComponents/inputs/Editor";
import Button from "../../coraWebMComponents/inputs/Button";
import {
  adminModeSetEditedData,
  adminModeSetCurrEdited,
  adminModeUpdateOrAdd,
} from "../../actions/adminModeActions";
import { withLocalizationConsumer } from "../../coraWebMComponents/utils/withLocalization";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import { PARKING_DIALOG, IFrmTxt } from "../../constants/adminConsts";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  gridFlex: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
});

const MapDialogDMParking = ({
  classes,
  locale,
  translateWithLocaleAsync
}) => {
  const { adminMode } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [dmParkingEdit, setDmParkingEdit] = React.useState(false);
  const [dmParking, setDmParking] = React.useState("");
  const [tableNameEdit, setTableNameEdit] = React.useState(false);
  const [tableName, setTableName] = React.useState("");
  const [openSaveDialog, setOpenSaveDialog] = React.useState(false);

  const ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  const dmTextInit = useCallback(async () => {
    translateWithLocaleAsync(locale, "DM_PARKING_TICKET_MAP")
      .then((res) => {
        setDmParking(res[0]);
      });
  }, [locale, translateWithLocaleAsync]);

  const tableNameInit = useCallback(async () => {
    translateWithLocaleAsync(locale, "DM_PARKING_TIKET_MAP_TAB_NAME")
      .then((res) => {
        setTableName(res[0]);
      });
  }, [locale, translateWithLocaleAsync]);

  useEffect(() => {
    !openSaveDialog && dmTextInit() && tableNameInit();
  }, [dmTextInit, tableNameInit, openSaveDialog]);

  const handleCurrEdited = (value, type) => {
    dispatch(adminModeSetCurrEdited(value));
    dispatch(adminModeSetEditedData(null));
    switch (type) {
      case "DM_PARKING_TICKET_MAP":
        setDmParkingEdit(true);
        break;
      case "DM_PARKING_TIKET_MAP_TAB_NAME":
        setTableNameEdit(true);
        break;
      default:
        break;
    }
  };

  const handleEditData = (data, type) => {
    dispatch(
      adminModeSetEditedData([
        {
          TxtFrm: data,
          ID: 4003003,
          locale: locale,
          N_KLUC: type,
          IFrmTxt: 50,
        },
      ])
    );
  };

  const handleSaveSection = async () => {
    const { dataEdited } = adminMode;
    await dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
    await dispatch(adminModeSetCurrEdited(null));
    setOpenSaveDialog(false);
    setDmParkingEdit(false);
    setTableNameEdit(false);
  };

  return (
    <div>
      {dmParking && dmParkingEdit && (
        <Editor
          value={ReactToHtml(dmParking)}
          onChange={(value) => handleEditData(value, "DM_PARKING_TICKET_MAP")}
        />
      )}
      {!dmParkingEdit ? (
        <div className={classes.gridFlex}>
          <Typography
            variant="body2"
            className={classes.textCenter}
            style={{ fontWeight: "lighter" }}
          >
            {dmParking}
          </Typography>
          {adminMode.isActive && (
            <IconButton
              className={classNames(classes.ml3)}
              color="inherit"
              onClick={() => handleCurrEdited(PARKING_DIALOG, "DM_PARKING_TICKET_MAP")}
              toolTip="Editovat"
              toolTipPlace="right"
              disableHover={true}
            >
              <EditOutlined
                color="secondary"
                variant="fab"
                style={{ fontSize: 30 }}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{
            width: "200px",
          }}
          type="success"
          className={classNames(classes.w100, classes.textTransformNone)}
          onClick={() => setOpenSaveDialog(true)}
        >
          <SaveIcon className={classes.mr1} />
          Uložiť
        </Button>
      )}
      {tableName && tableNameEdit && (
        <Editor
          value={ReactToHtml(tableName)}
          onChange={(value) => handleEditData(value, "DM_PARKING_TIKET_MAP_TAB_NAME")}
        />
      )}
      {!tableNameEdit ? (
        <div className={classes.gridFlex}>
          <Typography
            variant="h6"
            className={classes.textCenter}
            style={{ root: classNames(classes.textCenter, classes.p0) }}
          >
            {tableName}
          </Typography>
          {adminMode.isActive && (
            <IconButton
              className={classNames(classes.ml3)}
              color="inherit"
              onClick={() => handleCurrEdited(PARKING_DIALOG, "DM_PARKING_TIKET_MAP_TAB_NAME")}
              toolTip="Editovat"
              toolTipPlace="right"
              disableHover={true}
            >
              <EditOutlined
                color="secondary"
                variant="fab"
                style={{ fontSize: 30 }}
              />
            </IconButton>
          )}
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          style={{
            width: "200px",
          }}
          type="success"
          className={classNames(classes.w100, classes.textTransformNone)}
          onClick={() => setOpenSaveDialog(true)}
        >
          <SaveIcon className={classes.mr1} />
          Uložiť
        </Button>
      )}
      {adminMode.isActive && (
        <Dialog
          modal={false}
          open={openSaveDialog}
          onClose={null}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: () => setOpenSaveDialog(false),
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: handleSaveSection,
              keyboardFocused: true,
            },
          ]}
        />
      )}
    </div>
  );
};

export default withLocalizationConsumer(withStyles(styles)(MapDialogDMParking));
