import React from 'react';
import DateRange from '@material-ui/icons/DateRange';
import PropTypes from 'prop-types';

class DateRangeIconComponent extends React.Component {
  render() {
    return (
      <DateRange
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    )
  }
}

DateRangeIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default DateRangeIconComponent;