import React from 'react';
import * as requests from './requests';

/**
 * Returns wrapped component with requests
 * @module
 * @param {element} WrappedComponent - Wrapped component
 */
const withRequests = (WrappedComponent) => {
  class HOC extends React.Component {

    render() {
      return (
        <WrappedComponent
          {...this.props}
          requests={{
            /**
             * Asynchronously returns response from get list of data.
             * @function getList
             * @param {string} url url
             * @param {object} filter filter
             * @param {object} sort sort
             * @param {object} page page
             * @param {object} args aditional arguments
             * @returns {Object}
             */
            getList: requests.getList,
            /**
             * Asynchronously returns response from get Total of data.
             * @function getList
             * @param {string} url url
             * @param {object} filter filter
             * @param {object} args aditional arguments
             * @returns {Object}
             */
            getTotal: requests.getTotal,
            /**
             * Asynchronously returns blob.
             * @function getBlob
             * @param {string} url url
             * @returns {Object}
             */
            getBlob: requests.getBlob,
            /**
             * Asynchronously returns response from creating code.
             * @function createCode
             * @param {string} username username
             * @returns {Object}
             */
            createCode: requests.createCode,
            /**
            * Asynchronously returns response from get redirect token.
            * @function getRedirectToken
            * @returns {Object}
            */
            getRedirectToken: requests.getRedirectToken,
            signInRedirect: requests.signInRedirect,
            /**
             * Asynchronously returns response from update password with code.
             * @function updatePasswordWithCode
             * @param {string} code code
             * @param {string} password password
             * @returns {Object}
             */
            updatePasswordWithCode: requests.updatePasswordWithCode,
            /**
             * Asynchronously get text for change password.
             * @function getChangePasswordText
             * @param {string} code code
             * @returns {Object}
             */
            getPattern: requests.getPattern
          }}
        />
      );
    }
  }

  return HOC;
};

export default withRequests;