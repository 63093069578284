import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router";
import classNames from 'classnames';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import List from "../../coraWebMComponents/dataDisplay/List";
import ListItem from "../../coraWebMComponents/dataDisplay/ListItem";
import Button from "../../coraWebMComponents/inputs/Button";
import { connect } from 'react-redux';

const styles = theme => ({
  ...getSharedStyles(theme),
  titleFree: {
    color: '#01B050',
  },
  titleReserved: {
    color: 'orange',
  },
  titleOccupied: {
    color: 'red',
  },
  titleUnknown: {
    color: 'gray',
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reserveButton: {
    width: "90%",
    marginTop: "50px",
    letterSpacing: '4px',
  },
  item: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    border: "solid rgba(0,0,0,0.1) 1px",
  }
});

const PlacesMapDialog = ({ open, onClose, focusedPolygon, classes, isAuth }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState(null);
  const [titleColor, setTitleColor] = useState(null);

  useEffect(() => {
    if (focusedPolygon) {
      if (focusedPolygon.iMiestoTyp === 1) {
        switch (focusedPolygon.iStavMiesto) {
          case 1:
            setTitle("VOĽNÉ");
            setTitleColor(classes.titleFree);
            break;
          case 2:
            setTitle("REZERVOVANÉ");
            setTitleColor(classes.titleReserved);
            break;
          case 3:
            setTitle("OBSADENÉ");
            setTitleColor(classes.titleOccupied);
            break;
          default:
            break;
        }
      } else if (focusedPolygon.iMiestoTyp === 2) {
        setTitle("NEVYHRADENÉ");
        setTitleColor(classes.titleUnknown);
      }
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [focusedPolygon]);
  const redirect = () => {
    navigate("/parking/parkingPermit", { state: { parkMiesto: focusedPolygon, selectedParkPlace: true } });
  };

  const content = (
    <List
      style={{
        width: '100%',
        visibility: focusedPolygon?.iMiestoTyp === 1 ? 'visible' : 'hidden',
      }}>
      <ListItem
        style={{
          paddingBottom: "0px",
        }}>
        <div
          className={classes.item}
          style={{
            borderBottom: "none",
          }}>
          <Typography>
            Číslo parkovacieho miesta
          </Typography>
          <Typography>
            {focusedPolygon ? focusedPolygon.cParkMiesto : null}
          </Typography>
        </div>
      </ListItem>
      <ListItem
        style={{
          paddingTop: "0px",
        }}>
        <div className={classes.item}>
          <Typography>
            Ulica
          </Typography>
          <Typography>
            {focusedPolygon ? focusedPolygon.nU : null}
          </Typography>
        </div>
      </ListItem>
      {isAuth &&
        <Button
          variant="contained"
          color="secondary"
          size="large"
          type="success"
          onClick={redirect}
          className={classes.reserveButton}
          style={{
            visibility: focusedPolygon?.iStavMiesto === 1 ? 'visible' : 'hidden',
          }}>
          Rezervovať
        </Button>}
    </List>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding, titleColor) }}
      actions={[]}
      contentClasses={{ root: classNames(classes.textCenter, classes.alignCenter) }}
      content={content}
      closeButton={<div style={{ textAlign: 'right' }}><IconButton size='small' onClick={onClose} ><CloseIcon fontSize='small' /></IconButton></div>}
      titleStyle={{ paddingTop: 0 }}
    />
  );
};

PlacesMapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  focusedPolygon: PropTypes.shape({
    coordinates: PropTypes.array,
    color: PropTypes.string,
    key: PropTypes.number,
    title: PropTypes.string,
    toolTip: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(PlacesMapDialog));