import React from 'react';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';

class KeyboardArrowRightIcon extends React.Component {
  render() {
    return (
      <KeyboardArrowRight
        style={this.props.style}
      />
    )
  }
}

KeyboardArrowRightIcon.propTypes = {
  style: PropTypes.object
}
export default KeyboardArrowRightIcon;