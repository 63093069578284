import * as requests from '../coraWebMComponents/utils/requests';
import CoraError from '../coraWebMComponents/utils/coraError';
import * as storage from "../coraWebMComponents/utils/storage";
import { getClientVersion } from "../coraWebMComponents/utils/helper";

export const MAINTENANCE_MSG = "Práve prebieha údržba systému.";

export const login = async (username, password) => {
  try {
    return await requests.signIn(username, password);
  }
  catch (error) {
    throw error;
  }
}

export const createCode = async (username) => {
  const response = await fetch(`${window.config.url}/api/CreateCode?userName=${username}`, { method: 'POST' });
  if (response.status === 200) {
    const res = await response.json();
    return res;
  }
  else {
    const er = new CoraError(await response.json());
    er.message = "Používateľ s takýmto emailom neexistuje!";
    throw er;
  }
}

export const verifyCode = async (code) => {
  try {
    return await requests.verifyCode(code);
  }
  catch (error) {
    throw error;
  }
}

export const getUserByName = async (name) => {
  try {
    const response = await requests.get(`/api/sprava/SpUzN?un=${name}`);
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const userModify = async (Email, Heslo, Tel) => {
  try {
    let body = {
      EMAIL: Email,
      HESLO: Heslo,
      TEL: Tel
    }
    return await requests.put(`/api/sprava/SpUz`, body);
  }
  catch (error) {
    throw error;
  }
}


export const loginEid = async (code) => {
  if (!code)
    return false;

  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  try {
    let res = await requests.post("/api/loginEid", { Code: code })
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        storage.removeUser();
        return false;
      }
    }

    storage.removeUser();
    storage.setUser(res.Data);
    return res;
  }
  catch (error) {
    storage.removeUser();
    throw error;
  }
}


const checkVersion = (versionServer) => {
  const versionClient = getClientVersion();
  return (
    process.env.NODE_ENV === "development" ||
    !versionServer ||
    versionServer === versionClient
  );
};


export const getPersonEid = async (code, type) => {
  if (!code)
    return false;

  let url = "/api/account/personeid";

  if (type === "subject")
    url = "/api/account/subjecteid";

  try {
    return await requests.get(`${url}?code=${code}`);
  }
  catch (error) {
    throw error;
  }
}

export const getPersonEidType = async (code) => {
  if (!code)
    return false;

  try {
    return await requests.get(`/api/account/personEidType?code=${code}`);
  }
  catch (error) {
    throw error;
  }
}

