import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

class ToolTipComponent extends React.Component {
  render() {
    return (
      <Tooltip
        title={this.props.label}
        placement={this.props.place}
      >
        {this.props.children}
      </Tooltip>
    )
  }
}

ToolTipComponent.propTypes = {
  label: PropTypes.string,
  place: PropTypes.string,
}

export default ToolTipComponent;