import React from 'react';
import sortBy from 'lodash/sortBy';
import Slide from '@material-ui/core/Slide';

import * as convert from '../../coraWebMComponents/utils/convert';

import { formatDecimals, parseCodeAndNumber } from "./helper"

export const LABEL_NAME = 'label';
export const VALUE_NAME = 'value';

/** Načítanie hodnoty stĺpca s prípadnou úpravou (napr. dátumy) */
export const buildValue = (rowData, col) => {
  if (null == rowData)
    return null;

  let value = rowData[col.field];
  if (null == value)
    return null;

  /** Formatovanie ciselnych hodnot */
  const numberOrCurrency = (value, format) => {
    let { code, number } = parseCodeAndNumber(format);
    switch (code) {
      case "c": // currency
      case "n": // number
      case "p": // percentage
        return formatDecimals(value, number);
      case "b": // Bytes (KB,MB,GB,TB)
        return convert.bytesToSize(value);
      default:
        return value;
    }
  }

  /** Formatovanie casovych a datumovych hodnot */
  const dateOrTime = (value, format) => {
    try {
      return convert.dateToStr(new Date(value), format);
    } catch {
      return null;
    }
  }

  let format;

  switch (col.type) {
    case 'datetime':
      format = 'dd.MM.yyyy HH:mm:ss';
      return dateOrTime(value, format);
    case 'date':
      format = 'dd.MM.yyyy';
      return dateOrTime(value, format);
    case 'time':
      format = 'HH:mm:ss';
      return dateOrTime(value, format);
    case 'files':
      return null;
    case 'numeric':
      return numberOrCurrency(value, col.format);
    default:
      return value;
  }

  // let dt;
  // try {
  //   dt = new Date(value);
  //   value = convert.dateToStr(dt, format);
  //   return value
  // } catch {
  //   return null;
  // }
}

export function Transition(props) {
  return <Slide direction="down" {...props} />;
}

export const getDetailTitle = (formatMask, rowData) => {
  let title = formatMask;
  try {
    const regexpSize = /\{\{(\w+)\}\}/g;
    const matches = title.matchAll(regexpSize);
    for (const match of matches) {
      const fieldName = match[1];
      const value = rowData[fieldName] ?? '';
      title = title.replace(match[0], value);
    }
  } catch (error) {
    console.error(error);
  }
  return title;
};

export const getCboFltState = (fltMeta, fltValue) => {
  let text = fltMeta.label;
  let title = fltMeta.title;
  let hasValue = null != fltValue;
  if (hasValue && fltMeta.noItem) {
    const { value } = fltMeta.noItem;
    hasValue = !(value && value === fltValue);
  }
  if (hasValue) {
    const item = fltMeta.dataItems.find(x => x.value === fltValue);
    if (null != item) {
      text = item.label;
    }
  }
  return { hasValue, text, title };
}

export const getGridVisibleColumns = (columns, addProps = false) => {
  let cols = columns.filter(x => !x.disabled);
  if (addProps) {
    cols = cols.map(colSour => {
      const colDest = {
        ...colSour,
        name: colSour.field,
        header: colSour.title,
        width: colSour.width,
      };
      if (colSour.type === 'numeric') {
        colDest.cellProps = { align: 'right' };
      } else if (['boolean', 'date', 'time', 'datetime'].includes(colSour.type)) {
        colDest.cellProps = { align: 'center' };
      } else {
        colDest.cellProps = { align: 'left' };
      }
      return colDest;
    });
  }
  return sortBy(cols, x => { return x.order ? x.order : 0 });
}

export const getDetailVisibleColumns = (columns) => {
  const col1 = columns.filter(x => !x.hidden);
  const col2 = col1.map(col => ({ ...col, field: col.field + (col.filter === "container" ? "." + col.textField : "") }));
  return sortBy(col2, x => { return x.tabIndex ? x.tabIndex : 999999999 });
}
