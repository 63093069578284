import * as React from 'react';
import { connect } from 'react-redux';
import { GridDetailRow } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ResponsiveGrid from '../../coraWebMComponents/ResponsiveGrid';
import * as requiredPublicActions from '../../actions/publicZone/requiredPublicActions';
import * as convert from '../../coraWebMComponents/utils/convert';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';

const styles = theme => ({ ...getSharedStyles(theme) });

const detailRowStyle = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: "5px"
};

const detailRowTitle = {
  marginRight: "10px"
};

class DetailComponent extends GridDetailRow {
  render() {
    return getDetail(this.props.dataItem);
  }
}

const getDetail = (dataItem) => {
  return (
    <section>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Rok dotácie:</Typography>
        <Typography variant="body1">{dataItem.ROK}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Typ dotácie:</Typography>
        <Typography variant="body1">{dataItem.N_DT_TYP}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Druh dotácie:</Typography>
        <Typography variant="body1">{dataItem.N_DT_DRUH}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Žiadateľ</Typography>
        <Typography variant="body1">{dataItem.N_O_ZIAD}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Akcia:</Typography>
        <Typography variant="body1">{dataItem.N_PROJ}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Účel použitia:</Typography>
        <Typography variant="body1">{dataItem.TXT_UCEL}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Dátum prijatia:</Typography>
        <Typography variant="body1">{convert.dateToDateStr(dataItem.D_PRIJ)}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Schválené prostriedky:</Typography>
        <Typography variant="body1">{dataItem.NAKL_CELKOM ? dataItem.NAKL_CELKOM + ' ' + dataItem.SKR_MENA : 0}</Typography>
      </div>
      <div style={detailRowStyle} >
        <Typography variant="body2" style={detailRowTitle}>Požadované prostriedky:</Typography>
        <Typography variant="body1">{dataItem.SK_POZAD ? dataItem.SK_POZAD + ' ' + dataItem.SKR_MENA : 0}</Typography>
      </div>
    </section>
  );
};

class Subsidies extends React.Component {
  handleChange = async (filter, sort, page) => {
    await this.props.dispatch(requiredPublicActions.getListSubsidies(filter, sort, page));
  };

  //180101000067244
  buildSchema = () => [
    { field: "ROK", title: "Rok dotácie", filter: 'numeric', width: 100 },
    { field: "N_DT_TYP", title: "Typ", filter: 'text', width: 100 },
    { field: "N_DT_DRUH", title: "Druh", width: 180 },
    { field: "N_PROJ", title: "Akcia", width: 'auto' },
    { field: "NAKL_CELKOM", title: "Schv. prostriedky", width: 180, filter: "numeric" },
    { field: "SK_POZAD", title: "Požadované", width: 160, filter: "numeric" },
    { field: "SKR_MENA", title: "Mena", width: 160, filter: "numeric" },
    { field: "N_O_ZIAD", title: "Žiadateľ", width: ' auto', filter: "text" },
  ];

  render() {
    const { classes } = this.props;
    return (
      <div className="container grid-container w-margin">
        <CircularProgress loading={this.props.isLoading} />
        <Typography className={classNames(classes.ml3, classes.mb2, classes.mlLg0)} variant="subtitle1">Dotácie</Typography>
        <ResponsiveGrid
          data={this.props.data}
          schema={this.buildSchema()}
          onChange={this.handleChange}
          style={{ height: '700px' }}
          total={this.props.total}
          primaryField='I_DT'
          isLoading={this.props.isLoading}
          detail={DetailComponent}
          parentProps={this.props}
          getDetail={getDetail}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.listSubsidies.data,
    isLoading: state.listSubsidies.isLoading,
    total: state.listSubsidies.total,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Subsidies));
