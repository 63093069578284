import React from 'react';
import PropTypes from 'prop-types';
import List from './list/List';
import Grid from './grid/Grid';
import Paper from './surfaces/Paper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from './sharedStyles'
import classNames from 'classnames';

const styles = theme => ({
  ...getSharedStyles(theme),
  paperCard: {
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px',
    },
  },
});



function ResponsiveGrid(props) {
  const {classes} = props;  
  if (isWidthUp('lg', props.width)) {
    return (
      // <Grid
      //   data={props.data}
      //   schema={props.schema}
      //   onChange={props.onChange}
      //   style={props.style}
      //   total={props.total}
      //   primaryField={props.primaryField}
      //   isLoading={props.isLoading}
      //   detail={props.detail}
      //   parentProps={props.parentProps}
      // />

      <Paper elevation={2} className={classNames(classes.paperCard)}>
        <Grid
          data={props.data}
          schema={props.schema}
          onChange={props.onChange}
          style={props.style}
          total={props.total}
          primaryField={props.primaryField}
          isLoading={props.isLoading}
          detail={props.detail}
          parentProps={props.parentProps}
        />
      </Paper>
    )
  }
  else {
    return (
      <List
        data={props.data}
        isLoading={props.isLoading}
        schema={props.schema}
        getDetail={props.getDetail}
        onChange={props.onChange}
        total={props.total}
        primaryField={props.primaryField}
        parentProps={props.parentProps}
      />
    );
  }
}

ResponsiveGrid.propTypes = {
  data: PropTypes.array.isRequired,
  schema: PropTypes.array.isRequired,
  parentProps: PropTypes.object,
  disabled: PropTypes.number,
  filter: PropTypes.object,
  hidden: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  onFilter: PropTypes.func,
  onSelect: PropTypes.func,
  primaryField: PropTypes.string.isRequired,
  selected: PropTypes.object,
  style: PropTypes.object,
  total: PropTypes.number.isRequired,
  toolbar: PropTypes.element,
  initFilter: PropTypes.object,
  initSort: PropTypes.array,
  detail: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  onExpandChange: PropTypes.func,
  getDetail: PropTypes.func,
}

export default withWidth()(withStyles(styles)(ResponsiveGrid));
