import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { makeStyles, alpha } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";
// import FilterIcon from "../../coraWebMComponents/dataDisplay/icons/FilterIcon";
// import FilterNoneIcon from "../../coraWebMComponents/dataDisplay/icons/filterRemoveIcon";

import { getCboFltState } from "./support";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  fltCboRoot: {
    // position: "relative",
    // display: "inline-flex",
    // flexDirection: 'column',
    // padding: theme.spacing(1),
    textTransform: "none",
  },
  // fltCboText: {
  //   position: "relative",
  //   display: "inline-flex",
  //   // flexDirection: 'column',
  //   textAlign: "left",
  //   // paddingLeft: theme.spacing(1),
  //   whiteSpace: "nowrap",
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  // },
  fltDiv: {
    backgroundColor: "rgb(255, 238, 223)",
    "&:hover": {
      backgroundColor: "rgb(255, 229, 204)",
    },
  },
  fltHasValue: {
    color: "green",
    backgroundColor: "rgb(246, 255, 245)",
    "&:hover": {
      backgroundColor: "rgb(216, 225, 215)",
    },
    border: "1px solid green",
  },
  fltNoHasValue: {
    color: alpha(theme.palette.common.black, 0.87),
  },
}));

const ComboFilterComponent = ({ fltMeta, fltValue, onClick }) => {
  const classes = useStyles();
  const { hasValue, text, title } = getCboFltState(fltMeta, fltValue);

  return (
    <Button
      key={`cboflt_${fltMeta.id}`}
      className={classNames(
        classes.fltDiv,
        classes.fltCboRoot,
        hasValue ? classes.fltHasValue : classes.fltNoHasValue
      )}
      onClick={onClick}
      toolTip=""
      title={title}
      endIcon={<NavigateNextIcon />}
      style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
    >
      {/* {
        hasValue && (
          <FilterIcon
            style={{ marginRight: "2px" }}
            className={classNames(classes.rimIcon)}
          />
        )
         ) : (
        <FilterNoneIcon
          style={{ marginRight: "2px" }}
          className={classNames(classes.rimIcon)}
        />
      ) 
      } */}
      {text}
    </Button>
  );
};

ComboFilterComponent.propTypes = {
  fltMeta: PropTypes.object.isRequired,
  fltValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func.isRequired,
};

export default ComboFilterComponent;
