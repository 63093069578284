import * as React from 'react';
import PropTypes from 'prop-types';
//import Dialog from '../dialogs/Dialog';
import Button from '../inputs/Button';
//import DropDownList from '../forms/DropDownList';
import DialogFilterForm from './DialogFilterForm';
import SaveFilter from './SaveFilterDialog';
//import ConfirmDialog from '../dialogs/ConfirmDialog';
import './DialogFilter.scss';
import { buildFilter, buildUrl, addTextField } from '../utils/helper';
//import Checkbox from '../forms/Checkbox';

/**
 * Filter in dialog
 * @module
 * @param {array} schema - Schema
 * @param {object} [parentProps] - Parent props
 * @param {bool} isOpen - Is dialog open?
 * @param {func} handleOpenFilter - Function to open filter
 * @param {func} handleCloseFilter - Functions to close filter
 * @param {object} filter - Filter
 * @param {string} enumDefault - Default value in enum
 * @param {sting} primaryField - Primary field
 * @param {bool} userFilter - User filter
 * @param {bool} userFilterDisabled - User filter is disabled
 * @param {array} [userFilterList] - List of user filters
 */
class DialogFilter extends React.Component {
  alertTimeout;

  constructor(props) {
    super(props);
    this.state = {
      isOpenSaveDialog: false,
      isOpenDelete: false,
      selectFilter: null,
      filter: null,
      startFilter: false,
      alertMsg: null,
      error: null,
      nameError: null,
    }
    this.dialogRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userFilter) {
      if (this.props.filter !== prevProps.filter) {
        if ((this.state.filter && this.isFilterChanged(this.state.filter.filters, this.props.filter.filters))) {
          this.setState({
            filter: null,
            selectFilter: null,
            startFilter: false,
          })
        }
      }
    }
  }

  isFilterChanged = (oldData, newData) => {
    if (newData.length === oldData.length) {
      for (let i = 0; i < newData.length; i++) {
        if (newData[i].value !== oldData[i].value || newData[i].field !== oldData[i].field || newData[i].operator !== oldData[i].operator) {
          return true;
        }
      }
    } else return true;
    return false;
  }

  setAlert = (msg, isError) => {
    this.setState({
      alertMsg: msg,
      error: isError
    })
    clearTimeout(this.alertTimeout)
    this.alertTimeout = setTimeout(() => {
      this.setState({
        error: null,
        alertMsg: null
      })
    }, 4500)
  }

  handleDeleteFilter = () => {
    if (this.state.selectFilter) {
      try {
        this.props.deleteFilter(this.state.selectFilter.id);
        this.setState({
          filter: null,
          selectFilter: null,
          startFilter: false
        })
        this.setAlert("Filter úspešne vymazaný", false);
      }
      catch (error) {
        this.setAlert("Nepodarilo sa vymazať filter", true);
      }
    }
  }

  handleChangeUserFilter = (name, value) => {
    const newFilter = { filters: addTextField(value.filters, this.props.schema) }
    this.setState({
      selectFilter: value,
      filter: newFilter,
      startFilter: value.disabled === undefined ? false : !value.disabled
    });
  }

  handleSaveFilter = async (name) => {
    if (this.props.userFilterList.find(el => el.name === name)) {
      this.setState({ nameError: "Filter s týmto menom už existuje." })
      return;
    }
    try {
      const input = this.dialogRef.current.state.input;
      const newFilter = buildFilter(input, this.props.schema);
      const filter = { name, filters: newFilter.filters, disabled: true };
      let newFilterList = await this.props.createFilter(filter);
      let selectFilter = newFilterList.find(el => el.name === name);
      this.handleChangeUserFilter("selectFilter", selectFilter);
      this.handleCloseSaveDialog();
      this.setAlert("Filter úspešne vytvorený", false);
    }
    catch (error) {
      this.setAlert("Nepodarilo sa uložiť filter", true);
    }
  }

  handleUpdateFilter = async () => {
    try {
      this.props.deleteFilter(this.state.selectFilter.id);
      const name = this.state.selectFilter.name;
      const input = this.dialogRef.current.state.input;
      const newFilter = buildFilter(input, this.props.schema);
      const filter = { name, filters: newFilter.filters, disabled: !this.state.startFilter };
      let newFilterList = await this.props.createFilter(filter);
      let selectFilter = newFilterList.find(el => el.name === name);
      this.handleChangeUserFilter("selectFilter", selectFilter);
      this.setAlert("Filter úspešne upravený", false);
    }
    catch (error) {
      this.setAlert("Nepodarilo sa uložiť filter", true);
    }
  }

  handleOpenSaveDialog = () => {
    this.setState({ isOpenSaveDialog: true });
  }

  handleCloseSaveDialog = () => {
    this.setState({ isOpenSaveDialog: false, nameError: null });
  }

  handleConfirmFilter = () => {
    const input = this.dialogRef.current.state.input;
    const newFilter = buildFilter(input, this.props.schema);
    this.props.handleConfirmFilter(newFilter);
  }

  handleOpenDelete = () => {
    this.setState({ isOpenDelete: true });
  }

  handleCloseDelete = () => {
    this.setState({ isOpenDelete: false });
  }

  handleConfirmDelete = () => {
    this.handleDeleteFilter();
    this.handleCloseDelete();
  }

  handleStartFilter = (name, value) => {
    try {
      this.setState({ startFilter: value })
      this.props.updateFilter(this.state.selectFilter.id, !value, this.state.selectFilter.type);
      const msg = value ? "Štartovací filter bol zapnutý" : "Štartovací filter bol vypnutý";
      this.setAlert(msg, false);
    }
    catch (error) {
      this.setAlert("Nepodarilo sa nastaviť štartovací filter", true);
    }
  }

  render() {
    const editableFilter = this.props.userFilterDisabled || !this.state.selectFilter?.id || this.state.selectFilter?.type !== 3;
    const startableFilter = this.props.userFilterDisabled || !this.state.selectFilter?.id || !this.state.selectFilter?.CanDisabled;

    return (
      <>
        {/* <Dialog
          isOpen={this.props.isOpen}
          onClose={this.props.handleCloseFilter}
          onConfirm={this.handleConfirmFilter}
          title="Filter"
        >
          <div>
            {this.props.userFilter &&
              <div className="filter-toolbar">
                <div className="left">
                  <Button
                    disabled={this.props.userFilterDisabled}
                    onClick={this.handleOpenSaveDialog}
                    title="Nový filter"
                    imageUrl={buildUrl('assets/toolbar_cg_new.svg')}
                  />
                  <Button
                    disabled={editableFilter}
                    onClick={this.handleUpdateFilter}
                    title="Uložiť filter"
                    imageUrl={buildUrl('assets/toolbar_cg_save.svg')}
                  />
                  <Button
                    disabled={editableFilter}
                    onClick={this.handleOpenDelete}
                    title="Zmazať filter"
                    imageUrl={buildUrl('assets/toolbar_cg_delete.svg')}
                  />
                </div>
                <Checkbox
                  name="Start"
                  label="Štartovací filter"
                  onChange={this.handleStartFilter}
                  value={this.state.startFilter}
                  disabled={startableFilter}
                />
                <DropDownList
                  disabled={this.props.userFilterDisabled}
                  name={'selectFilter'}
                  data={this.props.userFilterList || []}
                  textField="name"
                  onChange={this.handleChangeUserFilter}
                  value={this.state.selectFilter || { name: ' - ', filters: [] }}
                  defaultItem={{ name: ' - ', filters: [] }}
                />
              </div>
            }
            {this.state.alertMsg &&
              <div className={"filter-alert mb-0 " + (this.state.error ? 'error' : 'success')}>
                <span className="alert-text">{this.state.alertMsg}</span>
              </div>
            }

            <DialogFilterForm
              ref={this.dialogRef}
              schema={this.props.schema}
              parentProps={this.props.parentProps}
              filter={this.state.filter || this.props.filter}
              enumDefault={this.props.enumDefault}
            />
          </div>
        </Dialog>
        <SaveFilter
          isOpen={this.state.isOpenSaveDialog}
          handleClose={this.handleCloseSaveDialog}
          handleConfirm={this.handleSaveFilter}
          error={this.state.nameError}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDelete}
          onClose={this.handleCloseDelete}
          onConfirm={this.handleConfirmDelete}
          text={`Zmazať filter "${this.state.selectFilter?.name}"?`}
        /> */}
      </>
    );
  }
}

DialogFilter.propTypes = {
  schema: PropTypes.array.isRequired,
  parentProps: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleOpenFilter: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleConfirmFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  enumDefault: PropTypes.string.isRequired,
  primaryField: PropTypes.string.isRequired,
  userFilter: PropTypes.bool.isRequired,
  userFilterList: PropTypes.array,
  userFilterDisabled: PropTypes.bool.isRequired,
  updateFilter: PropTypes.func
}

export default DialogFilter;