import React from 'react';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import PropTypes from 'prop-types';

class LibraryBooksIconComponent extends React.Component {
  render() {
    return (
      <LibraryBooks
        style={this.props.style}
        className={this.props.className}
      />
    );
  }
}

LibraryBooksIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default LibraryBooksIconComponent;
