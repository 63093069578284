import actionTypes from '../actions/actionTypes';

export const showRequestFailedDialog_ = (message) => ({
  type: actionTypes.SHOW_REQUEST_FAILURE_DIALOG,
  message
});

export const showRequestFailedDialog = (message) => {
  return async (dispatch) => {
    dispatch(showRequestFailedDialog_(message))
  }
}

const hideRequestFailedDialog_ = () => ({
  type: actionTypes.HIDE_REQUEST_FAILURE_DIALOG,
});

export const hideRequestFailedDialog = () => {
  return async (dispatch) => {
    dispatch(hideRequestFailedDialog_())
  }
}