import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import routes from '../../routes';
import { findDeepMenu } from '../../App';
import MetaFrm from "../metaGrid/MetaFrm";

const PzFrmComponent = (props) => {

  const [primaryFieldActual, setPrimaryFieldActual] = useState(null);

  const primaryField = props.location.pathname.split('/').reverse()[0];

  useEffect(() => {
    setPrimaryFieldActual(primaryField);
  }, [primaryField]);

  if (primaryField !== primaryFieldActual)
    return (<></>);

  let backRoute = routes.PZ;
  let foundMenu = findDeepMenu(props.menuTree, (x) => x.Route === props.location.pathname);
  if (foundMenu && foundMenu.NadMenuID) {
    foundMenu = findDeepMenu(props.menuTree, (x) => x.MenuID === foundMenu.NadMenuID);
    if (foundMenu && foundMenu.Route) {
      backRoute = foundMenu.Route;
    }
  }

  return (
    <MetaFrm primaryField={primaryFieldActual} backRoute={backRoute} />
  );
};

PzFrmComponent.propTypes = {
  menuTree: PropTypes.object.isRequired,
  isAuth: PropTypes.bool
}

export default withRouter(PzFrmComponent);
