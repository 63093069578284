import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class ConstructionOfficeIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		1.8,17 17.8,17 17.8,13 13.6,13 1.8,13 	"/>

          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		15.8,13 15.8,9 1.8,9 1.8,13 	"/>

          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		11.8,9 11.8,5 1.8,5 1.8,9 	"/>

          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		7.8,4 7.8,1 1.8,1 1.8,5 	"/>

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="4.8" y1="5" x2="4.8" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="8.8" y1="10" x2="8.8" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="4.8" y1="14" x2="4.8" y2="16" />

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="11.8" y1="14" x2="11.8" y2="16" />
        </g>
      </SvgIcon>
    );
  }
}

ConstructionOfficeIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default ConstructionOfficeIconComponent;
