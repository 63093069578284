import PropTypes from 'prop-types';
import classNames from 'classnames';
import useTheme from '@mui/system/useTheme';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';

import WarningNoData from "../feedBack/WarningNoData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  ...theme.typography.body2,
  textAlign: 'left',
  height: "100%"
}));

const card = (classes, onClick, item, matchesThemeMD) => (
  <>
    <CardContent sx={{ minHeight: matchesThemeMD ? "3em" : "4em" }}>
      <Typography title={item.NZstZs} variant="body1" gutterBottom>
        {item.NSlz}
      </Typography>
      <Typography variant="caption" className={classNames(classes.textCaption)}>
        {item.TxtSlz}
      </Typography>
    </CardContent>
    <CardActions sx={{ minHeight: matchesThemeMD ? "1.2em" : "1em" }} >
      <IconButton
        variant="contained"
        color="inherit"
        toolTip="Ukáž detaily"
        toolTipPlace="right"
      >
        <ArrowForward className={classNames(classes.navigationForwardButton)} />
      </IconButton>
    </CardActions>
  </>
);
/**
 * Zoznam sluzieb
 *
 * @param {*} props
 * @return {*} 
 */
const ZstSlzList = (props) => {
  const { classes } = props;
  const theme = useTheme();
  const breakpointMd = theme.breakpoints.up('md');
  const matchesThemeMD = useMediaQuery(breakpointMd);

  if (!Boolean(props.listZstSlz.data)) {
    return null;
  }

  // props.listZstSlz.data = []
  return (
    <>
      {
        //  No data
        props.listZstSlz.data.length < 1 &&
        <WarningNoData infoText="Žiadne služby nie sú k dispozícii" />
      }
      <Grid container spacing={1} direction="row" justifyContent="left" alignItems="stretch">
        {props.listZstSlz.data.length > 0 && props.listZstSlz.data.map(item => {
          // const switchedOn = props.selectedIDs.includes(item.IZstZs);
          // console.debug(`${item.IZstZs}. ${item.NZstZs} [switchedOn=${switchedOn}]`);
          return (
            <Grid item xs={12} >
              <Item key={`slz_${item.ISlz}`}
                elevation={1}
              // className={classNames(classes.btnZstZs, (switchedOn ? classes.switchedOn : classes.switchedOff))}
              >
                <Box sx={{ height: "100%" }} >
                  <Button
                    component={"Box"}
                    fullWidth
                    sx={{ p: 0, textTransform: 'none' }}
                    onClick={() => props.onClick(item.ISlz)}
                  >
                    <Card sx={{ width: "100%", height: "100%", backgroundColor: "transparent" }} variant="outlined">
                      {card(classes, props.onClick, item, matchesThemeMD)}
                    </Card>
                  </Button>
                </Box>
              </Item>
            </Grid >
          );
        })}
      </Grid>
    </>
  );
};

ZstSlzList.propTypes = {
  classes: PropTypes.object,
  listZstZs: PropTypes.object,
  listZstSlz: PropTypes.object,
  selectedIDs: PropTypes.array,
  onClick: PropTypes.func,
};

export default ZstSlzList;