import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class FirmIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="1,19 1,11 1,7 
		6,11 6,7 11,11 13,11 13,11 13,1 16,1 16,11 18,11 18,19 	"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="5,19 5,15 10,15 
		10,19 	"/>
          <line fill="none" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="3.5" x2="16" y2="3.5" />
        </g>
      </SvgIcon>
    );
  }
}

FirmIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default FirmIconComponent;
