import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Clear from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';


function renderInput(inputProps) {
  const { InputProps, classes, ref, onSelect, name, isLoading, ...other } = inputProps;
  return (
    <TextField
      position='relative'
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ position: 'absolute', right: '0px' }}>
            {isLoading ?
              <CircularProgress style={{ width: '14px', height: '14px', minWidth: '22px', minHeight: '22px' }} />
              :
              <Button
                disabled={inputProps.disabled}
                style={{ width: '14px', height: '14px', minWidth: '22px', minHeight: '22px' }}
                onClick={() => onSelect(name, null)}
              >
                <Clear />
              </Button>
            }
          </InputAdornment>
        ),
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, schema }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem && selectedItem[schema.value] === suggestion[schema.value];
  return (
    <MenuItem
      {...itemProps}
      key={suggestion[schema.value]}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion[schema.label]}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

let _timer;

function IntegrationDownshift(props) {
  const { classes, onChangeInput, timeDelay, countCharStart } = props;
  const onChange = (e) => {
    if (!e.hasOwnProperty('inputValue')) return;

    let charStart = countCharStart ? countCharStart : 1;

    if (charStart < 1)
      charStart = 1;

    if (e.inputValue.length < charStart) return;

    if (_timer) {
      clearTimeout(_timer);
    }

    _timer = setTimeout(() => {
      props.onChange(e.inputValue);
    }, charStart === e.inputValue.length ? 0 : timeDelay);

    if (onChangeInput) {
      onChangeInput(e.inputValue);
    }
  }

  return (
    <div className={classes.root}>
      <Downshift
        itemToString={item => item ? item[props.schema.label] : ''}
        id="downshift-simple"
        onStateChange={onChange}
        onSelect={value => props.onSelect(props.name, value)}
        selectedItem={props.selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => (
          <div className={classes.container}>
            {renderInput({
              className: props.className,
              fullWidth: true,
              classes,
              helperText: props.errorText,
              error: props.errorText ? true : false,
              InputProps: getInputProps({
                placeholder: '',
                value: inputValue || ""
              }),
              label: props.label,
              disabled: props.disabled,
              onSelect: props.onSelect,
              name: props.name,
              isLoading: props.isLoading
            })}
            <div {...getMenuProps()}>
              {(isOpen && inputValue.length >= (countCharStart ? countCharStart : 1)) ? (
                <Paper className={classes.paper} square>
                  {props.data.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                      schema: props.schema
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>


    </div>
  );
}

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  schema: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func,
  className: PropTypes.string,
  timeDelay: PropTypes.number,
  countCharStart: PropTypes.number,
};

export default withStyles(styles)(IntegrationDownshift);