import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class SwitchComponent extends React.Component {
  render() {
    return (
      <div className='row' style={{ alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Switch
              style={this.props.styleSwitch}
              color={this.props.color}
              disabled={this.props.disabled}
              checked={this.props.checked}
              onChange={this.props.onChange}
              value={this.props.value}
            />
          }
          label={this.props.label}
        />
      </div>
    )
  }
}

SwitchComponent.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.string,
  styleSwitch: PropTypes.object
}

export default SwitchComponent;

