import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
import { alpha } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";

import { getChartIcon } from './helper';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme), // assuming this function returns an object
  container: {
    display: "flex",
    // flex: 1,
    // width: "20%",
    // position: "relative",
  },
  text: {
    fontSize: "15px",
    fontWeight: "200",
    color: "black",
  },
  exportBtn: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.lightGreen.main,
    "&:hover": {
      backgroundColor: theme.palette.lightGreen.dark,
    },
    textTransform: 'none',
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    transform: "translatex(30%)",
    zIndex: theme.zIndex.tooltip,
    backgroundColor: "white",
    flexDirection: "column",
    minWidth: "120%",
    width: "fit-content",
    whiteSpace: "nowrap",
    borderRadius: theme.spacing(0.5),
    border: "2px solid white",
  },
  dropdownButton: {
    // borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 20px",
    color: "black",
    cursor: "pointer",
    border: "none",
    width: "100%",
    "&:hover": {},
  },
  rimText: {
    color: alpha(theme.palette.common.black, 0.87),
    fontSize: theme.palette.secondary.fontSize,
    textTransform: "none",
    textAlign: "left",
  },
  fltNoHasValue: {
    color: alpha(theme.palette.common.black, 0.87),
  },
}));

const ChartBtn = ({ charts, onClick }) => {
  const classes = useStyles();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setShowDropdown(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowDropdown(false);
  }, []);

  // Vygenerovanie buttonov pre vsetky grafy
  const ChartsBtn = useCallback(({ charts }) => {
    return (
      <div>
        {charts.sort((a, b) => a.cZorad - b.cZorad).map((chart, index) => {
          {/* popisok z DB: buttonTitle, ak nie je tak: nGrafTyp, ak ani to nie je tak 'Graf <index>' */ }
          const buttonText = chart.args ? (chart.args.buttonTitle ? chart.args.buttonTitle : (chart.grafTyp?.nGrafTyp ?? `Graf ${index}`)) : `Graf ${index}`;

          // TODO: overit - negenerovat polozku ak je chyba errorMessage
          if (chart.errorMessage) {
            return null;
          }

          return (
            <Button
              key={index}
              onClick={() => onClick(index)}
              // toolTip={`Otvoriť ${chart.grafTyp?.skrGrafTyp} graf`}
              className={classNames(classes.dropdownButton)}
              startIcon={getChartIcon(chart.grafTyp?.skrGrafTyp)}
            >
              <Typography className={classNames(classes.rimText, classes.fltNoHasValue)} title={buttonText}>
                {buttonText}
              </Typography>


            </Button>
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.dropdownButton]);

  return (
    <div
      className={classNames(classes.container)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        className={classNames(classes.exportBtn)}
        startIcon={<BarChartIcon />}
        variant="contained"
        px={1}
      >
        Grafy
        <div
          className={classNames(classes.dropdown)}
          style={{
            display: showDropdown ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <ChartsBtn charts={charts} />
        </div>
      </Button>
    </div>
  );
};

ChartBtn.propTypes = {
  postExportData: PropTypes.func.isRequired,
};

export default ChartBtn;
