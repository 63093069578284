import React from 'react';
import PropTypes from 'prop-types';
import { SplitButton } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import './SplitButton.scss';

/**
* Split button
* @module
* @param {string} text - Button text
* @param {array} items - items
* @param {string} [className] - ClassName of SplitButton
* @param {string} [buttonClassName] - ButtonClassName
* @param {string} [icon] - Icon name
* @param {func} onClick - Function invoked on button click
* @param {bool} [disabled] - Disabled button
* @param {object} [popupSettings] - Popup settings
* @param {string} [imageUrl] - Image URL
* @param {string} [position] - Tooltip position, if not provided - top
* @param {string} [tooltip] - Tooltip
*/
class SplitButtonComponent extends React.Component {
  onClick = (e) => {
    if (e.itemIndex !== undefined) {
      this.props.onClick(e.itemIndex, e.item.text);
    } else {
      this.props.onClick(-1, this.props.text);
    }
  }

  onMouseOver = (e) => {
    if(e.target.tagName === 'BUTTON' || (e.target.tagName === 'IMG' && e.target.parentElement.tagName === 'BUTTON')) {
      e.target.title = this.props.tooltip;
    } 
  }

  render() {
    if (this.props.tooltip) {
      return (
        <Tooltip
          anchorElement="target"
          position={this.props.position || 'top'}
          openDelay={0}
        >
          <div
            className="split-button"
            title={this.props.tooltip}
            onMouseOver={this.onMouseOver}
          >
            <SplitButton
              text={this.props.text}
              items={this.props.items}
              className={this.props.className}
              buttonClass={this.props.buttonClassName}
              icon={this.props.icon}
              onButtonClick={e => this.onClick(e)}
              onItemClick={e => this.onClick(e)}
              disabled={this.props.disabled}
              popupSettings={this.props.popupSettings}
              imageUrl={this.props.imageUrl ? this.props.imageUrl : null}
            />
          </div>
        </Tooltip>
      );
    }

    return (
      <div className="split-button">
        <SplitButton
          text={this.props.text}
          items={this.props.items}
          className={this.props.className}
          buttonClass={this.props.buttonClassName}
          icon={this.props.icon}
          onButtonClick={e => this.onClick(e)}
          onItemClick={e => this.onClick(e)}
          disabled={this.props.disabled}
          popupSettings={this.props.popupSettings}
          imageUrl={this.props.imageUrl ? this.props.imageUrl : null}
        />
      </div>
    );
  }
}

SplitButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  popupSettings: PropTypes.object,
  imageUrl: PropTypes.string,
  tooltip: PropTypes.string,
  position: PropTypes.string
}

export default SplitButtonComponent;