import React from 'react';
import CancelRounded from '@material-ui/icons/CancelRounded';
import PropTypes from 'prop-types';

class CancelRoundedIconComponent extends React.Component {
  render() {
    return (
      <CancelRounded
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

CancelRoundedIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default CancelRoundedIconComponent;
