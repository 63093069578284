import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  page: 0,
}

const parkingPermitsList = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_PERMITS_LIST_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_PARKING_PERMITS_LIST_SUCCESS:
      return { isLoading: false, data: action.res.Data?.Items, total: action.res.Data?.TotalRecords }

    case actionTypes.GET_PARKING_PERMITS_LIST_FAILURE:
      return _initState;

    case actionTypes.PARKING_PERMITS_LIST_RESET:
      return _initState;

    default:
      return state
  }
}

export default parkingPermitsList;