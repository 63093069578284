import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../dataDisplay/Typography';
import ExpansionPanel from '../dataDisplay/ExpansionPanel';
import Paper from '../surfaces/Paper';
import { withStyles } from '@material-ui/core/styles';
import * as convert from '../utils/convert';
import { saveAs } from 'file-saver';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import DescriptionIcon from '../dataDisplay/icons/DescriptionIcon';
import FilterIcon from '../dataDisplay/icons/FilterIcon';
import FilterRemoveIcon from '../dataDisplay/icons/filterRemoveIcon';
import IconButton from '../inputs/IconButton';
import DialogFilter from '../filter/DialogFilter';
import withRequests from '../utils/withRequests';


const styles = theme => ({
  ...getSharedStyles(theme),
  progressWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  progress: {
    marginBottom: theme.spacing.unit * 2,
  },
  wrapper: {
    marginRight: '15px!important',
    marginLeft: '15px!important'
  },
  paper: {
    marginBottom: '15px!important'
  },
  content: {
    padding: '20px',
    //backgroundColor: '#e9e9e9',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px!important',
  },
  expansionRoot: {
    boxShadow: 'none',
    borderTop: '1px solid #e1e1e1',
    //background: '#f6f6f6',
    '&:before': {
      display: 'none',
    },
  },
  expansionExpanded: {
    margin: 'auto',
  },
  summaryRoot: {
    height: '50px',
    minHeight: '50px',
    padding: '0 20px'
  },
  summaryExpanded: {
    minHeight: '50px!important',
  },
  summaryContent: {
    minHeight: 'auto!important'
  },
  detailRoot: {
    padding: '0px 20px 20px 20px'
  },
  right: {
    float: 'right',
  },
  toolbar: {
    overflow: 'hidden'
  }
})

const SORT = [];
const PAGE = {
  skip: 0,
  take: 5
};
const FILTER = {
  filters: []
};

class ListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      filter: FILTER,
      sort: SORT,
      data: [],
      isOpenFilter: false
    };
  }

  handleOpenFilter = () => {
    this.setState({ isOpenFilter: true })
  }

  handleCloseFilter = () => {
    this.setState({ isOpenFilter: false })
  }

  handleConfirmFilter = (filter) => {
    this.onFilterChange({ filter }, false)
    this.handleCloseFilter();
  }

  handleRemoveFilter = () => {
    this.onFilterChange({ filters: [] }, false)
  }

  onFilterChange = (event) => {
    let filter = event.filter || FILTER;
    const page = { ...this.state.page, skip: 0 };
    const data = [];
    this.setState({ filter, page, data });

    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.props.onChange(filter, this.state.sort, page);
    }, 500);
  }

  componentDidMount() {
    this.props.onChange(this.state.filter, this.state.sort, this.state.page);

    window.onscroll = (event) => {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        if (!this.props.isLoading && this.state.page.skip !== this.props.total) {
          this.props.onChange(this.state.filter, this.state.sort, this.state.page);
        }
      }
    };
  }

  buildValue = (data, col) => {
    const value = data[col.field];

    switch (col.filter) {
      case 'date':
        return convert.dataToLocaleDateStr(value);
      case 'files':
        return (
          <td>
            {
              value.map(x => {
                const name = x[col.nameField];
                const desc = x[col.descField];
                const date = x[col.dateField];
                const size = x[col.sizeField];
                const sizeKb = size / 1024;

                return (
                  <IconButton
                    toolTip={
                      <div>
                        {desc &&
                          <div>{`Dokument: "${desc}".`}</div>
                        }
                        {date &&
                          <div>{`Dátum zverejnenia: ${convert.convertDate(date)}.`}</div>
                        }
                        {name &&
                          <div>{`Po kliknutí na iconu sa otvorí súbor: "${name}" o veľkosti ${sizeKb.toFixed(1)}kB.`}</div>
                        }
                      </div>
                    }
                    onClick={() => this.handleSaveFile(col, x)}>
                    <DescriptionIcon />
                  </IconButton>
                );
              })
            }
          </td>
        );
      default:
        return value;
    }
  }

  handleSaveFile = async (x, dataItem) => {
    const url = x.url.replace('{id}', dataItem[x.idField]);
    const name = dataItem[x.nameField];
    try {
      const data = await this.props.requests.getBlob(url);
      saveAs(data, name);
    }
    catch (error) {
      console.error(error);
    }
  }

  isDataChanged = (oldData, newData) => {
    if (newData.length !== oldData.length) {
      return true;
    }

    const { primaryField } = this.props;
    for (let i = 0; i < newData.length; i++) {
      if (oldData[i][primaryField] !== newData[i][primaryField]) {
        return true;
      }
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isDataChanged(prevProps.data, this.props.data)) {
      this.setState({
        data: [...this.state.data, ...this.props.data],
        page: {
          skip: prevState.page.skip + this.props.data.length,
          take: prevState.page.take
        }
      });
    }
  }

  render() {
    const { classes, isLoading, schema, getDetail } = this.props;

    return (
      <div>
        <div className={classes.toolbar}>
          <div className={classes.right}>
            <IconButton toolTip='Filter' onClick={this.handleOpenFilter}>
              <FilterIcon isEmpty={this.state.filter.logic ? false : true} />
            </IconButton>
            <IconButton toolTip='Odstániť filter' onClick={this.handleRemoveFilter}>
              <FilterRemoveIcon />
            </IconButton>
          </div>
        </div>

        {
          this.state.data.map(x =>
            <div className={classes.wrapper}>
              <Paper elevation={2} className={classes.paper} >
                <div className={classes.content}>
                  {schema.map(col => (
                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt1)} >
                      <Typography variant="body2">{col.title}</Typography>
                      <Typography variant="body1">{this.buildValue(x, col)}</Typography>
                    </div>
                  ))}
                </div>
                <ExpansionPanel
                  ePSummary={<Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>Detail</Typography>}
                  classes={{ root: classes.expansionRoot, expanded: classes.expansionExpanded }}
                  classesSummary={{ root: classes.summaryRoot, expanded: classes.summaryExpanded, content: classes.summaryContent }}
                  classesDetail={{ root: classes.detailRoot }}
                  ePDetail={
                    <div>
                      {getDetail(x)}
                    </div>
                  }
                />
              </Paper>
            </div>
          )
        }
        {isLoading &&
          <div className={classes.progressWrapper}>
            <CircularProgress className={classes.progress} />
          </div>
        }
        <DialogFilter
          schema={schema}
          filter={this.state.filter}
          isOpen={this.state.isOpenFilter}
          parentProps={this.props.parentProps}
          handleCloseFilter={this.handleCloseFilter}
          handleConfirmFilter={this.handleConfirmFilter}
        />

      </div>
    )
  }
}

ListComponent.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  schema: PropTypes.array.isRequired,
  getDetail: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  primaryField: PropTypes.string.isRequired,
  parentProps: PropTypes.object
}

export default withRequests(withStyles(styles)(ListComponent));

