// testovacie init metadata pre prog. rozpocet, ak je v config.js nastavene: cfgProgramovyRozpocet.dataSourceCode = "json"
const initData = {
  Data: {
    orgList: [ // zoznam organizacii pre filter
      { iOrg: 0, orgName: "Mesto Trnava", years: [2023, 2022, 2021, 2020] },
      { iOrg: 1, orgName: "Obec Trtkovice", years: [2011, 2010, 2022] },
      { iOrg: 2, orgName: "Obec Maglajz", years: [2023, 2022] },
      { iOrg: 3, orgName: "Obec Prazdna", years: [] }
    ],
    activeIOrg: 0, // ID aktivnej organizacie z orgList
    activeYear: 2023, // aktivny rok z years
    maxIncomesLevel: 7, // maximalna povolena uroven rozbalenia pre Prijmy mesta (iOrg=0)
    maxExpensesLevel: 9, // maximalna povolena uroven rozbalenia pre Vydaje mesta (iOrg=0)
    maxOrgIncomesLevel: 9, // maximalna povolena uroven rozbalenia pre Prijmy ogranizacii mesta (iOrg<>0)
    maxOrgExpensesLevel: 9, // maximalna povolena uroven rozbalenia pre Vydaje ogranizacii  mesta (iOrg<>0)
    updateDate: new Date().toISOString(), // datum poslednej aktualizacie dat
    autoExpandToLevel: 2, // uroven, do ktorej sa ma automaticky rozbalit uvodna stranka
    breakPointXs: 910, // hranicna sirka okna v pixeloch pre zmenu z landscape na portret
    sortOrder: 1, // usporiadanie poloziek v leveloch. 1=ASC; -1=DESC
    numberOfDecimals: 2, // pocet des. miest vo vypise ciselnych hodnot
    maxAutoExpandToLevel: 8, // maximalny akceptovany level pre prvotne AUTO-rozbalenie
    maxExpandToLevel: 8, // maximalny ponuknuty level pre MANUALNE rozbalenie. Pozor, 9 moze sposobit vyrazne spomalenie rozbalovania pri objemnych rozpoctoch
    excludedLevels: [3, 4, 5], // pole ignorovanych levelov v manualnom rozbalovaci pre Prijmy, vzdy 3,4,5
  }
}

export default initData;