import * as requests from '../../coraWebMComponents/utils/requests';
import CoraError from '../../coraWebMComponents/utils/coraError';

const processListOrder = (data) => {
  data.Items = data.Items.map((item) => {
    item.D_ZVEREJ = new Date(item.D_ZVEREJ);
    return item;
  });
  return data;
}

const processListRequestsGrid = (data) => {
  data.Items = data.Items.map((item) => {
    item.Poziad.D_ZADANIE = new Date(item.Poziad.D_ZADANIE);
    return item.Poziad;
  });
  return data;
}

export const getListOrder = async (filter, sort, page) => {
  try {
    let res = await requests.getList('/api/pz/objd', filter, sort, page);
    res.Data = processListOrder(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}


export const fetchListRequestsGrid = async (filter, sort, page) => {
  filter.filters.map(x => requests.processFilter(x));

  let response = await fetch(`http://192.168.1.178:8081/api/hd/poziad?filter=${JSON.stringify(filter)}&sort=${JSON.stringify({ sorts: sort })}&page=${(page.skip / page.take) + 1}&limit=${page.take}`);

  if (response.status === 200) {
    return await response.json();
  }
  else if (response.status === 404) {
    return { Data: { Items: [], TotalRecords: page.skip } };
  }
  else {
    const res = await response.json();
    throw new CoraError(res);
  }
}

export const getListRequestsGrid = async (filter, sort, page) => {
  try {
    let res = await fetchListRequestsGrid(filter, sort, page);
    res.Data = processListRequestsGrid(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}

const processListContract = (data) => {
  data.Items = data.Items.map((item) => {
    item.D_PODPIS = new Date(item.D_PODPIS);
    return item;
  });
  return data;
}

export const getListContract = async (filter, sort, page) => {
  try {
    let res = await requests.getList('/api/pz/form/IZm', filter, sort, page);
    res.Data = processListContract(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}

const processListPurchBill = (data) => {
  data.Items = data.Items.map((item) => {
    item.D_ZVEREJ = new Date(item.D_ZVEREJ);
    item.D_VYSTAV = new Date(item.D_VYSTAV);
    return item;
  });
  return data;
}

export const getListPurchBill = async (filter, sort, page) => {
  try {
    let res = await requests.getList('/api/pz/odbFku', filter, sort, page);
    res.Data = processListPurchBill(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}

export const getListContractBill = async (filter, sort, page) => {
  try {
    return await requests.getList('/api/pz/dodFku', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
}

export const getListOrg = async (id) => {
  try {
    return await requests.get(`/api/pz/org/${id}`);
  }
  catch (error) {
    throw error;
  }
}

const processListSubsidies = (data) => {
  data.Items = data.Items.map((item) => {
    item.D_PRIJ = new Date(item.D_PRIJ);
    return item;
  });
  return data;
}

export const getListSubsidies = async (filter, sort, page) => {
  try {
    let res = await requests.getList(`/api/pz/dot`, filter, sort, page);
    res.Data = processListSubsidies(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}