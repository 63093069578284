import actionTypes from '../../actions/actionTypes';


const _initState = {
  isLoading: false,
  data: []
}

const listPeople = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST_PEOPLE_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_LIST_PEOPLE_SUCCESS:
      return { isLoading: false, data: action.res ? action.res.Data : [] }

    case actionTypes.GET_LIST_PEOPLE_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default listPeople;