import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
}

const ECVList = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ECV_LIST_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ECV_LIST_SUCCESS:
      return { isLoading: false, data: action.res.Data?.Items, total: action.res.Data?.TotalRecords }

    case actionTypes.GET_ECV_LIST_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default ECVList;