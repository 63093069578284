import { useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

const INITIAL_NUMBER_OF_ITEMS = 3;

/**
 * Baner - Posledne vyuzite sluzby globalne alebo na uzivatela
 *
 * @param {*} { classes, _matchesThemeMD, listSlzUsed, listSlzUsedUser, onClick, filterUser = false }
 * @return {*} 
 */
const ZstUsedServices = ({ classes, _matchesThemeMD, listSlzUsed, listSlzUsedUser, onClick, filterUser = false }) => {
  const [numberOfSlzUsedItems, setNumberOfSlzUsedItems] = useState(INITIAL_NUMBER_OF_ITEMS)

  const handleMoreItemsClick = () => {
    setNumberOfSlzUsedItems((cur) => cur + INITIAL_NUMBER_OF_ITEMS)
  }
  let listToShow;
  if (filterUser) {
    listToShow = listSlzUsedUser?.data.sort((a, b) => Number(new Date(b.DLast)) - Number(new Date(a.DLast))).slice(0, numberOfSlzUsedItems);
  } else {
    listToShow = listSlzUsed?.data.sort((a, b) => b.Count - a.Count).slice(0, numberOfSlzUsedItems);
  }

  const LinkItem = ({ text = "", onClick = () => { }, _item, class1 }) => {
    return (
      <Box>
        <Link
          component="button"
          variant="caption"
          onClick={() => onClick()}
          className={classNames(classes.textDecorationNone)}
        >
          <Typography variant="caption" className={class1}>
            {text}&nbsp;
            {/* {item && !filterUser && `(${item?.Count}x)`} */}
          </Typography>
        </Link>
      </Box>
    )
  }

  if (!listToShow || listToShow.length === 0) {
    return null;
  }

  return (
    <Stack
      // sx={{ backgroundColor: "#559" }}
      mt={1}
      spacing={1}
    >
      {
        listToShow?.map((item) => {
          return <LinkItem
            id={item.ISlzUsed}
            text={item?.Slz?.NSlz.trim()}
            item={item}
            onClick={() => onClick(item.Slz.ISlz, item.Slz.ZstZs.IZstZs)}
            class1={classNames(classes.uxTextCaption)}
          />
        }
        )
      }
      {
        listSlzUsed?.data.length > numberOfSlzUsedItems && <LinkItem
          text={"(ukázať viac...)"}
          onClick={handleMoreItemsClick}
          class1={classNames(classes.textMore)}
        />
      }
    </Stack>
  );
};

ZstUsedServices.propTypes = {
  classes: PropTypes.object,
  listSlzUsed: PropTypes.object,
  listSlzUsedUser: PropTypes.object,
  matchesThemeMD: PropTypes.bool,
  filterUser: PropTypes.bool
}

const mapStateToProps = (state) => ({
  listSlzUsed: state.listSlzUsed,
  listSlzUsedUser: state.listSlzUsedUser,
})

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ZstUsedServices);