import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class ParkingCardComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={this.props.style}
        viewBox="0 0 65 48"
        className={this.props.className}
      >
        <g transform="translate(-111 -280)">
          <g className="a" transform="translate(111 280)" style={{ fill: "#fff", stroke: "#323232", strokeWidth: "2px" }}>
            <rect className="d" width="65" height="48" rx="5" style={{ stroke: "none" }} />
            <rect className="e" x="1" y="1" width="63" height="46" rx="4" style={{ fill: "none" }} />
          </g>
          <path className="b" d="M28.125,2.25H3.375A3.376,3.376,0,0,0,0,5.625v24.75A3.376,3.376,0,0,0,3.375,33.75h24.75A3.376,3.376,0,0,0,31.5,30.375V5.625A3.376,3.376,0,0,0,28.125,2.25ZM16.875,22.5H13.5v3.375A1.128,1.128,0,0,1,12.375,27h-2.25A1.128,1.128,0,0,1,9,25.875V10.125A1.128,1.128,0,0,1,10.125,9h6.75a6.75,6.75,0,0,1,0,13.5Zm0-9H13.5V18h3.375a2.25,2.25,0,0,0,0-4.5Z" transform="translate(119 286.028)" style={{ fill: "#323232" }} />
          <line className="c" x2="14.587" transform="translate(154.5 293.5)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x2="14.587" transform="translate(154.5 300.706)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x2="14.587" transform="translate(154.5 308.812)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
          <line className="c" x1="8" transform="translate(161.087 316.018)" style={{ fill: "none", stroke: "#323232", strokeWidth: "2px", strokeLinecap: "round" }} />
        </g>
      </SvgIcon>
    )
  }
}


ParkingCardComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}
export default ParkingCardComponent;






