import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: {}
}

const initProgRozp = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_INIT_PROG_ROZP_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_INIT_PROG_ROZP_SUCCESS:
      return { isLoading: false, data: action.res.Data ? action.res.Data : _initState.data }

    case actionTypes.GET_INIT_PROG_ROZP_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default initProgRozp;