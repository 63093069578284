import React from 'react';
import { merge } from "lodash";

import { BarChart } from '@mui/x-charts/BarChart';
// import { makeStyles } from '@material-ui/core/styles';

// import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import { getColorsAttribute } from "../charts/helper";

// const useStyles = makeStyles((theme) => ({
//   ...getSharedStyles(theme),
//   chartContainer: { // da sa overraidnut cez propsu 'style'
//     backgroundColor: "#fff",
//     borderRadius: "0.5rem",
//     boxSizing: 'content-box',
//   }
// }));

const BarChartComponent = props => {
  const { isMobile, className, style,
    dataSet, series, layout = "vertical", width, height = 400, colors,
    labelAxisProps, valueAxisProps, leftAxis, rightAxis, bottomAxis, topAxis,
    marginProps, legendProps, gridProps, axisHighlightProps,
    ...otherProps } = props;

  // const classes = useStyles();

  const labelAxis = [];
  labelAxisProps?.forEach(axis =>
    labelAxis.push(merge(
      {
        valueFormatter: (val, context) => {
          return context.location === "tick" ? `${val}` : `${val}`;
        },
        scaleType: 'band',
        label: 'X',
        labelFontSize: "1em",
        categoryGapRatio: 0.4,
        barGapRatio: 0.05,
        hideTooltip: false,
      }, axis))
  );

  const valueAxis = [];
  valueAxisProps?.forEach(axis =>
    valueAxis.push(merge(
      {
        valueFormatter: (val, context) => {
          return context.location === "tick" ? `${val}` : `${val}`;
        },
        hideTooltip: false,
        label: 'Y',
        labelFontSize: "1em",
        // labelStyle: { transform: 'translate(-250px, 200px) rotate(-90deg)' }, //posun nazvu Y osi
      }, axis))
  );

  var defaultSeries = series?.map(x => merge(
    {
      highlightScope: {
        highlighted: "series",
        faded: "global",
      },
    }, x)
  );

  const margin = isMobile
    ? undefined
    : { top: 50, right: 50, bottom: 50, left: 50, ...marginProps };

  const chartSetting = merge({
    dataset: dataSet,
    series: defaultSeries,
    layout: layout,
    width: width,
    height: height,
    xAxis: layout === "vertical" ? labelAxis : valueAxis,
    yAxis: layout === "vertical" ? valueAxis : labelAxis,
    leftAxis: leftAxis,
    rightAxis: rightAxis,
    bottomAxis: bottomAxis,
    topAxis: topAxis,
    margin: margin,
    colors: getColorsAttribute(colors),
    grid: { horizontal: true, vertical: true, ...gridProps },
    axisHighlight: { x: layout === "vertical" ? "band" : "line", y: layout === "vertical" ? "line" : "band", ...axisHighlightProps }, // 'none', 'band'
    skipAnimation: false,
    disableAxisListener: false,
    tooltip: { trigger: 'axis' }, // "item"
    slotProps: {
      legend: merge({
        position: {
          vertical: 'top',
          horizontal: 'center', //ak je right, potom aj padding sa nastavuje cez right (padding left nereaguje)
        },
        direction: 'row',
        itemGap: 20,
        markGap: 5,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        itemMarkWidth: 16,
        itemMarkHeight: 24,
        labelStyle: {
          fontSize: "1rem",
          fill: "black",
        },
      }, legendProps),
    },
  }, otherProps);

  return (
    // <Box
    //   className={classes.chartContainer}
    //   style={style}
    //   width={"100%"}
    //   height={"100%"}
    // >
    <BarChart
      sx={className}
      {...chartSetting}
    />
    // </Box>
  );
};

BarChartComponent.propTypes = {};

export default BarChartComponent;