import React from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';

class MoreVertIconComponent extends React.Component {
  render() {
    return (
      <MoreVert
        style={this.props.style}
      />
    )
  }
}

MoreVertIconComponent.propTypes = {
  style: PropTypes.object
}

export default MoreVertIconComponent;
