import React from 'react';
import Event from '@material-ui/icons/Event';

class EventIcon extends React.Component {
  render() {
    return (
      <Event style={this.props.style}
      />
    )
  }
}

export default EventIcon;