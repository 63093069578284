import actionTypes from '../../actions/actionTypes';

const _init = {
  I_O: null,
  I_ADR: null,
}

const _initState = {
  isLoading: false,
  data: _init
}

const personEid = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERSON_EID_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_PERSON_EID_SUCCESS:
      return { isLoading: false, data: action.res ? action.res.Data : _init }

    case actionTypes.GET_PERSON_EID_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default personEid;