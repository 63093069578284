import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  page: 0,
  hasMore: true,
}

const parkingTickets = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_TICKETS_REQUEST:
      const data = action.page === 1 ? [] : [...state.data];
      return { ...state, data, isLoading: true };

    case actionTypes.GET_PARKING_TICKETS_SUCCESS:
      const hasMore = action.res.Data.Items.length > 0;
      return { isLoading: false, data: [...state.data, ...action.res.Data.Items], page: action.page, hasMore }

    case actionTypes.GET_PARKING_TICKETS_FAILURE:
      return { ...state, isLoading: false };

    case actionTypes.CLEAR_PARKING_CARDS:
      return _initState;

    default:
      return state
  }
}

export default parkingTickets;