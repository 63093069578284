import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: []
}

const creditDraw = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_CREDIT_DRAW_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_CREDIT_DRAW_SUCCESS:
      return { isLoading: false, data: action.res }

    case actionTypes.GET_CREDIT_DRAW_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default creditDraw;