import * as requests from '../utils/publicZone/requestsNoAuth';
import initData from "../components/rozpocet/progRozpTestInit.js"
import cfgProgramovyRozpocet from '../components/rozpocet/config.js';

const CFG_PG = cfgProgramovyRozpocet

// konsolidacia dat a ponechanie len potrebnych stlpcov pre PR
// Prichadzajuci typ: N=Number; Ss=String (hodnota je string); Sn=String (hodnota je number)
const prepareColumns = (data = []) => {
  return data = data?.map(x => {
    return ({
      I_R: x.I_R, //N 
      R_DR: x.R_DR, //Ss
      ROK: x.ROK, //N
      I_R_DR: x.I_R_DR, //N
      N_R_DR: x.N_R_DR, //Ss
      R_SCHVAL: x.R_SCHVAL ? x.R_SCHVAL : 0, //N
      R_UPRAV: x.R_UPRAV ? x.R_UPRAV : 0, //N
      R_SKUT: x.R_SKUT ? x.R_SKUT : 0, //N
      C_KL_E1: +x.C_KL_E1, //Sn
      N_KL_E1: x.N_KL_E1, //Ss
      C_KL_E2: +x.C_KL_E2, //Sn
      N_KL_E2: x.N_KL_E2, //Ss
      C_KL_E3: +x.C_KL_E3, //Sn
      N_KL_E3: x.N_KL_E3, //Ss
      C_KL_E446: x.C_KL_E446 === null ? '-' : x.C_KL_E446, //Sn
      C_AKC: x.C_AKC === null ? '-' : x.C_AKC, //Sn
      N_R: x.N_R === null ? '-' : x.N_R, //Ss,
      C_R_PRG: isNaN(parseFloat(x.C_R_PRG)) ? '- ' : parseFloat(x.C_R_PRG), //Sn
      N_R_PRG: x.N_R_PRG === null ? '-' : x.N_R_PRG, //Ss
      C_R_AK: isNaN(parseFloat(x.C_R_AK)) ? '-' : parseFloat(x.C_R_AK), //Sn
      N_R_AK: x.N_R_AK === null ? '-' : x.N_R_AK, //Ss
      I_KL_FUNK: x.I_KL_FUNK, //N
      C_KL_FUNK: x.C_KL_FUNK, //Ss
      N_KL_FUNK: x.N_KL_FUNK, //Ss
    })
  })
}

/**
 * Získanie zoznamu rozpočtových položiek
 * @param {number} orgId ID organizácie
 * @param {number} rok Rok
 * @returns GridItemsVm of WebERVm
 */
export const getProgRozpList = async (orgId, year) => {
  switch (CFG_PG.dataSourceCode) {
    case "json":
      return getDataJson()
    case "api":
      return getDataApi(orgId, year)
    default:
      return getDataApi(orgId, year)
  }
}

/**
   * Nacita data z lokalneho JSON
   */
async function getDataJson() {
  try {
    const data = fetch(`${CFG_PG.json}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        data = prepareColumns(data?.Data.Items)
        return data;
      })
    return data
  }
  catch (error) {
    throw error;
  }
}

/**
* Nacita data z API
*/
async function getDataApi(orgId, year) {
  try {
    const res = await requests.get(`${CFG_PG.api}?iOrg=${orgId}&rok=${year}`);
    let data = (res.Data ? prepareColumns(res.Data?.Items) : [])
    return data;
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie konfiguračných metadát pre Programový rozpočet
 * @returns MetadataVm
 */
export const getProgRozpInit = async () => {
  switch (CFG_PG.dataSourceCode) {
    case "json":
      return getInitDataJson()
    case "api":
      return getInitDataApi().then(result => result)
    default:
      return getInitDataApi()
  }
}

/**
 * Nacita init data ak sa data tahaju z lokalneho JSON
 */
function getInitDataJson() {
  return initData;
}

/**
 * Nacita init data z API
 */
async function getInitDataApi() {
  try {
    const res = await requests.get(`${CFG_PG.apiInit}`);
    return res;
  }
  catch (error) {
    throw error;
  }
}
