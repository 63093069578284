import React from 'react';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import './index.css';
import { MetaProvider } from './coraWebMComponents/utils/withMeta';
import rootReducer from './reducers';
import errorHandler from './middleware/errorHandler';
import '@progress/kendo-theme-material/dist/all.css';

import * as actionCreators from "./actions/actionTypes";

const composeEnhancers = composeWithDevTools({ actionCreators, trace: true, traceLimit: 25 });

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, errorHandler))
);

ReactDOM.render(
  <MetaProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </MetaProvider>,
  document.getElementById('root')
);

if (window.config.enableServiceWorker) {
  registerServiceWorker(store.dispatch);
}
else {
  unregister();
}