import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  selectMenu: {
    background: 'transparent!important'
  }
})

class SimpleSelectComponent extends React.Component {
  renderValue = (value) => {
    if (this.props.multiple) {
      const data = this.props.data.filter(x => value.includes(x[this.props.schema.value]));
      if (data) {
        if (this.props.wrap) {
          return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, whiteSpace: "pre-wrap" }}>
            {data.map((x, index) => {
              return (
                <>
                  {index > 0 && ", "}
                  < div key={x[this.props.schema.value]}>{x[this.props.schema.label]}</div >
                </>
              )
            })
            }
          </Box >
        }
        return data.map(x => x[this.props.schema.label]).join(", ");
      }
    } else {
      const item = this.props.data.find(x => x[this.props.schema.value] === value)
      if (item) {
        return item[this.props.schema.label];
      }
    }
    return '';
  };

  render() {
    const { schema, data, required, floatingLabelText, name, classes, multiple } = this.props;
    return (
      <FormControl
        fullWidth={this.props.fullwidth}
        disabled={this.props.disabled}
        error={this.props.error}
        className={this.props.className}
      >
        <InputLabel htmlFor={name} >
          {floatingLabelText}
        </InputLabel>
        <Select
          multiple={this.props.multiple}
          value={this.props.value || (this.props.icon ? " " : "")}
          onChange={event => this.props.onChange(name, event.target.value)}
          inputProps={{ id: name }}
          renderValue={this.renderValue}
          classes={{ selectMenu: classes.selectMenu }}
          MenuProps={{
            getContentAnchorEl: () => null,
          }}
          startAdornment={
            this.props.icon && (
              <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                {this.props.icon}
              </InputAdornment>
            )
          }
        >
          {!required && !multiple &&
            <MenuItem value=''>{this.props.defaultLabelText}</MenuItem>
          }
          {data?.map((x, idx) =>
            <MenuItem key={x[schema.value]} value={x[schema.value]}>
              {
                multiple && <Checkbox checked={this.props.value.indexOf(x[schema.value]) > -1} />
              }
              {x[schema.label]}
            </MenuItem>)
          }
        </Select>
        {this.props.error && <FormHelperText>{this.props.errorText}</FormHelperText>}
      </FormControl>
    );
  }
}

SimpleSelectComponent.propTypes = {
  disabled: PropTypes.bool,
  floatingLabelText: PropTypes.string,
  defaultLabelText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  fullwidth: PropTypes.bool,
  data: PropTypes.array.isRequired,
  schema: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element,
  multiple: PropTypes.bool,
  wrap: PropTypes.bool
};
export default withStyles(styles)(SimpleSelectComponent);