import React from 'react';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

class DeleteIconComponent extends React.Component {
  render() {
    return (
      <Delete
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
      />
    )
  }
}

DeleteIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string
}

export default DeleteIconComponent;
