import actionTypes from '../../actions/actionTypes';

const _initState = {
  isActive: false,
  currEdited: null,
  dataEdited: null
}

const adminMode = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_MODE_SET_ACTIVE_STATE:
      return { ...state, isActive: action.isActive };
    case actionTypes.ADMIN_MODE_SET_CURR_EDITED_SECTION:
      return { ...state, currEdited: action.currEdited };
    case actionTypes.ADMIN_MODE_SET_EDITED_DATA:
      return { ...state, dataEdited: action.dataEdited };
    case actionTypes.ADMIN_MODE_RESET:
      return { state: _initState };
    default:
      return state
  }
}

export default adminMode;