import React from 'react';
import LockIcon from '@material-ui/icons/Lock';


class LockIconComponent extends React.Component {
  render() {
    return (
      <LockIcon />
    )
  }
}

export default LockIconComponent;