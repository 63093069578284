import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../feedBack/Dialog';
import Typography from '../dataDisplay/Typography';
import SelectField from '../inputs/SelectField';
import Input from '../inputs/TextField';
import DatePicker from '../inputs/DatePicker';
import getSharedStyles from '../sharedStyles';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import sk from '../utils/sk.json';
import { buildFilter, buildUrl, addTextField } from '../utils/helper';

const styles = theme => ({
  ...getSharedStyles(theme),
  baseline: {
    alignItems: 'baseline',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      marginBottom: '15px',
    },
    marginBottom: '30px',
  },
  col: {
    width: '33.33%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
});

const FILTER_OPERATORS = {
  text: [
    { text: sk.grid.filterContainsOperator, operator: 'contains' },
    { text: sk.grid.filterNotContainsOperator, operator: 'doesnotcontain' },
    { text: sk.grid.filterEqOperator, operator: 'eq' },
    { text: sk.grid.filterNotEqOperator, operator: 'neq' },
    { text: sk.grid.filterStartsWithOperator, operator: 'startswith' },
    { text: sk.grid.filterEndsWithOperator, operator: 'endswith' },
    { text: sk.grid.filterIsEmptyOperator, operator: 'isempty' },
    { text: sk.grid.filterIsNotEmptyOperator, operator: 'isnotempty' }
  ],
  numeric: [
    { text: sk.grid.filterEqOperator, operator: 'eq' },
    { text: sk.grid.filterNotEqOperator, operator: 'neq' },
    { text: sk.grid.filterGteOperator, operator: 'gte' },
    { text: sk.grid.filterGtOperator, operator: 'gt' },
    { text: sk.grid.filterLteOperator, operator: 'lte' },
    { text: sk.grid.filterLtOperator, operator: 'lt' },
    { text: sk.grid.filterIsNullOperator, operator: 'isnull' },
    { text: sk.grid.filterIsNotNullOperator, operator: 'isnotnull' }
  ],
  date: [
    { text: sk.grid.filterEqOperator, operator: 'eq' },
    { text: sk.grid.filterNotEqOperator, operator: 'neq' },
    { text: sk.grid.filterAfterOrEqualOperator, operator: 'gte' },
    { text: sk.grid.filterAfterOperator, operator: 'gt' },
    { text: sk.grid.filterBeforeOperator, operator: 'lt' },
    { text: sk.grid.filterBeforeOrEqualOperator, operator: 'lte' },
    { text: sk.grid.filterIsNullOperator, operator: 'isnull' },
    { text: sk.grid.filterIsNotNullOperator, operator: 'isnotnull' }
  ],
  boolean: [
    { text: sk.grid.filterBooleanAll, operator: '' },
    { text: sk.grid.filterIsTrue, operator: true },
    { text: sk.grid.filterIsFalse, operator: false }
  ]
};

/**
 * Filter component
 * @module
 * @param {bool} isOpen - Whether the filter is open
 * @param {object} [filter] - Filter object
 * @param {array} schema - Filter schema
 * @param {object} parentProps - Parent props
 * @param {func} handleCloseFilter - Close filter handler
 * @param {func} handleConfirmFilter - Confirm filter handler
 */
class DialogFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
    }

  }

  handleConfirmFilter = () => {
    const newFilter = buildFilter(this.state.input, this.props.schema);
    this.props.handleConfirmFilter(newFilter)
  }

  componentDidMount() {
    let input = {};
    this.props.schema.forEach(item => {
      if (item.filter === "date") {
        input[item.field + 'Value'] = null;
      }
    })
    this.props.filter.filters.forEach(item => {
      input[item.field + 'Operator'] = item.operator;
      input[item.field + 'Value'] = item.value;
    });
    this.setState({ input });
  }

  handleChange = (name, value) => {
    this.setState(prevState => ({
      input: {
        ...prevState.input,
        [name]: value ? value : null
      }
    }))
  }


  render() {
    const { classes } = this.props;

    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.handleCloseFilter}
        title='Filter'
        content={

          this.props.schema.map(item => {
            switch (item.filter) {

              case 'cmd':
                return null;

              case 'enum':
                const data = this.props.parentProps ? this.props.parentProps[item.dataField].map((value) => ({ 'value': value })) : [];
                return (
                  <div key={item.field} className={classNames(classes.row, classes.baseline)}>
                    <Typography className={classNames(classes.col)} variant="subtitle1">{item.title}</Typography>
                    <div className={classNames(classes.col)}></div>
                    <SelectField
                      className={classNames(classes.col)}
                      name={item.field + 'Value'}
                      data={data}
                      schema={{ label: "value", value: "value" }}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Value'] || ''}
                      defaultLabelText='Všetko'
                    />
                  </div>
                );

              case 'boolean':
                return (
                  <div key={item.field} className={classNames(classes.row, classes.baseline)}>
                    <Typography className={classNames(classes.col)} variant="subtitle1">{item.title}</Typography>
                    <div className={classNames(classes.col)}></div>
                    <SelectField
                      className={classNames(classes.col)}
                      name={item.field + 'Value'}
                      data={FILTER_OPERATORS.boolean}
                      schema={{ label: "text", value: "operator" }}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Value'] || FILTER_OPERATORS["boolean"][0]["operator"]}
                      required={true}
                    />
                  </div>
                );

              case "date":
                return (
                  <div key={item.field} className={classNames(classes.row, classes.baseline)}>
                    <Typography className={classNames(classes.col)} variant="subtitle1">{item.title}</Typography>
                    <SelectField
                      className={classNames(classes.col)}
                      name={item.field + 'Operator'}
                      data={FILTER_OPERATORS.date}
                      defaultLabelText=" - "
                      schema={{ label: "text", value: "operator" }}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Operator'] || FILTER_OPERATORS["date"][0]["operator"]}
                    />
                    <DatePicker
                      className={classNames(classes.col)}
                      clearable={true}
                      name={item.field + 'Value'}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Value']}
                      keyboards={true}
                      disableFuture={false}
                    />
                  </div>
                );

              case 'numeric':
                return (
                  <div key={item.field} className={classNames(classes.row, classes.baseline)}>
                    <Typography className={classNames(classes.col)} variant="subtitle1">{item.title}</Typography>
                    <SelectField
                      className={classNames(classes.col)}
                      name={item.field + 'Operator'}
                      data={FILTER_OPERATORS.numeric}
                      defaultLabelText=" - "
                      schema={{ label: "text", value: "operator" }}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Operator'] || FILTER_OPERATORS["numeric"][0]["operator"]}
                    />
                    <Input
                      className={classNames(classes.col)}
                      type="number"
                      name={item.field + 'Value'}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Value']}
                    />
                  </div>
                );

              default:
                return (
                  <div key={item.field} className={classNames(classes.row, classes.baseline)}>
                    <Typography className={classNames(classes.col)} variant="subtitle1">{item.title}</Typography>
                    <SelectField
                      className={classNames(classes.col)}
                      name={item.field + 'Operator'}
                      data={FILTER_OPERATORS["text"]}
                      schema={{ label: "text", value: "operator" }}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Operator'] || FILTER_OPERATORS["text"][0]["operator"]}
                    />
                    <Input
                      className={classNames(classes.col)}
                      name={item.field + 'Value'}
                      onChange={this.handleChange}
                      value={this.state.input[item.field + 'Value']}
                    />
                  </div>
                );
            }
          })

        }
        actions={[
          {
            label: 'Zrušiť',
            onClick: this.props.handleCloseFilter,
          },
          {
            label: 'Filtrovať',
            onClick: this.handleConfirmFilter,
          }
        ]}>

      </Dialog>
    );
  }
}

DialogFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  filter: PropTypes.object,
  schema: PropTypes.array.isRequired,
  parentProps: PropTypes.object.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleConfirmFilter: PropTypes.func.isRequired,
}


export default withStyles(styles)(DialogFilter);