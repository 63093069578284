import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * Expansion panel component
 * @module
 * @param {element} [ePSummary] - Expansion panel summary element
 * @param {element} [ePDetail] - Expansion panel detail element
 * @param {object} [classes] - Expansion panel classes
 * @param {object} [classesSummary] - Expansion panel summary classes
 * @param {object} [classesDetail] - Expansion panel detail classes
 */
class ExpansionPanelComponent extends React.Component {
  render() {
    return (
      <ExpansionPanel square={false} classes={this.props.classes}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={this.props.classesSummary}>
          {this.props.ePSummary}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={this.props.classesDetail}>
          {this.props.ePDetail}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

ExpansionPanel.propTypes = {
  ePSummary: PropTypes.element,
  ePDetail: PropTypes.element,
  classes: PropTypes.object,
  classesSummary: PropTypes.object,
  classesDetail: PropTypes.object,
}


export default ExpansionPanelComponent;