import React from 'react';

const replacer = (k, v) => {
  return ['symbol', 'function'].includes(typeof v) || k === '_owner' ? null : v;
}

const IsCellEqual = (prevProps, newProps) => {
  //return false;
  const p = prevProps.tdElement.props;
  const n = newProps.tdElement.props;
  const prevJson =  JSON.stringify(p.children, replacer);
  const newJson =  JSON.stringify(n.children, replacer);
  
  return p.className === n.className && 
    JSON.stringify(p.style) === JSON.stringify(n.style) &&     
    prevJson === newJson && 
    JSON.stringify(prevProps.value) === JSON.stringify(newProps.value) && 
    prevProps.Id === newProps.Id && 
    !newProps.isPicker;
}

export const MemoCell = React.memo((props) => {
  const { tdElement } = props;  
  return tdElement;
}, IsCellEqual);