import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { hideRequestFailedDialog } from '../../actions/errorActions';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';


class ErrorDialog extends React.Component {
  hideDialog = () => {
    this.props.hideErrorDialog();
  }

  render() {
    return (
      <Dialog
        open={this.props.message ? true : false}
        onClose={this.handleCloseAlert}
        contentText={this.props.message}
        title='Upozornenie'
        actions={[
          {
            label: 'Zrušiť',
            color: 'primary',
            onClick: this.hideDialog,
            keyboardFocused: true
          }
        ]}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  hideErrorDialog: () => { dispatch(hideRequestFailedDialog()) }
});

const mapStateToProps = (state) => {
  return {
    message: state.errorDialog.length ? state.errorDialog[0] : null
  };
};

ErrorDialog.propTypes = {
  mesasge: PropTypes.string,
  hideErrorDialog: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);