import React from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";
import Menu from "../../coraWebMComponents/dataDisplay/Menu";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";

import OptionsButtonMenuItem from "./OptionsButtonMenuItem";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  container: {
    display: "flex",
  },
  riRoot: {
    display: "flex",
    alignItems: "center",
  },
  riBtn: {
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
  riIcon: {},
  rim: {},
  rimItem: {},
  rimItemIcon: {},
  rimText: {
    fontSize: theme.palette.secondary.fontSize,
  },
  requiredColor: {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
});

export class OptionsButtonAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const anchorOrigin = this.props.anchorOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };
    const transformOrigin = this.props.transformOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };
    let ii = 0;

    return (
      <div className={classNames(classes.container)}>
        <Button
          variant="contained"
          className={classNames(classes.textTransformNone, classes.adminButton)}
          onClick={this.handleMenu}
          startIcon={<EditIcon />}
        >
          Upraviť
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.items.map((x) => (
            <OptionsButtonMenuItem
              key={++ii}
              classes={classes}
              itemText={x.itemText}
              onClick={() => this.setState({ anchorEl: null }, x.onClick())}
              disabled={x.disabled}
            />
          ))}
        </Menu>
      </div>
    );
  }
}

OptionsButtonAdmin.propTypes = {
  id: PropTypes.number,
  anchorOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  transformOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      itemText: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default withStyles(styles)(OptionsButtonAdmin);
