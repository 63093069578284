import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Button from '../../coraWebMComponents/inputs/Button';

import WarningNoData from "../feedBack/WarningNoData";

const DetailRow = ({ classes, title, value }) => {
  if (!Boolean(value) || value.length < 1) {
    return null;
  }

  return (
    <Stack className={classNames(classes.w100, classes.dFlex, classes.justifyContentStart, classes.boxSizingBorderBox)}>
      <Box className={classNames(classes.w100, classes.justifyContentStart, classes.boxSizingBorderBox, classes.mt2, classes.mb1)}>
        <Typography variant="body1" className={classNames(classes.mr1, classes.mt1)}>{title}</Typography>
      </Box>
      <Box className={classNames(classes.w100, classes.justifyContentStart, classes.boxSizingBorderBox, classes.mb2)}>
        <Typography variant="caption" className={classNames(classes.mr1, classes.mt1)}>{value}</Typography>
      </Box>
    </Stack>
  );
};

/**
 * Detailny pohlad na sluzbu
 *
 * @param {*} props
 * @return {*} 
 */
const ZstSlzDetailItem = (props) => {
  const { classes, selectedISlz, matchesThemeMD } = props;
  const zstSlz = props.listZstSlz.data.find((x) => x.ISlz === selectedISlz);

  const activeFrms = zstSlz?.EFrms;
  // const activeFrms = zstSlz.EFrms.filter(x => !x.Zrus && Boolean(x.FeForm) && [3, x.IOTyp].includes(props.personTypeId));

  // No data
  if (!zstSlz) {
    return <WarningNoData infoText="Popis služby nie je k dispozícii" />;
  }

  const sortedAtachments = zstSlz.Prilohy
    .filter(item => !item.Zrus)
    .sort((a, b) => a.CZorad - b.CZorad);

  return (
    <>
      <Stack
        // backgroundColor="#F57C00"
        flexDirection="collumn"
        justifyContent="space-between"
        // alignItems="flex-start" //na horny okraj
        pb={5}
      >
        {/* El. podania */}
        {
          (activeFrms && activeFrms.length > 0) &&
          <Stack
            flexDirection="column"
            pt={3}
          >
            <Paper elevation={2} className={classNames(classes.ePodaniaWrapper)}>
              <Typography variant="h6" className={classNames(classes.ePodaniaTextMain)}>
                Elektronické podanie
              </Typography>
              <Typography variant="caption" className={classNames(classes.ePodaniaTextCaption)}>
                Elektronickou formou - budete presmerovaný do nového wizardu el. podania konkrétnej služby
              </Typography>
              <Stack flexDirection="column" justifyContent={"flex-start"} >
                {activeFrms?.map(frm => {
                  return (
                    <Button
                      onClick={() => { props.selectFrmClick(selectedISlz, frm.FeForm.IFeForm); }}
                      className={classNames(classes.ePodaniaFormLinkWrapper)}
                    >
                      <Stack flexDirection="row" justifyContent={"flex-start"} alignItems={"flex-start"}
                        className={classNames(classes.w100)}
                      >
                        <ArrowForward fontSize="small" className={classNames(classes.ePodaniaTextMain, classes.mr2)} />
                        <Typography variant="caption" className={classNames(classes.ePodaniaTextMain)}>{frm.FeForm.NFeForm}</Typography>
                      </Stack>
                    </Button>
                  );
                })}
              </Stack>
            </Paper>
          </Stack>
        }

        {/* Popisky sluzby */}
        <Stack
          flexDirection={matchesThemeMD ? "row" : "column"}
          pt={3}
          boxSizing={"border-box"}
          justifyContent={"space-between"}
        >
          {/* Lavy stlpec - len ak je aspon jeden text */}
          {(zstSlz?.Lehota || zstSlz?.TxtSlzVyb || zstSlz?.TxtPopl) && (
            <Stack
              // backgroundColor="#80D8FF"
              flexDirection="column"
              flexBasis={"48%"}
            >
              <Box >
                <DetailRow classes={classes} title="Termíny na vybavenie" value={zstSlz?.Lehota} />
              </Box>
              <Box >
                <DetailRow classes={classes} title="Ako vybavíte" value={zstSlz?.TxtSlzVyb} />
              </Box>
              <Box >
                <DetailRow classes={classes} title="Poplatky" value={zstSlz?.TxtPopl} />
              </Box>
            </Stack>)
          }

          {/* Pravy stlpec */}
          <Stack
            // backgroundColor="#F4FF81"
            flexDirection="column"
            flexBasis={"48%"}
          >
            <Box >
              <DetailRow classes={classes} title="Kde vybavíte" value={zstSlz?.MiestoVyb} />
            </Box>
            <Box >
              <DetailRow classes={classes} title="Kontaktná osoba" value={zstSlz?.NKontOs} />
            </Box>
          </Stack>
        </Stack>

        {/* Tlacivo sluzby */}
        {
          sortedAtachments.length > 0 && (
            <Stack flexDirection="column" pt={3}>
              <Paper elevation={2} className={classNames(classes.eTlacivoWrapper)}>
                <Typography variant="h6" className={classNames(classes.eTlacivoTextMain)}>
                  Tlačivá na stiahnutie
                </Typography>

                {sortedAtachments.map(item => (
                  <Stack key={item.id} flexDirection="row" className={classNames(classes.w100)}>
                    <Link
                      component="button"
                      variant="caption"
                      to={item.Priloha}
                      target="_blank"
                      className={classNames(classes.eTlacivoFormLinkWrapper, classes.textDecorationNone, classes.w100)}
                    >
                      <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-start" gap={1}>
                        <Stack flexDirection={"column"}>
                          <Typography variant="h6" className={classNames(classes.eTlacivoTextCaption)}>
                            {/* Tlačivo služby realizujúce sa v listinnom svete bude stiahnuté do váho zariadenia kliknutím na tento odkaz */}
                            {item.NPril}
                          </Typography>
                          <Typography variant="caption" className={classNames(classes.eTlacivoTextCaption)}>
                            {item.PopisPril}
                          </Typography>
                        </Stack>
                        <FileDownloadIcon fontSize="large" className={classNames(classes.eTlacivoTextMain)} />
                      </Stack>
                    </Link>
                  </Stack>
                ))}
              </Paper>
            </Stack>
          )
        }
      </Stack>
    </>
  );
};

ZstSlzDetailItem.propTypes = {
  classes: PropTypes.object,
  listZstSlz: PropTypes.object,
  matchesThemeMD: PropTypes.bool,
  selectedISlz: PropTypes.number,
  personTypeId: PropTypes.number,
  egovUrl: PropTypes.string,
  onBackClick: PropTypes.func,
  selectFrmClick: PropTypes.func,
};

export default ZstSlzDetailItem;