import React from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';

class AddCircleOutlineComponent extends React.Component {
  render() {
    return (
      <AddCircleOutline
        style={this.props.style}
      />
    )
  }
}

AddCircleOutlineComponent.propTypes = {
  style: PropTypes.object
}

export default AddCircleOutlineComponent;
