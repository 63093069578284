import { React, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import MuiDialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle } from "@material-ui/core";
import { connect } from 'react-redux';
import QuickCreditDialog from "../quickActions/QuickCreditDialog";
import Button from "../../coraWebMComponents/inputs/Button";

const QuickActionsDialog = ({ open, onClose, classes, activeQuickActions }) => {
  const [openQuickCreditDialog, setOpenQuickCreditDialog] = useState(false);

  const mountQuickCredit = () => {
    const quickCredit = activeQuickActions.find(x => x.I_SPEED_AKC === 1);
    return quickCredit ? true : false;
  };
  const handleQuickCredit = () => {
    setOpenQuickCreditDialog(true);
    onClose();
  };

  return (
    <>
      <MuiDialog
        modal={false}
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>
          <img src={"assets/icon-qa-active.svg"} style={{ height: "30px", width: "30px", verticalAlign: 'middle' }} alt=""></img>
          <div style={{ display: "inline", marginLeft: "10px" }}>Rýchla akcia</div>
        </DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            size="large"
            className={classNames(
              classes.w100,
              classes.justifyContentStart,
              classes.addButton
            )}
            style={{ width: '300px', maxWidth: "100%" }}
            onClick={handleQuickCredit}
          >
            <div style={{ marginTop: '8px', marginBottom: '8px' }}>
              <img src="assets/credit_spend.svg" style={{ height: "30px", width: "30px", verticalAlign: 'middle', marginRight: '10px' }} alt=""></img>
              Čerpať kredit</div>
          </Button>
        </DialogContent>
      </MuiDialog>
      {mountQuickCredit() && <QuickCreditDialog isOpen={openQuickCreditDialog} handleClose={() => setOpenQuickCreditDialog(false)} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeQuickActions: state.activeQuickActions.data,
  };
};

export default connect(mapStateToProps)(withStyles()(QuickActionsDialog));