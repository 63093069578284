import actionTypes from './actionTypes';
import * as parkingUtils from '../utils/parkingUtils';
import * as ciselnikyUtils from '../utils/ciselnikyUtils';

const AddParkingPermitRequest = () => ({
  type: actionTypes.ADD_PARKING_PERMIT_REQUEST
});

const AddParkingPermitSuccess = (res) => ({
  type: actionTypes.ADD_PARKING_PERMIT_SUCCESS,
  res
});

const AddParkingPermitFailure = (error) => ({
  type: actionTypes.ADD_PARKING_PERMIT_FAILURE,
  error
});

export const AddParkingPermit = (parkingPermit) => {
  return async (dispatch) => {
    try {
      dispatch(AddParkingPermitRequest());
      let res = await parkingUtils.addParkingPermit(parkingPermit);
      dispatch(AddParkingPermitSuccess(res));
      return true;
    } catch (error) {
      dispatch(AddParkingPermitFailure(error));
      return false;
    }
  }
}

const AddParkingModifyRequest = () => ({
  type: actionTypes.ADD_PARKING_MODIFY_REQUEST
});

const AddParkingModifySuccess = (res) => ({
  type: actionTypes.ADD_PARKING_MODIFY_SUCCESS,
  res
});

const AddParkingModifyFailure = (error) => ({
  type: actionTypes.ADD_PARKING_MODIFY_FAILURE,
  error
});

export const AddParkingModify = (parkingModify) => {
  return async (dispatch) => {
    try {
      dispatch(AddParkingModifyRequest());
      let res = await parkingUtils.addParkingModify(parkingModify);
      dispatch(AddParkingModifySuccess(res));
      return true;
    } catch (error) {
      dispatch(AddParkingModifyFailure(error));
      return false;
    }
  }
}

const AddParkingExtendRequest = () => ({
  type: actionTypes.ADD_PARKING_EXTEND_REQUEST
});

const AddParkingExtendSuccess = (res) => ({
  type: actionTypes.ADD_PARKING_EXTEND_SUCCESS,
  res
});

const AddParkingExtendFailure = (error) => ({
  type: actionTypes.ADD_PARKING_EXTEND_FAILURE,
  error
});

export const addParkingExtend = (idKarta, attachments) => {
  return async (dispatch) => {
    try {
      dispatch(AddParkingExtendRequest());
      let res = await parkingUtils.addParkingExtend(idKarta, attachments);
      dispatch(AddParkingExtendSuccess(res));
      return res;
    } catch (error) {
      dispatch(AddParkingExtendFailure(error));
      return false;
    }
  }
}


const getParkingCardRequest = () => ({
  type: actionTypes.GET_PARKING_CARD_REQUEST
});

const getParkingCardSuccess = (res) => ({
  type: actionTypes.GET_PARKING_CARD_SUCCESS,
  res
});

const getParkingCardFailure = (error) => ({
  type: actionTypes.GET_PARKING_CARD_FAILURE,
  error
});

export const getParkingCard = (iZiadost) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingCardRequest());
      let res = await parkingUtils.getParkingCard(iZiadost);
      dispatch(getParkingCardSuccess(res));
    } catch (error) {
      dispatch(getParkingCardFailure(error));
    }
  }
}


const getListParkingCardRequest = () => ({
  type: actionTypes.GET_LIST_PARKING_CARD_REQUEST
});

const getListParkingCardSuccess = (res) => ({
  type: actionTypes.GET_LIST_PARKING_CARD_SUCCESS,
  res
});

const getListParkingCardFailure = (error) => ({
  type: actionTypes.GET_LIST_PARKING_CARD_FAILURE,
  error
});

export const getListParkingCard = (io, iKartaStav) => {
  return async (dispatch) => {
    try {
      dispatch(getListParkingCardRequest());
      let res = await parkingUtils.getListParkingCard(io, iKartaStav);
      dispatch(getListParkingCardSuccess(res));
    } catch (error) {
      dispatch(getListParkingCardFailure(error));
    }
  }
}

const getListValidParkingCardRequest = () => ({
  type: actionTypes.GET_LIST_VALID_PARKING_CARD_REQUEST
});

const getListValidParkingCardSuccess = (res) => ({
  type: actionTypes.GET_LIST_VALID_PARKING_CARD_SUCCESS,
  res
});

const getListValidParkingCardFailure = (error) => ({
  type: actionTypes.GET_LIST_VALID_PARKING_CARD_FAILURE,
  error
});

export const getListValidParkingCard = (io) => {
  return async (dispatch) => {
    try {
      dispatch(getListValidParkingCardRequest());
      let res = await parkingUtils.getListValidParkingCard(io);
      dispatch(getListValidParkingCardSuccess(res));
    } catch (error) {
      dispatch(getListValidParkingCardFailure(error));
    }
  }
}

const cancelParkingPermitRequest = () => ({
  type: actionTypes.CANCEL_PARKING_PERMIT_REQUEST
});

const cancelParkingPermitSuccess = () => ({
  type: actionTypes.CANCEL_PARKING_PERMIT_SUCCESS
});

const cancelParkingPermitFailure = (error) => ({
  type: actionTypes.CANCEL_PARKING_PERMIT_FAILURE,
  error
});

export const cancelParkingPermit = (id) => {
  return async (dispatch) => {
    try {
      dispatch(cancelParkingPermitRequest());
      await parkingUtils.cancelParkingPermit(id);
      dispatch(cancelParkingPermitSuccess());
    } catch (error) {
      dispatch(cancelParkingPermitFailure(error));
    }
  }
}

const getBySquareRequest = () => ({
  type: actionTypes.GET_BY_SQUARE_REQUEST
});

const getBySquareSuccess = (res) => ({
  type: actionTypes.GET_BY_SQUARE_SUCCESS,
  res
});

const getBySquareFailure = (error) => ({
  type: actionTypes.GET_BY_SQUARE_FAILURE,
  error
});

export const getBySquare = (iban, vs, amount, recipient) => {
  return async (dispatch) => {
    try {
      dispatch(getBySquareRequest());
      let res = await parkingUtils.getBySquare(iban, vs, amount, recipient);
      dispatch(getBySquareSuccess(res));
    } catch (error) {
      dispatch(getBySquareFailure(error));
    }
  }
}

const getParkingCreditRequest = () => ({
  type: actionTypes.GET_PARKING_CREDIT_REQUEST
});

const getParkingCreditSuccess = (res) => ({
  type: actionTypes.GET_PARKING_CREDIT_SUCCESS,
  res
});

const getParkingCreditFailure = (error) => ({
  type: actionTypes.GET_PARKING_CREDIT_FAILURE,
  error
});

export const getParkingCredit = (iKredit) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingCreditRequest());
      let res = await parkingUtils.getParkingCredit(iKredit);
      dispatch(getParkingCreditSuccess(res));
    } catch (error) {
      dispatch(getParkingCreditFailure(error));
    }
  }
}

const getListParkingCreditRequest = () => ({
  type: actionTypes.GET_LIST_PARKING_CREDIT_REQUEST
});

const getListParkingCreditSuccess = (res) => ({
  type: actionTypes.GET_LIST_PARKING_CREDIT_SUCCESS,
  res
});

const getListParkingCreditFailure = (error) => ({
  type: actionTypes.GET_LIST_PARKING_CREDIT_FAILURE,
  error
});

export const getListParkingCredit = (iKartaTyp, iKarta) => {
  return async (dispatch) => {
    try {
      dispatch(getListParkingCreditRequest());
      let res = await parkingUtils.getListParkingCredit(iKartaTyp, iKarta);
      dispatch(getListParkingCreditSuccess(res));
    } catch (error) {
      dispatch(getListParkingCreditFailure(error));
    }
  }
}

const getCreditDrawRequest = () => ({
  type: actionTypes.GET_CREDIT_DRAW_REQUEST
});

const getCreditDrawSuccess = (res) => ({
  type: actionTypes.GET_CREDIT_DRAW_SUCCESS,
  res
});

const getCreditDrawFailure = (error) => ({
  type: actionTypes.GET_CREDIT_DRAW_FAILURE,
  error
});

export const getCreditDraw = (iKreditCerp) => {
  return async (dispatch) => {
    try {
      dispatch(getCreditDrawRequest());
      let res = await parkingUtils.getCreditDraw(iKreditCerp);
      dispatch(getCreditDrawSuccess(res));
    } catch (error) {
      dispatch(getCreditDrawFailure(error));
    }
  }
}

const getListCreditDrawRequest = () => ({
  type: actionTypes.GET_LIST_CREDIT_DRAW_REQUEST
});

const getListCreditDrawSuccess = (res) => ({
  type: actionTypes.GET_LIST_CREDIT_DRAW_SUCCESS,
  res
});

const getListCreditDrawFailure = (error) => ({
  type: actionTypes.GET_LIST_CREDIT_DRAW_FAILURE,
  error
});

export const getListCreditDraw = (iKarta, iKredit) => {
  return async (dispatch) => {
    try {
      dispatch(getListCreditDrawRequest());
      let res = await parkingUtils.getListCreditDraw(iKarta, iKredit);
      dispatch(getListCreditDrawSuccess(res));
    } catch (error) {
      dispatch(getListCreditDrawFailure(error));
    }
  }
}


const addDrawRequest = () => ({
  type: actionTypes.ADD_DRAW_REQUEST
});

const addDrawSuccess = (res) => ({
  type: actionTypes.ADD_DRAW_SUCCESS,
  res
});

const addDrawFailure = (error) => ({
  type: actionTypes.ADD_DRAW_FAILURE,
  error
});

export const addDraw = (draw) => {
  return async (dispatch) => {
    try {
      dispatch(addDrawRequest());
      let res = await parkingUtils.addDraw(draw);
      dispatch(addDrawSuccess(res));
      return res.Data;
    } catch (error) {
      dispatch(addDrawFailure(error));
      return false;
    }
  }
}

const cancelDrawRequest = () => ({
  type: actionTypes.CANCEL_DRAW_REQUEST
});

const cancelDrawSuccess = () => ({
  type: actionTypes.CANCEL_DRAW_SUCCESS
});

const cancelDrawFailure = (error) => ({
  type: actionTypes.CANCEL_DRAW_FAILURE,
  error
});

export const cancelDraw = (id) => {
  return async (dispatch) => {
    try {
      dispatch(cancelDrawRequest());
      await parkingUtils.cancelDraw(id);
      dispatch(cancelDrawSuccess());
      return true;
    } catch (error) {
      dispatch(cancelDrawFailure(error));
      return false;
    }
  }
}


const getRequiredParkingAdrRequest = () => ({
  type: actionTypes.GET_REQUIRED_PARKING_ADR_REQUEST
});

const getRequiredParkingAdrSuccess = (res) => ({
  type: actionTypes.GET_REQUIRED_PARKING_ADR_SUCCESS,
  res
});

const getRequiredParkingAdrFailure = (error) => ({
  type: actionTypes.GET_REQUIRED_PARKING_ADR_FAILURE,
  error
});

export const getRequiredParkingAdr = () => {
  return async (dispatch) => {
    try {
      dispatch(getRequiredParkingAdrRequest());
      let res = await ciselnikyUtils.getPh(1411011);
      dispatch(getRequiredParkingAdrSuccess(res));
    } catch (error) {
      dispatch(getRequiredParkingAdrFailure(error));
    }
  }
}

const getListTempEcvRequest = () => ({
  type: actionTypes.GET_LIST_TEMP_ECV_REQUEST
});

const getListTempEcvSuccess = (res) => ({
  type: actionTypes.GET_LIST_TEMP_ECV_SUCCESS,
  res
});

const getListTempEcvFailure = (error) => ({
  type: actionTypes.GET_LIST_TEMP_ECV_FAILURE,
  error
});

export const getListTempEcv = (iKarta) => {
  return async (dispatch) => {
    try {
      dispatch(getListTempEcvRequest());
      let res = await parkingUtils.getListTempEcv(iKarta);
      dispatch(getListTempEcvSuccess(res));
    } catch (error) {
      dispatch(getListTempEcvFailure(error));
    }
  }
}

const addTempEcvRequest = () => ({
  type: actionTypes.ADD_TEMP_ECV_REQUEST
});

const addTempEcvSuccess = (res) => ({
  type: actionTypes.ADD_TEMP_ECV_SUCCESS,
  res
});

const addTempEcvFailure = (error) => ({
  type: actionTypes.ADD_TEMP_ECV_FAILURE,
  error
});

export const addTempEcv = (iKarta, eCv, iO, dOd, dDo) => {
  return async (dispatch) => {
    try {
      dispatch(addTempEcvRequest());
      let res = await parkingUtils.addTempEcv(iKarta, eCv, iO, dOd, dDo);
      dispatch(addTempEcvSuccess(res));
      return true;
    } catch (error) {
      dispatch(addTempEcvFailure(error));
      return false;
    }
  }
}

const cancelTempEcvRequest = () => ({
  type: actionTypes.CANCEL_TEMP_ECV_REQUEST
});

const cancelTempEcvSuccess = () => ({
  type: actionTypes.CANCEL_TEMP_ECV_SUCCESS
});

const cancelTempEcvFailure = (error) => ({
  type: actionTypes.CANCEL_TEMP_ECV_FAILURE,
  error
});

export const cancelTempEcv = (id) => {
  return async (dispatch) => {
    try {
      dispatch(cancelTempEcvRequest());
      await parkingUtils.cancelTempEcv(id);
      dispatch(cancelTempEcvSuccess());
      return true;
    } catch (error) {
      dispatch(cancelTempEcvFailure(error));
      return false;
    }
  }
}


export const unikParkingPermit = (data) => {
  return async () => {
    try {
      let res = await parkingUtils.unikParkingPermit(data);
      return res;
    } catch (error) {

      return false;
    }
  }
}

export const unikParkingModify = (iKarta) => {
  return async () => {
    try {
      let res = await parkingUtils.unikParkingModify(iKarta);
      return res.Data;
    } catch (error) {

      return false;
    }
  }
}

const getParkingTicketsSuccess = (res, page) => ({
  type: actionTypes.GET_PARKING_TICKETS_SUCCESS,
  res,
  page
});

const getParkingTicketsRequest = (page) => ({
  type: actionTypes.GET_PARKING_TICKETS_REQUEST,
  page
});

const getParkingTicketsFailure = (error) => ({
  type: actionTypes.GET_PARKING_TICKETS_FAILURE,
  error
});

export const getParkingTickets = (zone, ecv, dOd, dDo, page) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingTicketsRequest(page));
      let res = await parkingUtils.getParkingTickets(zone, ecv, dOd, dDo, page);
      dispatch(getParkingTicketsSuccess(res, page));
    } catch (error) {
      dispatch(getParkingTicketsFailure(error))
    }
  }
}

export const changeParkingPermitData = (data) => {
  return {
    type: actionTypes.CHANGE_PARKING_PERMIT_DATA,
    data
  }
}

const getECVListSuccess = (res) => ({
  type: actionTypes.GET_ECV_LIST_SUCCESS,
  res
});

const getECVListRequest = () => ({
  type: actionTypes.GET_ECV_LIST_REQUEST
});

const getECVListFailure = (error) => ({
  type: actionTypes.GET_ECV_LIST_FAILURE,
  error
});

export const getECVList = () => {
  return async (dispatch) => {
    try {
      dispatch(getECVListRequest());
      let res = await parkingUtils.getECVList();
      dispatch(getECVListSuccess(res));
    } catch (error) {
      dispatch(getECVListFailure(error))
    }
  }
}

const getActiveParkingCardSuccess = (res) => ({
  type: actionTypes.GET_LIST_ACTIVE_PARKING_CARD_SUCCESS,
  res
});

const getActiveParkingCardRequest = () => ({
  type: actionTypes.GET_LIST_ACTIVE_PARKING_CARD_REQUEST
});

const getActiveParkingCardFailure = (error) => ({
  type: actionTypes.GET_LIST_ACTIVE_PARKING_CARD_FAILURE,
  error
});

export const getActiveParkingCard = (iO) => {
  return async (dispatch) => {
    try {
      dispatch(getActiveParkingCardRequest());
      let res = await parkingUtils.getActiveParkingCard(iO);
      dispatch(getActiveParkingCardSuccess(res));
    } catch (error) {
      dispatch(getActiveParkingCardFailure(error))
    }
  }
}

const getParkingPermitsListRequest = () => ({
  type: actionTypes.GET_PARKING_PERMITS_LIST_REQUEST,
});

const getParkingPermitsListSuccess = (res) => ({
  type: actionTypes.GET_PARKING_PERMITS_LIST_SUCCESS,
  res
})

const getParkingPermitsListFailure = () => ({
  type: actionTypes.GET_PARKING_PERMITS_LIST_FAILURE
})

export const getParkingPermitsList = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingPermitsListRequest())
      let res = await parkingUtils.getParkingPermitsList(id)
      dispatch(getParkingPermitsListSuccess(res))
    } catch (error) {
      dispatch(getParkingPermitsListFailure(error))
    }
  }
}

export const resetParkingPermitsList = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PARKING_PERMITS_LIST_RESET
    })
  }
}

const getMapLayersSettingsRequest = () => ({
  type: actionTypes.GET_MAP_LAYERS_SETTINGS_REQUEST
});

const getMapLayersSettingsSuccess = (res) => ({
  type: actionTypes.GET_MAP_LAYERS_SETTINGS_SUCCESS,
  res
});

const getMapLayersSettingsFailure = (error) => ({
  type: actionTypes.GET_MAP_LAYERS_SETTINGS_FAILURE,
  error
});

export const getMapLayersSettings = () => {
  return async (dispatch) => {
    try {
      dispatch(getMapLayersSettingsRequest());
      let res = await parkingUtils.getLayersSettings();
      dispatch(getMapLayersSettingsSuccess(res));
      return res?.Data?.Items ?? [];
    } catch (error) {
      dispatch(getMapLayersSettingsFailure(error));
    }
  }
}