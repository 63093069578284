import React from 'react';
import Settings from '@material-ui/icons/Settings';

class SettingsComponent extends React.Component {
  render() {
    return (
      <Settings />
    )
  }
}

export default SettingsComponent;