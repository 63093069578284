import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';

class MenuIconComponent extends React.Component {
  render() {
    return (
      <MenuIcon
        style={this.props.style}
      />
    )
  }
}

MenuIconComponent.propTypes = {
  style: PropTypes.object
}
export default MenuIconComponent;