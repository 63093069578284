import actionTypes from '../../actions/actionTypes';


const _initState = {
  isLoading: false,
  data: []
}

const activeQuickActions = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_QUICK_ACTIONS_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_ACTIVE_QUICK_ACTIONS_SUCCESS:
      return { isLoading: false, data: action.res.Data?.Items, total: action.res.Data?.TotalRecords }

    case actionTypes.GET_ACTIVE_QUICK_ACTIONS_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default activeQuickActions;