import React from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import ReactDOMServer from 'react-dom/server';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Button from '../../coraWebMComponents/inputs/Button';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import TextField from '../../coraWebMComponents/inputs/TextField';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import CheckBox from '../../coraWebMComponents/inputs/CheckBox';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentFile from '../../coraWebMComponents/inputs/AttachmentFile';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import FileInput from '../../coraWebMComponents/inputs/FileInput';
import EmailIcon from '../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import LockIcon from '../../coraWebMComponents/dataDisplay/icons/PasswordIcon';
import routes from '../../routes';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import * as userActions from '../../actions/userActions';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as accountActions from '../../actions/accountActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import InfoButton from '../info/InfoButton';
import { ACCOUNT_PERMIT_DIALOG } from '../../constants/adminConsts';
import AdminModeExitButton from '../adminMode/exitButton';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import ImageModal from '../attachments/ImageModal';
import { downloadPDF } from '../../utils/parkingUtils';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;

const _isDev = false; //window.config.isDev;
let _sizeAttachs = 0;
let _iVer = 0;
const styles = theme => ({
  ...getSharedStyles(theme),
  // paperCard: {
  //   padding: '20px',
  //   [theme.breakpoints.up('sm')]: {
  //     padding: '30px',
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     padding: '45px',
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     padding: '60px',
  //   },
  // },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  },
});

const _initState = {
  I_ZIADOST: 0,
  I_O_TYP: 1,
  MENO: '',
  PF: '',
  D_NAR: null,
  EMAIL: '',
  TEL: '',
  ICO_RC: '',
  N_U: '',
  CS: '',
  CO: '',
  N_M: '',
  krajina: {
    I_STAT: 1,
    N_STAT: 'Slovenská republika'
  },
  D_EVID: null,
  I_O: null,
  I_ADR: null,
  I_UZ: null,
  N_UZ: '',
  HESLO: '',
  HESLO2: '',
  I_ZDROJ: 1,
  ATTACHS: [],
  ATTACHS_FILE: [],
  errorTextMeno: '',
  errorTextPf: '',
  errorTextIco_rc: '',
  errorTextCs: '',
  errorTextN_m: '',
  errorTextEmail: '',
  errorTextTel: '',
  errorTextN_uz: '',
  errorTextHeslo: '',
  errorTextHeslo2: '',
  errorTextAttach: '',
  gdpr: false,
  activeStep: 1,
  skipped: new Set(),
  loading: true,
  openGdpr: false,
  text: '',
  textGdpr: '',
  textInfoAttachs: '',
  havePersonEid: false,
  URI: null,
}

class AccountPermit extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _iVer = params.get("iVer");
    const code = params.get("code");
    let isActive = this.props.adminMode.isActive

    if ((_iVer === null || _iVer === 0) && !isActive)
      return;

    !isActive && await this.props.dispatch(accountActions.UpdVerifyEmail(_iVer));
    !isActive && await this.props.dispatch(accountActions.GetVerifyEmail(_iVer));
    await this.props.dispatch(ciselnikyActions.getPasswordPattern());
    await this.props.dispatch(ciselnikyActions.getPasswordErrMsg());

    if (!this.props.urlGdpr)
      await this.props.dispatch(ciselnikyActions.getUrlGdpr());

    const txt = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_ACCOUNT_PERMIT');
    const txtGdpr = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_GDPR');
    const txtInfoAttachs = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_CREATE_ACC_ATTACHS_INFO');

    if (code)
      await this.props.dispatch(userActions.getPersonEid(code, "subject"));

    const havePersonEid = this.props.personEid.I_O ? true : false;
    const I_O_TYP = havePersonEid ? this.props.personEid.I_O_TYP : 1;
    const MENO = havePersonEid ? this.props.personEid.MENO : '';
    const PF = havePersonEid ? this.props.personEid.PF : '';
    const D_NAR = havePersonEid ? (this.props.personEid.D_NAR ? new Date(this.props.personEid.D_NAR) : null) : null;
    const ICO_RC = havePersonEid ? this.props.personEid.ICO_RC : '';
    const N_U = havePersonEid ? this.props.personEid.N_U : '';
    const CS = havePersonEid ? this.props.personEid.CS : '';
    const CO = havePersonEid ? this.props.personEid.CO : '';
    const N_M = havePersonEid ? this.props.personEid.N_M : '';
    const I_O = havePersonEid ? this.props.personEid.I_O : null;
    const I_ADR = havePersonEid ? this.props.personEid.I_ADR : null;
    const URI = code ? this.props.personEid.URI : null;

    this.setState({
      text: txt,
      textGdpr: txtGdpr,
      textInfoAttachs: txtInfoAttachs[0],
      EMAIL: this.props.verifyEmail.EMAIL,
      N_UZ: this.props.verifyEmail.EMAIL,
      loading: false,
      I_O_TYP,
      MENO,
      PF,
      D_NAR,
      ICO_RC,
      N_U,
      CS,
      CO,
      N_M,
      I_O,
      I_ADR,
      URI,
      havePersonEid
    });
  }

  handleChange = (name, value) => {
    switch (name) {
      case 'MENO':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextMeno: value ? '' : 'Meno je povinný údaj!' });
        break;
      case 'PF':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextPf: value ? '' : 'Priezvisko je povinný údaj!' });
        break;
      case 'ICO_RC':
        this.setState({ errorTextIco_rc: (value && (!value || validator.isValidRc(value))) ? '' : 'RČ je povinný údaj a obsahuje iba číslice (max. 10)!' });
        break;
      case 'CS':
        this.setState({ errorTextCs: value ? '' : 'Súpisné číslo je povinný údaj!' });
        break;
      case 'N_M':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextN_m: value ? '' : 'Obec je povinný údaj!' });
        break;
      case 'N_U':
        value = convert.toUpperCaseFirst(value);
        break;
      case 'EMAIL':
        this.setState({ errorTextEmail: value ? validator.isValidMail(value) : 'E-mail je povinný údaj!' });
        break;
      case 'TEL':
        this.setState({ errorTextTel: value ? validator.isValidPhone(value) : 'Telefón je povinný údaj!' });
        break;
      case 'N_UZ':
        value = value.trim();
        this.setState({ errorTextN_uz: value ? validator.isValidMail(value) : 'Prihlasovací e-mail je povinný údaj!', EMAIL: value });
        break;
      case 'HESLO':
        this.setState({ errorTextHeslo: value ? this.isValidPasswordStrong(value) : 'Heslo je povinný údaj!' });
        break;
      case 'HESLO2':
        this.setState({ errorTextHeslo2: value ? this.isValidPassword(this.state.HESLO, value) : 'Potvrdenie hesla je povinné!' });
        break;
      case 'krajina':
        this.setState({ errorTextKrajina: value ? '' : 'Štát je povinný údaj!' });
      default:
    }
    this.setState({ [name]: value });
  }

  isValidPassword = (heslo, heslo2) => {
    let errorString = '';

    if (heslo !== heslo2)
      errorString += 'Potvrdenie hesla je nesprávne!';

    return errorString;
  }

  isValidPasswordStrong = (psw) => {
    let errorString = '';
    let passwordPattern = new RegExp(this.props.passwordPattern.HODN.trim(), "g");

    if (!(passwordPattern).test(psw))
      errorString = this.props.passwordErrMsg.HODN.trim();

    return errorString;
  }

  handleChangeGdpr = name => event => {
    this.setState({ [name]: event.target.checked });
  }

  handleInfoGdpr = () => {
    if (this.props.urlGdpr.HODN)
      window.open(this.props.urlGdpr.HODN, '_blank').focus();
    else
      this.setState({ openGdpr: !this.state.openGdpr });
  }

  handleSearchCountries = (searchText) => {
    this.props.dispatch(ciselnikyActions.getListCountries(searchText || ''));
  }

  isUserNameUnik = async (username) => {
    let exist = false;
    if (username.length > 0) {
      await this.props.dispatch(userActions.getUserByName(username || ''));
      exist = this.props.userByName !== null;
      if (!exist) {
        await this.props.dispatch(accountActions.getZiadostByName(username || ''));
        exist = this.props.ziadostByName !== null;
      }
    }
    return exist ? 'Používateľ s takýmto emailom už existuje!' : '';
  }

  handleBlurRc = async (name, value) => {
    let existIO = false;
    let dNar = null;
    if (value.length > 0) {
      await this.props.dispatch(accountActions.getPeopleByRc(value || ''));
      if (this.props.peopleByRc !== null) {
        existIO = true;
      }

      dNar = convert.rcToDnar(value);

    }

    this.setState({
      I_O: existIO ? this.props.peopleByRc.I_O : null,
      D_NAR: dNar,
      I_ADR: existIO ? this.props.peopleByRc.I_ADR : null
    });
  }

  handleAdd = async () => {
    const Meno = this.state.MENO;
    const Pf = this.state.PF;
    const Ico_rc = this.state.ICO_RC;
    const Cs = this.state.CS;
    const N_m = this.state.N_M;
    const Email = this.state.EMAIL;
    const Tel = this.state.TEL;
    const N_uz = this.state.N_UZ;
    const Heslo = this.state.HESLO;
    const Heslo2 = this.state.HESLO2;
    const I_adr = this.state.I_ADR;
    const Attachs = this.state.ATTACHS;
    const AttachsFile = this.state.ATTACHS_FILE;
    const havePersonEid = this.state.havePersonEid;
    const URI = this.state.URI;

    let errorTxtN_u = await this.isUserNameUnik(N_uz);
    if (!errorTxtN_u)
      errorTxtN_u = validator.isValidMail(N_uz);

    const errorTextMeno = Meno ? '' : 'Meno je povinný údaj!';
    const errorTextPf = Pf ? '' : 'Priezvisko je povinný údaj!';
    const errorTextIco_rc = (Ico_rc && (!Ico_rc || validator.isValidRc(Ico_rc))) ? '' : 'RČ je povinný údaj a obsahuje iba číslice (max. 10)!';
    const errorTextCs = Cs || I_adr !== null ? '' : 'Súpisné číslo je povinný údaj!';
    const errorTextN_m = N_m || I_adr !== null ? '' : 'Obec je povinný údaj!';
    const errorTextKrajina = this.state.krajina ? '' : 'Štát je povinný údaj!';
    const errorTextEmail = Email ? validator.isValidMail(Email) : 'E-mail je povinný údaj!';
    const errorTextTel = Tel ? validator.isValidPhone(Tel) : 'Telefón je povinný údaj!';
    const errorTextN_uz = N_uz ? errorTxtN_u : 'Prihlasovací e-mail je povinný údaj!';
    const errorTextHeslo = Heslo ? this.isValidPasswordStrong(Heslo) : 'Heslo je povinný údaj!';
    const errorTextHeslo2 = Heslo2 ? this.isValidPassword(Heslo, Heslo2) : 'Potvrdenie hesla je povinné!';
    const errorTextAttach = _sizeAttachs === 0 && !URI ? 'Prílohy sú povinné!' : this.state.errorTextAttach;

    this.setState({ errorTextMeno, errorTextPf, errorTextIco_rc, errorTextCs, errorTextN_m, errorTextKrajina, errorTextEmail, errorTextTel, errorTextN_uz, errorTextHeslo, errorTextHeslo2, errorTextAttach });

    if (!errorTextMeno &&
      !errorTextPf &&
      !errorTextIco_rc &&
      !errorTextCs &&
      !errorTextN_m &&
      !errorTextKrajina &&
      !errorTextEmail &&
      !errorTextTel &&
      !errorTextN_uz &&
      !errorTextHeslo &&
      !errorTextHeslo2 &&
      !errorTextAttach) {

      let AttachsNew = Attachs;
      for (let i = 0; i < Attachs.length; i++) {
        AttachsNew[i].data = Attachs[i].data.replace(/^.+,/, '');
      }

      let AttachsFileNew = AttachsFile;
      for (let i = 0; i < AttachsFile.length; i++) {
        AttachsFileNew[i].data = AttachsFile[i].data.replace(/^.+,/, '');
      }

      const data = {
        ...this.state, I_STAT: this.state.krajina ? this.state.krajina.I_STAT : null,
        D_EVID: convert.addOffset(new Date()),
        D_NAR: convert.addOffset(this.state.D_NAR),
        ATTACHS: AttachsNew,
        ATTACHS_FILE: AttachsFileNew,
        I_ZDROJ: havePersonEid ? 4 : 1
      };

      this.setState({ loading: true });
      const res = await this.props.dispatch(accountActions.AddAccountPermit(data));
      this.setState({ loading: false });
      if (res)
        this.setState({ activeStep: 5 });

    }
    else {
      let errorPage = 0;
      if (I_adr === null) {
        if (errorTextN_m || errorTextCs, errorTextKrajina)
          errorPage = 3;
      }

      if (errorTextMeno || errorTextPf || errorTextIco_rc)
        errorPage = 2;

      if (errorTextN_uz || errorTextHeslo || errorTextHeslo2)
        errorPage = 1;

      if (errorPage !== 0)
        this.setState({ activeStep: errorPage });
    }
  }

  handleGoToReview = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 6,
      skipped,
    });
  };

  handleGoBackFromReview = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 4,
      skipped,
    });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + ((activeStep === 2 && this.state.I_ADR !== null) ? 2 : 1),
      skipped,
    });
  };

  handleBack = () => {
    if (this.state.activeStep === 1) {
      this.setState(_initState);
      this.props.navigate(routes.LOGIN);
    }
    else {
      this.setState(state => ({
        activeStep: state.activeStep - ((state.activeStep === 4 && this.state.I_ADR !== null) ? 2 : 1),
      }));
    };
  };

  handleGoLogin = () => {
    this.setState(_initState);
    this.props.navigate(routes.LOGIN);
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleChangeImage = (attach) => {
    //  const ATTACHS = this.state.ATTACHS;
    //  for (let i = 0; i < ATTACHS.length; i++) {
    //     if (ATTACHS[i].name === attach.name && ATTACHS[i].date.getTime() === attach.date.getTime()) {
    //       return;
    //     }
    //   }
    this.setState(prevState => ({ ATTACHS: [...prevState.ATTACHS, attach] }));
    this.validAttachs(true, attach.size);
  }

  handleRemoveImage = (index) => {
    this.setState(prevState => ({
      ATTACHS: [
        ...prevState.ATTACHS.slice(0, index),
        ...prevState.ATTACHS.slice(index + 1)
      ]
    }));
    const item = this.state.ATTACHS[index];
    this.validAttachs(false, item.size);
  }

  handleChangeFile = (attach) => {
    const ATTACHS_FILE = this.state.ATTACHS_FILE;
    for (let i = 0; i < ATTACHS_FILE.length; i++) {
      if (ATTACHS_FILE[i].name === attach.name) {
        return;
      }
    }
    this.setState(prevState => ({ ATTACHS_FILE: [...prevState.ATTACHS_FILE, attach] }));
    this.validAttachs(true, attach.size);
  };


  handleRemoveFile = (index) => {
    this.setState(prevState => ({
      ATTACHS_FILE: [
        ...prevState.ATTACHS_FILE.slice(0, index),
        ...prevState.ATTACHS_FILE.slice(index + 1)
      ]
    }));

    const item = this.state.ATTACHS_FILE[index];
    this.validAttachs(false, item.size);
  };

  validAttachs = (add, sizeItem) => {
    if (add)
      _sizeAttachs = _sizeAttachs + sizeItem;
    else
      _sizeAttachs = _sizeAttachs - sizeItem;

    let txtErr = '';
    if (_sizeAttachs <= 0)
      txtErr = 'Prílohy sú povinné!';

    if (_sizeAttachs > window.config.maxUploadSize) {
      let sizeInMB = (window.config.maxUploadSize / 1024) / 1024;
      txtErr = `Prekročený limit na prílohy! Max. ${sizeInMB}MB!`;
    }
    this.setState({ errorTextAttach: txtErr });
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  openModal = (imgSrc) => {
    this.setState({ showModal: true, currentImage: imgSrc });
  };

  closeModal = () => {
    this.setState({ showModal: false, currentImage: '' });
  };


  handleFileClick = (file) => {
    if (file.type === 'application/pdf') {
      downloadPDF(file.data, file.name);
    } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {

      this.openModal(`${file.data}`);
    }
  };

  render() {
    const { classes, width } = this.props;
    const { showModal, currentImage } = this.state;

    return (
      <div className='container'>
        <ImageModal show={showModal} onClose={this.closeModal} image={currentImage} />
        <CircularProgress loading={this.state.loading} />
        <AdminModeExitButton />
        <Paper className={classNames(classes.paperCardNoAppbar, (width && !this.isMobile(width) && classes.mt4))} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <React.Fragment>
            {this.state.activeStep != 5 &&
              <div >
                <div className={classNames(classes.row, classes.justifyContentStart, classes.alignItemsCenter)}>
                  <IconButton
                    className={classes.backButton}
                    color="primary"
                    aria-label="Späť"
                    onClick={this.state.activeStep === 6 ? this.handleGoBackFromReview : this.handleBack}
                    toolTip=" Späť"
                    toolTipPlace="bottom"
                  >
                    <ArrowBack
                      color='secondary'
                      fontSize='default'
                      variant='fab'
                    />
                  </IconButton>
                  <Typography variant="subtitle1">Registrácia</Typography>
                  {this.state.activeStep != 6 && <Typography variant="body1" className={classNames(classes.mlAuto)}><b>{this.state.activeStep}</b>/4</Typography>}

                </div>
                <Divider />
              </div>
            }
            {(this.state.activeStep === 1 || this.state.activeStep === 6) &&
              <div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                  Prihlasovacie údaje
                </Typography>
                <div >
                  <TextField
                    disabled={true}
                    type="email"
                    label="Prihlasovací e-mail"
                    fullwidth={true}
                    value={this.state.N_UZ}
                    errorText={this.state.errorTextN_uz}
                    error={this.state.errorTextN_uz ? true : false}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurUserName}
                    name='N_UZ'
                    imputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div >
                  <TextField
                    label="Heslo"
                    type="password"
                    disabled={this.state.activeStep === 6}
                    fullwidth={true}
                    value={this.state.HESLO}
                    errorText={this.state.errorTextHeslo}
                    error={this.state.errorTextHeslo ? true : false}
                    onChange={this.handleChange}
                    name='HESLO'
                    imputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ marginLeft: '-4px' }}>
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classNames(classes.mt2)}
                  />
                </div>
                <div >
                  <TextField
                    disabled={this.state.activeStep === 6}

                    label="Potvrdenie hesla"
                    type="password"
                    fullwidth={true}
                    value={this.state.HESLO2}
                    errorText={this.state.errorTextHeslo2}
                    error={this.state.errorTextHeslo2 ? true : false}
                    onChange={this.handleChange}
                    name='HESLO2'
                    imputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ marginLeft: '-4px' }}>
                          <LockIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classNames(classes.mt2)}
                  />
                </div>
              </div>
            }

            {(this.state.activeStep === 2 || this.state.activeStep === 6) &&
              <div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                  Osobné údaje
                </Typography>
                <div>
                  <TextField
                    fullwidth={true}
                    disabled={this.state.activeStep === 6}

                    label="Meno"
                    value={this.state.MENO}
                    onChange={this.handleChange}
                    errorText={this.state.errorTextMeno}
                    error={this.state.errorTextMeno ? true : false}
                    name='MENO'
                  />
                </div>
                <div >
                  <TextField
                    fullwidth={true}
                    disabled={this.state.activeStep === 6}

                    label="Priezvisko"
                    value={this.state.PF}
                    onChange={this.handleChange}
                    errorText={this.state.errorTextPf}
                    error={this.state.errorTextPf ? true : false}
                    name='PF'
                    className={classNames(classes.mt2)}
                  />
                </div>
                <div >
                  <TextField
                    label="Rodné číslo"
                    disabled={this.state.havePersonEid || this.state.activeStep === 6}
                    fullwidth={true}
                    value={this.state.ICO_RC}
                    errorText={this.state.errorTextIco_rc}
                    error={this.state.errorTextIco_rc ? true : false}
                    onChange={this.handleChange}
                    onBlur={this.handleBlurRc}
                    name='ICO_RC'
                    className={classNames(classes.mt2)}
                  />
                </div>
                <div >
                  <DatePicker
                    disabled={this.state.activeStep === 6}

                    label="Dátum narodenia"
                    fullWidth={true}
                    value={this.state.D_NAR}
                    onChange={this.handleChange}
                    clearable={true}
                    name='D_NAR'
                    keyboards={true}
                    disablePast={false}
                    disableFuture={true}
                    className={classNames(classes.mt2)}
                  />
                </div>
              </div>
            }

            {(this.state.activeStep === 3 || this.state.activeStep === 6) &&
              <div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                  Adresa
                </Typography>
                <Autocomplete
                  disabled={this.state.activeStep === 6}

                  data={this.props.listCountries}
                  schema={{ label: 'N_STAT', value: 'I_STAT' }}
                  onChange={this.handleSearchCountries}
                  onSelect={this.handleChange}
                  selectedItem={this.state.krajina}
                  label="Štát"
                  name='krajina'
                  fullwidth={true}
                  className={classNames(classes.mt2)}
                  errorText={this.state.errorTextKrajina}
                  error={this.state.errorTextKrajina ? true : false}
                  countCharStart={_autocompleteCountCharStart}
                  timeDelay={_autocompleteTimeDelay}
                />
                <div>
                  <TextField
                    disabled={this.state.activeStep === 6}

                    label="Obec"
                    fullwidth={true}
                    value={this.state.N_M}
                    onChange={this.handleChange}
                    errorText={this.state.errorTextN_m}
                    error={this.state.errorTextN_m ? true : false}
                    name='N_M'
                    className={classNames(classes.mt2)}
                  />
                </div>
                <div >
                  <TextField
                    disabled={this.state.activeStep === 6}

                    label="Ulica"
                    fullwidth={true}
                    value={this.state.N_U}
                    onChange={this.handleChange}
                    name='N_U'
                    className={classNames(classes.mt2)}
                  />
                </div>
                <div className={classNames(classes.row, classes.w100)}>
                  <div className={classNames(classes.mr1, classes.w50)}>
                    <TextField
                      disabled={this.state.activeStep === 6}

                      label="Súpisné číslo"
                      fullwidth={true}
                      value={this.state.CS}
                      onChange={this.handleChange}
                      errorText={this.state.errorTextCs}
                      error={this.state.errorTextCs ? true : false}
                      name='CS'
                      className={classNames(classes.mt2)}
                      inputProps={{ maxLength: 6 }}
                    />
                  </div>
                  <div className={classNames(classes.ml1, classes.w50)}>
                    <TextField
                      disabled={this.state.activeStep === 6}

                      label="Orientačné číslo"
                      fullwidth={true}
                      value={this.state.CO}
                      onChange={this.handleChange}
                      name='CO'
                      className={classNames(classes.mt2)}
                      inputProps={{ maxLength: 10 }}
                    />
                  </div>

                </div>
              </div>
            }
            {(this.state.activeStep === 4 || this.state.activeStep === 6) &&
              <div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                  Kontaktné údaje
                </Typography>
                <div>
                  {_isDev &&
                    <TextField
                      disabled={this.state.activeStep === 6}

                      label="E-mail"
                      type="email"
                      fullwidth={true}
                      value={this.state.EMAIL}
                      errorText={this.state.errorTextEmail}
                      error={this.state.errorTextEmail ? true : false}
                      onChange={this.handleChange}
                      name='EMAIL'
                    />
                  }
                </div>
                <div >
                  <TextField
                    disabled={this.state.activeStep === 6}

                    label="Telefón"
                    fullwidth={true}
                    value={this.state.TEL}
                    errorText={this.state.errorTextTel}
                    error={this.state.errorTextTel ? true : false}
                    onChange={this.handleChange}
                    name='TEL'
                  />
                </div>
                <div className={classNames(classes.row, classes.justifyContentBetween, classes.alignItemsCenter, classes.mt3)} >
                  <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.0714285714285714rem", lineHeight: "1" }}>Prílohy</Typography>
                  <div className={classNames(classes.row)}>
                    <FileInput

                      disabled={this.props.disabled || this.state.activeStep === 6}
                      onChange={this.handleChangeFile}
                      mimetype={"application/pdf, image/jpeg, image/jpg"}
                      title={'súbory typu PDF, JPG, JPEG'}
                      iconType={2}
                    />
                    <AttachmentInput
                      disabled={this.props.disabled || this.state.activeStep === 6}
                      onChange={this.handleChangeImage}
                      iconType={1}
                    />
                    <InfoButton
                      N_KLUC="DM_CREATE_ACC_ATTACHS_INFO"
                      currEdited={ACCOUNT_PERMIT_DIALOG}
                      style={{ padding: '12px' }}
                      title={'Prílohy'}
                    />
                  </div>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS.map((image, index) =>
                    <Attachment
                      disabled={this.props.disabled || this.state.activeStep === 6}
                      key={index}
                      image={image}
                      onClick={() => { this.handleFileClick(image); }}
                      onRemove={() => this.handleRemoveImage(index)}
                    />
                  )}
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_FILE.map((file, index) =>
                    <AttachmentFile
                      disabled={this.props.disabled || this.state.activeStep === 6}
                      key={index}
                      image={file}
                      onRemove={() => this.handleRemoveFile(index)}
                      onClick={() => this.handleFileClick(file)}
                    />
                  )}
                </div>
                {this.state.errorTextAttach &&
                  <Typography style={{ color: 'red' }}> {this.state.errorTextAttach}</Typography>
                }
                <div>
                  <CheckBox
                    disabled={this.state.activeStep === 6}

                    toolTip={ReactDOMServer.renderToString(this.state.textGdpr).replace(/(<([^>]+)>)/ig, '')}
                    toolTipPlace="bottom-start"
                    color='secondary'
                    checked={this.state.gdpr}
                    onChange={this.handleChangeGdpr('gdpr')}
                    value='gdpr'
                    label='súhlasím so spracovaním'
                    style={{ marginLeft: '10px', marginRight: '3px', width: '0px' }}
                    styleLabel={{ marginLeft: '1px', marginRight: '3px' }}
                  />
                  <Button
                    onClick={this.handleInfoGdpr}
                    style={{ padding: '0px 0px', minWidth: '0px', textTransform: 'none', }}
                  >
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}> osobných údajov</Typography>
                  </Button>
                </div>
                {this.state.text.length > 0 &&
                  <div className={classNames(classes.mb4, classes.mt2,)}>
                    <Typography variant="caption" >
                      {this.state.text}
                    </Typography>
                  </div>
                }
                <div className={classNames(classes.col)} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  {this.state.activeStep !== 6 && <RaisedButton style={{ width: '80%', maxWidth: '350px', padding: '13px 30px', border: "1px solid grey", backgroundColor: "white" }} onClick={this.handleGoToReview} aria-label="Náhľad">
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#000' }}>Náhľad</Typography>
                      <img src={'assets/nahlad_button.svg'} alt='Náhľad' style={{ width: '40px', height: '40px', color: "black" }} />
                    </div>
                  </RaisedButton>}
                  <RaisedButton
                    className={classNames(classes.raisedButton)}
                    onClick={this.handleAdd}
                    disabled={!this.state.gdpr}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Odoslať
                      </Typography>
                      <ArrowForward
                        color='primary'
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>
            }
            {this.state.activeStep > 0 && this.state.activeStep < 4 &&
              <div className='center' style={{ marginTop: '10%', marginBottom: '10%' }}>

                <Button
                  color="primary"
                  aria-label="Ďalej"
                  onClick={this.handleNext}
                >
                  <img style={{ width: 90, height: 90 }} src={'assets/arrow_forward-white.svg'} alt='Ďalej' />
                </Button>
              </div>
            }
            {this.state.activeStep === 5 &&
              <div>
                <div className={classNames(classes.mb4)}>
                  <Button
                    color="secondary"
                    aria-label="Prihlásenie"
                    onClick={this.handleGoLogin}
                  >
                    <ArrowBack
                      color='secondary'
                      fontSize='default'
                      variant='fab'
                    />
                  </Button>
                </div>
                <div className='center'>
                  <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='ŽIADOSŤ ODOSLANÁ' />
                </div>
                <div className={classNames(classes.mt3, classes.textCenter)}>
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    VAŠA ŽIADOSŤ BOLA <br />
                    ÚSPEŠNE ODOSLANÁ
                  </Typography>
                </div>
                <div className={classNames(classes.mt4, classes.mb4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    POČKAJTE, PROSÍM, <br />
                    NA OVERENIE VAŠICH ÚDAJOV V <br />
                    INFORMAČNOM SYSTÉME MESTA.
                  </Typography>
                </div>
              </div>
            }
          </React.Fragment>
        </Paper>
        <Dialog
          open={this.state.openGdpr}
          onClose={this.handleInfoGdpr}
          contentText={ReactDOMServer.renderToString(this.state.textGdpr)}
          title={"GDPR"}
          actions={[
            {
              label: "Zatvoriť",
              color: 'primary',
              onClick: this.handleInfoGdpr,
              keyboardFocused: true
            }
          ]}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listCountries: state.listCountries.data,
    peopleByRc: state.peopleByRc.data,
    personEid: state.personEid.data,
    locale: state.locale.locale,
    urlGdpr: state.urlGdpr.data.Data,
    verifyEmail: state.verifyEmail.data,
    userByName: state.userByName.data,
    ziadostByName: state.ziadostByName.data,
    passwordPattern: state.passwordPattern.data.Data,
    passwordErrMsg: state.passwordErrMsg.data.Data,
    adminMode: state.adminMode
  }
}


export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(AccountPermit)))));