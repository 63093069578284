import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import { alpha } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";

const styles = (theme) => ({
  ...getSharedStyles(theme), // assuming this function returns an object
  container: {
    display: "flex",
    // flex: 1,
    // width: "20%",
    // position: "relative",
  },
  text: {
    fontSize: "15px",
    fontWeight: "200",
    color: "black",
  },
  exportBtn: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.lightBlue.main,
    "&:hover": {
      backgroundColor: theme.palette.lightBlue.dark,
    },
    textTransform: 'none',
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    transform: "translatex(20%)",
    zIndex: theme.zIndex.tooltip,
    backgroundColor: "white",
    flexDirection: "column",
    minWidth: "120%",
    width: "fit-content",
    whiteSpace: "nowrap",
    borderRadius: theme.spacing(0.5),
    border: "2px solid white",
  },
  dropdownButton: {
    // borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px 20px",
    color: "black",
    cursor: "pointer",
    border: "none",
    width: "100%",
    "&:hover": {},
  },
});

class ExportBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ showDropdown: true });
  };

  handleMouseLeave = () => {
    this.setState({ showDropdown: false });
  };

  render() {
    const { classes } = this.props;
    const { showDropdown } = this.state;
    return (
      <div
        className={classNames(classes.container)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Button className={classNames(classes.exportBtn)}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          px={1}
        >
          Export
          <div
            className={classNames(classes.dropdown)}
            style={{
              display: showDropdown ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            {/* <Button
              onClick={() => this.props.handleJsonExportBtnClick()}
              toolTip="Stiahnuť JSON súbor"
              className={classNames(classes.dropdownButton)}
              startIcon={<InsertDriveFileIcon />}
            >
              JSON
            </Button>
            <Button
              onClick={() => this.props.handleCsvExportBtnClick()}
              toolTip="Stiahnuť CSV súbor"
              className={classNames(classes.dropdownButton)}
              startIcon={<InsertDriveFileIcon />}
            >
              CSV
            </Button> */}
            <Button
              onClick={() => this.props.postExportData("xlsx")}
              toolTip="Stiahnuť XLSX súbor"
              className={classNames(classes.dropdownButton)}
              startIcon={<InsertDriveFileIcon />}
            >
              XLSX
            </Button>
            <Button
              onClick={() => this.props.postExportData("pdf")}
              toolTip="Stiahnuť PDF súbor"
              className={classNames(classes.dropdownButton)}
              startIcon={<InsertDriveFileIcon />}
            >
              PDF
            </Button>
          </div>
        </Button>
      </div>
    );
  }
}

ExportBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  handleCsvExportBtnClick: PropTypes.func.isRequired,
  handleJsonExportBtnClick: PropTypes.func.isRequired,
  postExportData: PropTypes.func.isRequired,
};

export default withStyles(styles)(ExportBtn);
