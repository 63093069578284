import * as React from 'react';
import Button from '../inputs/ToolBarButton';
import DropDownButton from '../inputs/DropDownButton';
import SplitButton from '../inputs/SplitButton';
import PropTypes from 'prop-types';
import flags from '../utils/flags';
import { buildUrl } from '../utils/helper';
import './Toolbar.scss';
import TextField from '../inputs/TextField'
import SearchIcon from '../dataDisplay/icons/SearchIcon';
import InputAdornment from '@material-ui/core/InputAdornment';
import SelectFilterField from '../inputs/SelectFilterField';
import SelectField from '../inputs/SelectField';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'

/**
 * Toolbar grid component
 * @module
 * @param {func} [onAdd] - Function invoked on entry add
 * @param {func} [onDelete] - Function invoked on entry delete
 * @param {func} [onCopy] - Function invoked on entry copy
 * @param {func} [onCancel] - Function invoked on entry cancel
 * @param {func} onRefresh - Function invoked on refresh
 * @param {func} onClear - Function invoked on clear
 * @param {element} [children] - Children element
 * @param {number} [disabled] - Flags for disabling actions
 * @param {number} [filterLength] - Filter length
 * @param {func} onSwitchFilterable - On switch filterable function
 * @param {number} [hidden] - Flags for hiding actions
 * @param {string} [imgName] - Image name
 * @param {func} onExport - Function invoked on file export
 * @param {func} [onPrint] - Function invoked on print
 * @param {func} onReset - Function invoked on reset
 * @param {func} onUpdateAll - Function invoked on update all entries
 * @param {func} onAbortChangesAll - Function invoked on abort all changes
 * @param {func} onEditAll - Function invoked on edit all entries
 * @param {array} selected - Selected entries
 * @param {bool} [showToolbarButtons] - Show buttons with labels (not icons)  in toolbar
 * @param {string} [customToolbarBreadcrumb] - display custom breadcrumb in toolbar
 */


const styles = theme => ({
  ...getSharedStyles(theme),

})

class ToolbarComponent extends React.Component {

  constructor(props) {
    super(props);

    var org = '{ "organizations" : [' +
      '{ "I_ORG":"1" , "N_ORG":"MsÚ Vyšný Skálnik" },' +
      '{ "I_ORG":"2" , "N_ORG":"ZŠ Vyšný Skálnik" },' +
      '{ "I_ORG":"3" , "N_ORG":"Gymnázium Vyšný Skálnik" }]}';

    const organizations = JSON.parse(org);

    var year = '{ "years" : [' +
      '{ "YEAR":"2022" , "N_YEAR":"2022" },' +
      '{ "YEAR":"2021" , "N_YEAR":"2021" },' +
      '{ "YEAR":"2020" , "N_YEAR":"2020" } ]}';

    const years = JSON.parse(year);

    this.state = {
      editingAll: (props.hidden & flags.EDITALL) === 0 ? false : undefined,
      search: '',
      errorTextSearch: '',
      listOrgaizations: organizations.organizations,
      I_ORG: 0,
      listYears: years.years,
      YEAR: 2022
    }
  }

  handleChange = async (name, value) => {

    this.setState({ [name]: value });
  };

  handlePrint = (index, name) => {
    this.props.onPrint(index, name);
  }

  handleEditAll = () => {
    this.setState({
      editingAll: true
    })
    this.props.onEditAll()
  }

  handleUpdateAll = () => {
    if (this.props.onUpdateAll()) {
      this.setState({
        editingAll: false
      });
    }

  }

  handleAbortChangesAll = () => {
    this.setState({
      editingAll: false
    })
    this.props.onAbortChangesAll()
  }

  handleFrmClick = (index, text) => {
    this.props.handleChangeFrm('frm', this.props.frms[index]);
  }

  getUserFilterList = () => {
    let userFilterList = this.props.userFilterList.map(x => {
      if (x.id === this.props.filter.id) {
        return { text: x.name, imageUrl: buildUrl('assets/toolbar_cg_filter-active.svg') }
      }
      return { text: x.name, imageUrl: buildUrl('assets/toolbar_cg_filter-add.svg') }
    });
    return userFilterList;
  }

  render() {
    const { classes } = this.props;
    const isSelected = this.props.selected && this.props.selected.length === 1;
    const isMultiSelected = this.props.selected && this.props.selected.length >= 1;
    let printItems = [{ text: "Tlač mriežky do XLSX", imageUrl: buildUrl('assets/toolbar_cg_xlsx.svg') },
    { text: "Tlač mriežky do PDF", imageUrl: buildUrl('assets/toolbar_cg_pdf.svg') }];
    if (this.props.printItems) { printItems = [...printItems, ...this.props.printItems] }
    return (
      <>
        {this.props.customToolbarBreadcrumb ? (<span className="custom-breadcrumb">{this.props.customToolbarBreadcrumb}</span>) : null}
        <div id="grid-toolbar" className={this.props.customToolbarBreadcrumb ? "custom-breadcrumb-no-margin" : ""}>
          {!(this.props.toolbarHidden || (this.props.hidden & flags.LEFT_TOOLBAR)) &&
            <span>
              <div className={classNames(classes.row, classes.w100)} style={{ justifyContent: 'flex-start' }}>
                <TextField
                  label='Hľadanie'
                  fullwidth={false}
                  value={this.state.search}
                  errorText={this.state.errorTextSearch}
                  error={this.state.errorTextSearch ? true : false}
                  onChange={this.handleSearch}
                  name='search'
                  style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '15px', marginRight: '30px' }}
                  margin="normal"
                  variant="outlined"
                  imputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                        <SearchIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '2px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <SelectFilterField
                  floatingLabelText="Organizácia"
                  required={true}
                  value={this.state.I_ORG}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_ORG', value: 'I_ORG' }}
                  data={this.state.listOrgaizations}
                  name='ORGANIZATION'
                  style={{ marginTop: '20px', marginBottom: '10px', marginLeft: '35px', justifyContent: 'start' }}
                />
                {/* <SelectField
                  floatingLabelText="Organizácia"
                  value={this.state.I_ORG}
                  onChange={this.handleChange}
                  fullwidth={false}
                  schema={{ label: 'N_ORG', value: 'I_ORG' }}
                  data={this.state.listOrgaizations}
                  name='ORGANIZATION'
                  style={{ marginRight: '30px', width: '50px' }}
                /> */}
                <SelectFilterField
                  floatingLabelText="Rok"
                  required={true}
                  value={this.state.ROK}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_YEAR', value: 'YEAR' }}
                  data={this.state.listYears}
                  name='YEAR'
                  style={{ marginTop: '20px', marginBottom: '10px', justifyContent: 'start' }}
                />
                {/* <SelectField
                  floatingLabelText="Rok"
                  value={this.state.ROK}
                  onChange={this.handleChange}
                  fullwidth={false}
                  schema={{ label: 'N_YEAR', value: 'I_YEAR' }}
                  data={this.state.listYears}
                  style={{ marginRight: '40px', marginLeft: '40px', width: '50px' }}
                  name=' YEAR'
                /> */}
              </div>
              {/* {this.props.showToolbarButtons && this.props.data && this.props.data.find((item) => item.inEdit !== undefined || item.hasChanged) && (
                <>
                  <Button onClick={this.handleUpdateAll} label="Uložiť zmeny" title="Uložiť zmeny" className="button-changes button-save-changes" />
                  <Button onClick={this.handleAbortChangesAll} label="Zrušiť zmeny" title="Zrušiť zmeny" className="button-changes button-cancel-changes" />
                </>
              )}
              {!(this.props.hidden & flags.ADD) &&
                <Button
                  onClick={this.props.onAdd}
                  primary={true}
                  disabled={this.props.disabled & (flags.ADD | flags.LEFT_TOOLBAR | flags.TOOLBAR)}
                  title="Nový záznam"
                  icon="novy-zaznam"
                  imageUrl={buildUrl('assets/toolbar_cg_new.svg')}
                />
              }
              {!(this.props.hidden & flags.COPY) &&
                <Button
                  onClick={this.props.onCopy}
                  disabled={!isSelected || (this.props.disabled & (flags.COPY | flags.LEFT_TOOLBAR | flags.TOOLBAR))}
                  primary={true}
                  title="Nový záznam ako kópia"
                  imageUrl={buildUrl('assets/toolbar_cg_copy.svg')}
                />
              }
              {!(this.props.hidden & flags.CANCEL) &&
                <Button
                  onClick={this.props.onCancel}
                  disabled={!isSelected || (this.props.disabled & (flags.CANCEL | flags.LEFT_TOOLBAR | flags.TOOLBAR))}
                  primary={true}
                  icon="cancel"
                  title="Zrušenie záznamu"
                  imageUrl={buildUrl('assets/toolbar_cg_cancel.svg')}
                />
              }
              {(this.props.hidden & flags.DELETE) !== flags.DELETE &&
                <Button
                  onClick={this.props.onDelete}
                  disabled={(((this.props.disabled & flags.DELETE) === flags.DELETE || !isSelected) && ((this.props.disabled & (flags.DELETEALL | flags.LEFT_TOOLBAR | flags.TOOLBAR) || this.props.selected.length === 0)))}
                  primary={true}
                  icon="delete"
                  title="Zmazanie záznamu"
                  imageUrl={buildUrl('assets/toolbar_cg_delete.svg')}
                />
              }
              {(this.state.editingAll === false && (this.props.hidden & flags.EDITCELL) === 1) &&
                <Button
                  onClick={this.handleEditAll}
                  primary={true}
                  icon="track-changes-enable"
                  title="Upraviť všetky"
                  disabled={this.props.disabled & flags.TOOLBAR}
                />
              }
              {!this.props.showToolbarButtons && (this.state.editingAll === true || (this.props.hidden & flags.EDITCELL) === 0) &&
                <>
                  <Button
                    onClick={this.handleUpdateAll}
                    primary={true}
                    icon="track-changes-accept"
                    title="Uložiť zmeny"
                    disabled={this.props.disabled & flags.TOOLBAR}
                  />
                  <Button
                    onClick={this.handleAbortChangesAll}
                    primary={true}
                    icon="track-changes-reject"
                    title="Zrušiť úpravu"
                    disabled={this.props.disabled & flags.TOOLBAR}
                  />
                </>
              }
              {this.props.frms.length !== 0 &&
                <DropDownButton
                  text=""
                  title="Podradené formuláre"
                  imageUrl={buildUrl('assets/toolbar_podriad_form.svg')}
                  items={
                    this.props.frms.map(x => { return { text: x.Name, icon: "window" } })
                  }
                  onClick={this.handleFrmClick}
                  popupSettings={{ animate: false, popupClass: 'subform-button-popup' }}
                  disabled={!isMultiSelected}
                />
              } */}

              {/* {this.props.children} */}
            </span>
          }
          {/* <span className="right">
            {(!(this.props.hidden & flags.PRINT)) &&
              <DropDownButton
                text=""
                popupSettings={{ popupClass: 'dropdown-print' }}
                icon="print"
                title="Tlač"
                items={printItems}
                onClick={this.handlePrint}
                disabled={Boolean(this.props.disabled & (flags.PRINT | flags.TOOLBAR))}
                imageUrl={buildUrl('assets/toolbar_cg_print.svg')}
              />
            }
            {!(this.props.hidden & (flags.SORT_DIALOG | flags.SORT)) &&
              <Button
                onClick={this.props.handleOpenSort}
                primary={true}
                icon="sort-desc"
                title="Zoradenie"
                disabled={this.props.disabled & (flags.SORT | flags.SORT_DIALOG | flags.TOOLBAR)}
              />
            }
            {(!(this.props.hidden & flags.FILTER)) &&
              <span>
                {this.props.userFilterList.length === 0 ?
                  <Button
                    onClick={this.props.handleOpenFilter}
                    imageUrl={this.props.filterLength > 0 ? buildUrl('assets/toolbar_cg_filter-active.svg') : buildUrl('assets/toolbar_cg_filter-add.svg')}
                    title="Filter"
                    disabled={this.props.disabled & (flags.FILTER | flags.TOOLBAR)}
                  />
                  :
                  <SplitButton
                    className="compact-split-button"
                    onClick={(value) => value === -1 ? this.props.handleOpenFilter() : this.props.handleConfirmFilter(this.props.userFilterList[value])}
                    imageUrl={this.props.filterLength > 0 ? buildUrl('assets/toolbar_cg_filter-active.svg') : buildUrl('assets/toolbar_cg_filter-add.svg')}
                    title="Filter"
                    tooltip="Filter"
                    text=""
                    disabled={!!(this.props.disabled & (flags.FILTER | flags.TOOLBAR))}
                    popupSettings={{ animate: false }}
                    items={this.getUserFilterList()}
                  />
                }
                <Button
                  onClick={this.props.onClear}
                  icon="filter-clear"
                  title="Zrušenie filtra"
                  imageUrl={buildUrl('assets/toolbar_cg_filter.svg')}
                  disabled={this.props.disabled & (flags.FILTER | flags.TOOLBAR)}
                />
                <Button
                  onClick={this.props.onSwitchFilterable}
                  imageUrl={this.props.filterable ? buildUrl('assets/toolbar_cg_minimize.svg') : buildUrl('assets/toolbar_cg_expand.svg')}
                  title="Mód"
                  disabled={this.props.disabled & (flags.FILTER | flags.TOOLBAR)}
                />
              </span>
            }
            {!(this.props.hidden & flags.RESET) &&
              <Button
                onClick={this.props.onReset}
                icon="reset-sm"
                title="Reset"
                disabled={(this.props.disabled & (flags.RESET | flags.TOOLBAR))}
              />
            }
            {this.props.imgName &&
              <Button
                onClick={this.props.onExport}
                icon="image-export"
                title="Export"
                imageUrl={buildUrl('assets/toolbar_cg_export.svg')}
                disabled={(this.props.disabled & flags.TOOLBAR)}
              />
            }
            <Button
              onClick={this.props.onRefresh}
              icon="refresh"
              title="Obnovenie"
              imageUrl={buildUrl('assets/toolbar_cg_refresh.svg')}
              disabled={this.props.disabled & flags.TOOLBAR}
            />
          </span> */}
        </div>
      </>
    );
  }
}

ToolbarComponent.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onCancel: PropTypes.func,
  onRefresh: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]),
  disabled: PropTypes.number,
  filterLength: PropTypes.number,
  onSwitchFilterable: PropTypes.func.isRequired,
  hidden: PropTypes.number,
  imgName: PropTypes.string,
  onExport: PropTypes.func.isRequired,
  onPrint: PropTypes.func,
  onReset: PropTypes.func.isRequired,
  onUpdateAll: PropTypes.func,
  onAbortChangesAll: PropTypes.func,
  onEditAll: PropTypes.func,
  selected: PropTypes.array.isRequired,
  handleOpenSort: PropTypes.func,
  showToolbarButtons: PropTypes.bool,
  customToolbarBreadcrumb: PropTypes.string,
  data: PropTypes.array,
  withMeta: PropTypes.bool,
  isPicker: PropTypes.bool,
  handleConfirmFilter: PropTypes.func,
  userFilterList: PropTypes.array,
}

export default withStyles(styles)(ToolbarComponent);