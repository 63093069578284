import actionTypes from '../../actions/actionTypes';

const _init = {
  I_VERIFY_EMAIL: null,
  EMAIL: '',
  L_VERIFY: false
}

const _initState = {
  isLoading: false,
  data: _init
}

const verifyEmail = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_VERIFY_EMAIL_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_VERIFY_EMAIL_SUCCESS:
      return { isLoading: false, data: action.res ? action.res.Data : _init }

    case actionTypes.GET_VERIFY_EMAIL_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default verifyEmail;