import React from 'react';
import Icon from '@material-ui/icons/BarChart';
import PropTypes from 'prop-types';
import Paper from '../../surfaces/Paper';

class ChartBarIconComponent extends React.Component {
  render() {
    return (
      <Paper
        elevation={2}
        className={this.props.className}
      >
        <Icon
          style={this.props.style}
          color={this.props.color}
          fontSize={this.props.fontSize}
        />
      </Paper>
    );
  }
}

ChartBarIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
};

export default ChartBarIconComponent;
