import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, alpha } from '@material-ui/core/styles';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    width: '100%',
  },
  input: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  inputActive: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: 'green',
  },
  iconButton: {
    padding: 7,
    color: theme.palette.text.primary,
  },
  iconButtonActive: {
    padding: 7,
    color: 'green',
  },
  fltSearch: {
    width: '100%',
    verticalAlign: 'middle',
  },
  fltSearchActive: {
    width: '100%',
    color: 'green',
  },
  fltDiv: {
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  fltDivActive: {
    backgroundColor: 'rgb(246, 255, 245)',
    '&:hover': {
      backgroundColor: 'rgb(216, 225, 215)',
    },

  },
}));

const SearchFilterComponent = ({ searchText: initialSearchText = '', placeholderText, onClick }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState(initialSearchText ?? "");
  const [searchActive, setSearchActive] = useState(false);

  const handleOnClick = () => {
    if (searchText.length < 3) {
      setSearchActive(false);
      onClick('');
    } else {
      setSearchActive(true);
      onClick(searchText);
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <>
      <Box
        className={classNames(
          searchActive ? classes.fltDivActive : classes.fltDiv,
          searchActive ? classes.fltSearchActive : classes.fltSearch,
          classes.root
        )}
      >
        <TextField
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleOnClick();
            }
          }}
          type="search"
          value={searchText}
          label="Hľadanie"
          aria-label="Search"
          placeholder={placeholderText}
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton 
                  onClick={handleOnClick}
                  aria-label="Search"
                  edge="end"
                  type="button"
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

SearchFilterComponent.propTypes = {
  searchText: PropTypes.string,
  placeholderText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default SearchFilterComponent;
