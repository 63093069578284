import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class SecurityIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style.transform} >
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M16,10.4
		c0,3.3-6.5,7.6-6.5,7.6S3,13.7,3,10.4V2.8c0,0,4.9,0.3,6.5-1.8C11,3.1,16,2.8,16,2.8V10.4z"/>
          <polyline fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="6,9 8,11 13,6 	
		"/>
        </g>
      </SvgIcon>
    );
  }
}

SecurityIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default SecurityIconComponent;
