import React, { useState, useCallback } from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Menu from "../../coraWebMComponents/dataDisplay/Menu";
import ChartBarIcon from "../../coraWebMComponents/dataDisplay/icons/ChartBarIcon";

import { getChartIcon } from './helper';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme), // assuming this function returns an object
  container: {
    display: "flex",
    // flex: 1,
    // width: "20%",
    // position: "relative",
  },
  text: {
    fontSize: "15px",
    fontWeight: "200",
    color: "black",
  },
  riRoot: {
    display: "inline-flex",
    alignItems: "center",
  },
  riBtn: {
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    opacity: 0.7,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.10),
      opacity: 1,
      cursor: "pointer",
    },
  },
  riIcon: {
    width: "1.8rem",
    textAlign: "center",
    color: alpha(theme.palette.common.black, 0.87),
  },
  rimItem: {},
  rimItemIcon: {
    color: alpha(theme.palette.common.black, 0.87),
  },
  rimText: {
    color: alpha(theme.palette.common.black, 0.87),
    fontSize: theme.palette.secondary.fontSize,
    textTransform: "none",
  },
  fltNoHasValue: {
    color: alpha(theme.palette.common.black, 0.87),
  },
}));

const ChartOptionsButton = ({ charts, onClick, anchorOrigin = { vertical: "top", horizontal: "right" }, transformOrigin = { vertical: "top", horizontal: "right" } }) => {
  const classes = useStyles();
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setShowDropdown(true);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setShowDropdown(false);
  };

  // nacitanie ikony podla typu
  const GetIcon = ({ type, classes }) => {
    return getChartIcon(type, classes);
  };

  return (
    <Box
      className={classes.riRoot}
    >
      <Stack
        className={classes.riBtn}
        width={"2.5rem"}
        height={"2.5rem"}
        alignItems="center"
        justifyContent="center">
        <Stack
          title="Grafy"
          className={classNames(classes.container)}
          onClick={handleMenuOpen}
          flexDirection="row"
          justifyContent="flex-end"
        >
          <ChartBarIcon className={classes.riIcon} />
        </Stack>
      </Stack>

      <Menu
        className={classes.rim}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={showDropdown}
        onClose={handleMenuClose}
        toolTip={`Graf`}
      >
        {/* Vygenerovanie buttonov pre vsetky grafy */}
        {charts.sort((a, b) => a.cZorad - b.cZorad).map((chart, index) => {
          const buttonText = chart.args ? (chart.args.buttonTitle ? chart.args.buttonTitle : (chart.grafTyp?.nGrafTyp ?? `Graf ${index}`)) : `Graf ${index}`;

          // TODO: overit - negenerovat polozku ak je chyba errorMessage
          if (chart.errorMessage) {
            return null;
          }

          return (
            <MenuItem
              key={index}
              // className={classes.rimItem}
              onClick={() => {
                handleMenuClose();
                onClick(index);
              }}
            >
              <Button
                variant="text"
                className={classNames(classes.rimBtn, classes.fltNoHasValue)}
                startIcon={<GetIcon type={chart.grafTyp?.skrGrafTyp} classes={classNames(classes.rimItemIcon, classes.fltNoHasValue)} />}
                endIcon={<NavigateNextIcon className={classes.rimItemIcon} />}
              >
                <Typography className={classNames(classes.rimText, classes.fltNoHasValue)} title={buttonText}>
                  {buttonText}
                </Typography>
              </Button>
            </MenuItem>
          );
        })}
      </Menu>
    </Box >
  );
};

ChartOptionsButton.propTypes = {
};

export default ChartOptionsButton;
