import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DogIcon from '../dataDisplay/icons/DogIcon';
import PersonIcon from '../dataDisplay/icons/PersonIcon';
import PeopleIcon from '../dataDisplay/icons/PeopleIcon';
import ParkingIcon from '../dataDisplay/icons/ParkingIcon';
import ParkingCard from '../dataDisplay/icons/ParkingCard';
import MapIcon from '../dataDisplay/icons/MapIcon';
import ParkingPermit from '../dataDisplay/icons/ParkingPermit';
import AddBlackIcon from '../dataDisplay/icons/AddBlackIcon';
import Typography from '../dataDisplay/Typography';
import DescriptionIcon from '../dataDisplay/icons/DescriptionIcon';
import EuroIcon from '../dataDisplay/icons/EuroIcon';
import TicketIcon from '../dataDisplay/icons/TicketIcon';
import SetingsIcon from '../dataDisplay/icons/SetingsIcon';
import SubsidiesIcon from '../dataDisplay/icons/SubsidiesIcon';
import SecurityIcon from '../dataDisplay/icons/SecurityIcon';
import BudgetIcon from '../dataDisplay/icons/BudgetIcon';
import AdminSegmentationIcon from '../dataDisplay/icons/AdminSegmentationIcon';
import DebtorsIcon from '../dataDisplay/icons/DebtorsIcon';
import GraveIcon from '../dataDisplay/icons/GraveIcon';
import CitizensIcon from '../dataDisplay/icons/CitizensIcon';
import TowingIcon from '../dataDisplay/icons/TowingIcon';
import PublicationIcon from '../dataDisplay/icons/PublicationIcon';
import OrganizationIcon from '../dataDisplay/icons/OrganizationIcon';
import FirmIcon from '../dataDisplay/icons/FirmIcon';
import ShopIcon from '../dataDisplay/icons/ShopIcon';
import SelfGovIcon from '../dataDisplay/icons/SelfGovIcon';
import EducationIcon from '../dataDisplay/icons/EducationIcon';
import SocialServiceIcon from '../dataDisplay/icons/SocialServiceIcon';
import ConstructionOfficeIcon from '../dataDisplay/icons/ConstructionOfficeIcon';
import ElectionIcon from '../dataDisplay/icons/ElectionIcon';
import DefectsIcon from '../dataDisplay/icons/DefectsIcon';
import ImportantObjectsIcon from '../dataDisplay/icons/ImportantObjectsIcon';
import PetitionsIcon from '../dataDisplay/icons/PetitionsIcon';

import getSharedStyles from '../sharedStyles';

const styles = theme => ({
  ...getSharedStyles(theme),
  itemMenuPaper: {
    marginLeft: '5px',
    marginRight: '5px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '15px',
      marginRight: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
  primaryItemText: {
    fontSize: 17,
    fontWeight: 'bold'
  },
  secondaryItemText: {
    fontSize: 12
  },
  iconColor: {
    stroke: theme.palette.primary.dark,
  }
});

const getIcon = (icon) => {
  const style = { width: '30px', height: '30px' };
  switch (icon) {
    case 'PersonIcon':
      return <PersonIcon style={style} />;
    case 'ParkingIcon':
      return <ParkingIcon style={style} />;
    case 'ParkingCard':
      return <ParkingCard style={style} />;
    case 'ParkingPermit':
      return <ParkingPermit />;
    case 'DogIcon':
      return <DogIcon style={{ ...style }}
      />;
    case 'AddBlackIcon':
      return <AddBlackIcon style={{ ...style, color: '#66bb6a' }} />;
    case 'DescriptionIcon':
      return <DescriptionIcon style={style} />;
    case 'EuroIcon':
      return <EuroIcon style={style} />;
    case 'PeopleIcon':
      return <PeopleIcon style={style} />;
    case 'TicketIcon':
      return <TicketIcon style={style} />;
    case 'SetingsIcon':
      return <SetingsIcon style={style} />;
    case 'MapIcon':
      return <MapIcon style={{ style, stroke: "#757575" }} />;
    case 'SubsidiesPublicIcon':
      return <SubsidiesIcon style={{ ...style, stroke: "#757575" }} />;
    case 'SecurityIcon':
      return <SecurityIcon style={{ ...style, stroke: "#757575" }} />;
    case 'BudgetIcon':
      return <BudgetIcon style={{ ...style, stroke: "#757575" }} />;
    case 'AdminSegmentationIcon':
      return <AdminSegmentationIcon style={{ ...style, stroke: "#757575" }} />;
    case 'DebtorsIcon':
      return <DebtorsIcon style={{ ...style, stroke: "#757575" }} />;
    case 'GraveIcon':
      return <GraveIcon style={{ ...style, stroke: "#757575" }} />;
    case 'CitizensIcon':
      return <CitizensIcon style={{ ...style, stroke: "#757575" }} />;
    case 'TowingIcon':
      return <TowingIcon style={{ ...style, stroke: "#757575" }} />;
    case 'PublicationIcon':
      // return <PublicationIcon style={{ ...style }} />;
      return <PublicationIcon style={{ ...style, stroke: "#757575" }} />;
    case 'OrganizationIcon':
      return <OrganizationIcon style={{ ...style, stroke: "#757575" }} />;
    case 'FirmIcon':
      return <FirmIcon style={{ ...style, stroke: "#757575" }} />;
    case 'ShopIcon':
      return <ShopIcon style={{ ...style, stroke: "#757575" }} />;
    case 'SelfGovIcon':
      return <SelfGovIcon style={{ ...style, stroke: "#757575" }} />;
    case 'EducationIcon':
      return <EducationIcon style={{ ...style, stroke: "#757575" }} />;
    case 'SocialServiceIcon':
      return <SocialServiceIcon style={{ ...style, stroke: "#757575" }} />;
    case 'ConstructionOfficeIcon':
      return <ConstructionOfficeIcon style={{ ...style, stroke: "#757575" }} />;
    case 'ElectionIcon':
      return <ElectionIcon style={{ ...style, stroke: "#757575" }} />;
    case 'DefectsIcon':
      return <DefectsIcon style={{ ...style, stroke: "#757575" }} />;
    case 'ImportantObjectsIcon':
      return <ImportantObjectsIcon style={{ ...style, stroke: "#757575" }} />;
    case 'PetitionsIcon':
      return <PetitionsIcon style={{ ...style, stroke: "#757575" }} />;
    default:
      return <PersonIcon style={style} />;
  }
};

// Function to reorder the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class ListItemMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuFields: this.props.menu,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const menuFields = reorder(
      this.state.menuFields,
      result.source.index,
      result.destination.index
    );

    this.setState({
      menuFields,
    });
    this.props.onEdit(menuFields);
  }

  handleChange(ev, id) {
    const { name, value } = ev.target;
    const menuFields = this.state.menuFields?.map((field) => {
      if (field.MenuID === id) {
        return { ...field, [name]: value };
      }
      return field;
    });
    this.setState({ menuFields });
    this.props.onEdit(menuFields);
  }

  toogleVisibility(id) {
    const menuFields = this.state.menuFields?.map((field) => {
      if (field.MenuID === id) {
        return { ...field, Visible: field.Visible === 0 ? 1 : 0 };
      }
      return field;
    });
    this.setState({ menuFields });
    this.props.onEdit(menuFields);
  }

  render() {
    const { classes, inEdit } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <List className={classNames(classes.mt2, this.props.className)}>
                {this.state.menuFields?.map((x, idx) => {
                  if (inEdit || x.Visible) {
                    return (
                      <Draggable
                        key={x.MenuID.toString()}
                        draggableId={x.MenuID.toString()}
                        index={idx}
                        isDragDisabled={inEdit ? false : true}
                      >
                        {(provided, snapshot) => {
                          if (snapshot.isDragging) {
                            provided.draggableProps.style.left = undefined;
                            provided.draggableProps.style.top = undefined;
                          }
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Paper
                                square={false}
                                elevation={
                                  this.props.paperElevation
                                    ? this.props.paperElevation
                                    : 2
                                }
                                className={classNames(
                                  this.props.paperClasses,
                                  classes.itemMenuPaper
                                )}
                                key={x.MenuID.toString()}
                                style={{ marginBottom: '7px' }}
                              >
                                <ListItem
                                  button
                                  alignItems='center'
                                  color={this.props.color}
                                  label={x.Title}
                                  onClick={
                                    this.props.onClick
                                      ? () => this.props.onClick(x.Route)
                                      : (ev) => ev.stopPropagation()
                                  }
                                  onMouseDown={(ev) => ev.stopPropagation()}
                                  key={x.MenuID.toString()}
                                >
                                  {inEdit && (
                                    <div {...provided.dragHandleProps}>
                                      <DragHandleIcon />
                                    </div>
                                  )}
                                  <ListItemIcon style={{ marginRight: 0 }}>
                                    {getIcon(x.Icon)}
                                  </ListItemIcon>
                                  {inEdit ? (
                                    <div>
                                      <TextField
                                        name='Title'
                                        id='standard-basic'
                                        value={x.Title.toUpperCase()}
                                        onChange={(ev) =>
                                          this.handleChange(ev, x.MenuID)
                                        }
                                      />
                                      <TextField
                                        name='Tooltip'
                                        id='standard-basic'
                                        value={x.Tooltip}
                                        onChange={(ev) =>
                                          this.handleChange(ev, x.MenuID)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <ListItemText
                                      disableTypography={true}
                                      primary={
                                        <React.Fragment>
                                          <Typography
                                            className={classNames(
                                              classes.primaryItemText
                                            )}
                                          >
                                            {x.Title.toUpperCase()}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      secondary={
                                        <React.Fragment>
                                          <Typography
                                            className={classNames(
                                              classes.secondaryItemText
                                            )}
                                          >
                                            {x.Tooltip}
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    />
                                  )}

                                  {inEdit && (
                                    <ListItemSecondaryAction
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Switch
                                        onChange={() =>
                                          this.toogleVisibility(x.MenuID)
                                        }
                                        checked={x.Visible === 1}
                                      />
                                    </ListItemSecondaryAction>
                                  )}
                                </ListItem>
                              </Paper>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  } else {
                    return null;
                  }
                })}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

ListItemMenuComponent.propTypes = {
  menu: PropTypes.array.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  iconStyle: PropTypes.object,
  paperClasses: PropTypes.string,
  paperElevation: PropTypes.number,
  inEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  setOpen: PropTypes.func,
};
export default withStyles(styles)(ListItemMenuComponent);
