import { showRequestFailedDialog } from '../actions/errorActions';
import * as storage from '../coraWebMComponents/utils/storage';

const signOut = () => ({
  type: "LOGOUT_SUCCESS"
});

const checkRequestFailtures = (store, action) => {
  if (action.type.indexOf('FAILURE') > -1 && action.type.indexOf('FAILURE_DIALOG') === -1) {
    if (action.error.code === '401.000' && action.type !== 'SIGN_IN_FAILURE') {
      storage.removeUser();
      store.dispatch(signOut());
    }
    else {
      store.dispatch(showRequestFailedDialog(action.error.message))
    }
  }
};

const errorHandler = store => next => action => {
  checkRequestFailtures(store, action);
  let result = next(action);
  return result;
}

export default errorHandler;