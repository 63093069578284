import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import saveAs from 'file-saver';
import _ from "lodash";
import { withStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import DescriptionIcon from '../../coraWebMComponents/dataDisplay/icons/DescriptionIcon';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import * as requests from '../../utils/publicZone/requestsNoAuth';

import { getGridVisibleColumns, buildValue, getDetailTitle } from './support';
import DetailDialog from './DetailDialog';

const styles = theme => ({
  ...getSharedStyles(theme),
  titleText: {
    textAlign: 'left',
    paddingRigth: theme.spacing.unit,
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    minWidth: '200px',
  },
  valueText: {
    textAlign: 'left',
    paddingLeft: theme.spacing.unit,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  riBtn: {
    padding: '0px',
    marginTop: '3px',
    backgroundColor: theme.palette.primary.light,
    // backgroundColor: alpha(theme.palette.common.black, 0.25),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    borderRadius: theme.shape.borderRadius,
  },
  riIcon: {
  },
  fld: {
    margin: '8px 0',
  },
  lbl: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightBold,
  },
  val: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    minHeight: '1em',
    wordBreak: 'break-all'
  },
  pdfBtn: {
    border: '2px solid #ebebeb',
    display: 'flex',
    textTransform: 'none',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
});

class PodFrmComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailOpen: false,
      selectedId: -1,
      isLoading: false
    };
  }

  /** Udalosť Otvorenie detailu */
  handleDetailDialogOpen = (selectedId) => {
    this.setState({ detailOpen: true, selectedId });
  };

  /** Udalosť Zatvorenie detailu */
  handleDetailClose = () => {
    this.setState({ detailOpen: false, selectedId: -1 });
  };

  handleDownloadFile = async (e, iBinFile = 0) => {
    e.stopPropagation();
    this.setState({ isLoading: true });

    let response = await requests.getBlobWithFileName(`/api/pz/download/${iBinFile}`);
    saveAs(response.blob, response.fileName);

    this.setState({ isLoading: false });
  };

  render() {
    const { classes, selectedFrm, recordTitle, rowData } = this.props;

    const primaryField = selectedFrm.Identif;
    const pkFieldName = primaryField.split('-').pop();
    const { schema } = selectedFrm;
    const gridColumns = getGridVisibleColumns(schema.Grid);
    const data = rowData[`FrmPod${selectedFrm.Id}`]?.items;

    let customUI;
    try {
      customUI = schema.Args;
    } catch (error) {
      customUI = { detail: { disabled: true } };
    }

    const formatMask = customUI && customUI.detail && customUI.detail.label ? customUI.detail.label : schema.NFrm;

    const isAttach = primaryField === 'IBinAttach';

    const hasDetail = !isAttach && !customUI.detail.disabled;

    const dataDetail = { isOpen: false, columns: [], rowData: null, rowTitle: schema.NFrm };
    if (hasDetail) {

      //metadata pre zobrazenie detailu
      if (this.state.detailOpen) {
        if (data.length > 0 && this.state.selectedId > 0) {
          const rowData = data.find(x => x[pkFieldName] === this.state.selectedId);
          if (null != rowData) {
            dataDetail.isOpen = true;
            dataDetail.columns = schema.Detail;
            dataDetail.rowData = rowData;
            dataDetail.rowTitle = formatMask;
          }
        }
      }
    }

    return (
      <div className='container w-padding' style={{ marginTop: '70px' }}>
        <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.mt3)} style={{ justifyContent: 'inherit' }}>
          <IconButton
            className={classNames(classes.p0)}
            color="inherit"
            onClick={this.props.onBackClick}
            toolTip={`Späť`}
            toolTipPlace="right"
            disableHover
            disableRipple
          >
            <ArrowBack
              color='secondary'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <div>
            <Typography variant="h6" className={classNames(classes.mt3, classes.mb3, classes.ml3)}>
              {`${recordTitle} / ${selectedFrm.Name}`}
            </Typography>
          </div>
        </div>
        {data.map(row => {
          const pkValue = row[pkFieldName];
          const iBinFile = row['IBinFile'];
          const itemTitle = isAttach ? (row.hasOwnProperty('NVec') && !_.isNil(row['NVec']) ? row['NVec'] : 'Dokument') : getDetailTitle(formatMask, row);
          return (
            <div className={classNames()} key={`row_${pkValue}`}>
              <Paper elevation={2} className={classNames(classes.cardRoot, classes.mb3)}>
                <div className={this.props.classes.cardContent}>
                  <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb2)} style={{ justifyContent: `${hasDetail ? 'space-between' : 'inherit'}` }}>
                    <div className={classNames(classes.title)}>
                      <DescriptionIcon style={{ width: "30", height: "30" }}></DescriptionIcon>
                      <Typography variant="body1" className={classNames(classes.ml2)} style={{ fontWeight: 'bold' }}>{itemTitle}</Typography>
                    </div>
                    {hasDetail &&
                      <IconButton
                        className={classNames(classes.riBtn)}
                        onClick={() => this.handleDetailDialogOpen(pkValue)}
                        color="inherit"
                        toolTip="Detail"
                        toolTipPlace="left"
                      >
                        <InfoIcon
                          className={classNames(classes.riIcon)}
                        />
                      </IconButton>
                    }
                  </div>
                  {!isAttach && gridColumns.map(col => (
                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} key={`col_${col.field}`}>
                      <Typography variant="body2" className={classNames(classes.titleText)}>{col.title}</Typography>
                      <Typography variant="body1" className={classNames(classes.valueText)}>{buildValue(row, col)}</Typography>
                    </div>
                  ))}
                  {isAttach && gridColumns.filter(col => col.field !== 'NVec').map(col => (
                    <div key={`col_${col.field}`} className={classNames(classes.alignItemsCenter, classes.fld)}>
                      <Typography variant="body2" className={classNames(classes.row, classes.lbl)}>{col.title}</Typography>
                      <Typography variant="body1" className={classNames(classes.row, classes.val)}>{buildValue(row, col)}</Typography>
                    </div>
                  ))}
                  {isAttach &&
                    <div className={classes.row} >
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="large"
                        type="button"
                        className={classNames(classes.w100, classes.mt2, classes.pdfBtn)}
                        onClick={(e) => this.handleDownloadFile(e, iBinFile)}
                      >
                        <FileDownloadIcon />
                        Stiahnúť
                      </Button>
                    </div>
                  }
                </div>
              </Paper>
            </div>
          );
        })}
        {dataDetail.isOpen &&
          <DetailDialog
            closeClick={this.handleDetailClose}
            detailProps={dataDetail}
            pkValue={this.state.selectedId}
          />
        }
      </div>
    );
  }
}

PodFrmComponent.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  selectedFrm: PropTypes.object.isRequired,
  rowData: PropTypes.object.isRequired,
  recordTitle: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PodFrmComponent);
