import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class MapIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <g>
            <path fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="
			M3,4.8C3,2.5,4.6,1,6.5,1C8.4,1,10,2.5,10,4.8C10,6.6,6.5,13,6.5,13S3,6.6,3,4.8z"/>
          </g>

          <polyline fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		2,10 1,18 18,18 16,6 13,6 	"/>

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="8.8" x2="9" y2="18" />

          <line fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="14" y1="15" x2="11" y2="14" />
          <circle fill="none" strokeMiterlimit="10" cx="6.5" cy="4.5" r="1" />
        </g>
      </SvgIcon>
    );
  }
}

MapIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default MapIconComponent;
