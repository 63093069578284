import React from "react";
import { merge } from "lodash";
// import classNames from 'classnames';

// import { makeStyles } from '@material-ui/core/styles';

// import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import WarningNoData from "../feedBack/WarningNoData";
import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import PieChart from "../charts/PieChart";
import * as chartConfig from "../charts/config";
import { splitText } from "../charts/helper";

// const useStyles = makeStyles((theme) => ({
//   ...getSharedStyles(theme),
// }));

const ChartDetail = ({ data, chartParams, isMobile }) => {
  // const classes = useStyles();
  const chartProps = chartParams.args;

  // podla typu grafu vrat default classi 
  const getDefaultChartClassName = () => {
    switch (chartParams.grafTyp?.skrGrafTyp) {
      case "bar":
        return chartConfig.barChartClassNameDefault;
      case "line":
        return chartConfig.lineChartClassNameDefault;
      case "pie":
        return chartConfig.pieChartClassNameDefault;
      default:
        return {};
    }
  };

  // Spoj defaultne configuracne parametre z configu s parametramy z DB
  const chartContainerStyle =
    merge(chartConfig.chartContainerStyleDefault, chartProps.chartContainerStyle);

  // Spoj defaultne configuracne classi z configu s classami z DB
  const chartClassName = merge(
    getDefaultChartClassName(), chartProps.chartClassName
  );

  // Rekurzivne prejdi cely JSON, formatovacie retazce z 'valueFormatter' dosad do template, 
  // sprav z toho funkciu a uloz ju spat do 'valueFormatter'
  function convertValueFormatterToFunction(obj, helperFunctions) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'valueFormatter' && typeof obj[key] === 'object') {
          const { tick, defaultValue } = obj[key];
          const safeDefaultVal = defaultValue || '\`${value}\`'; //ak 'default' nie je definovany, nastav nejaky 
          const valueFormatterString = `(value, context) => context.location === 'tick' ? ${tick || safeDefaultVal} : ${safeDefaultVal}`;
          try {
            obj[key] = new Function('splitText', `return ${valueFormatterString}`)(helperFunctions.splitText);
          } catch (e) {
            console.error(`Failed to convert valueFormatter for key ${key}:`, e);
          }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          convertValueFormatterToFunction(obj[key], helperFunctions);
        }
      }
    }
    return obj;
  }

  // transformacia formatovacich retazcov na formatovacie funkcie
  const helperFunctions = { splitText };
  let chartOtherProps = convertValueFormatterToFunction(chartProps.otherProps, helperFunctions);
  let seriesDefinition = convertValueFormatterToFunction(chartProps.seriesDefinition, helperFunctions);

  // premapovanie dat z API do dataSet BAR
  const getDataSetBarChart = () => {
    // Format:
    // [{
    //   id: 1,
    //   prijmy: 400000,
    //   vydavky: 550000,
    //   rok: 2021,
    // }, {...}];
    const dataSetDefinition = chartProps.dataSetDefinition;
    let transformedData = [];
    if (data.Items?.length > 0) {
      transformedData = data.Items.map(item => {
        const transformedItem = {};
        dataSetDefinition.forEach(field => {
          transformedItem[field] = item[field];
        });
        return transformedItem;
      });
    }
    return transformedData;
  };

  // premapovanie dat z API do dataSet LINE
  const getDataSetLineChart = () => {
    // Format:
    // [{
    //   id: 1,
    //   prijmy: 400000,
    //   vydavky: 550000,
    //   spolu: 950000,
    //   rok: 2021,
    // }, {...}];
    const dataSetDefinition = chartProps.dataSetDefinition;
    let transformedData = [];
    if (data.Items?.length > 0) {
      transformedData = data.Items.map(item => {
        const transformedItem = {};
        dataSetDefinition.forEach(field => {
          transformedItem[field] = item[field];
        });
        return transformedItem;
      });
    }
    return transformedData;
  };

  // premapovanie dat z API do dataSet PIE
  const getDataSetPieChart = () => {
    // Format:
    // [
    //   { id: 0, value: 30, label: '2024' },
    //   ...
    // ];
    const dataSetDefinition = chartProps.dataSetDefinition;
    let transformedData = [];
    if (data.Items?.length > 0) {
      transformedData = data.Items.map(item => {
        return {
          id: item[dataSetDefinition.id],
          value: item[dataSetDefinition.value],
          label: splitText(`${item[dataSetDefinition.label?.labelValue]}`, dataSetDefinition.label?.wordsInLine ?? 0)
        };
      });
    }
    return transformedData;
  };

  const BarChartX = ({ layout = "vertical" }) =>
    <BarChart
      isMobile={isMobile}
      style={chartContainerStyle} // CSS styl obalujuceho kontainera
      className={chartClassName} // MUI styl grafu
      dataSet={getDataSetBarChart()} // data pre serie
      series={seriesDefinition} // definicie serii
      layout={layout} // orientacia grafu; DEFAUT: "vertical", allowed: "horizontal"
      // width={800} // sirka celeho objektu grafu aj s popiskami; DEFAUT: 500px
      // height={600} // vyska celeho objektu grafu aj s popiskami; DEFAUT: 400px
      // colors={["darkgreen", "darkred"]} //farby serii; DEFAUT: MUI colors
      // marginProps={{ top: 60, right: 60, bottom: 70, left: 100 }} // okraje okolo samotneho grafu (prestor pre nazvy osi, popisky); DEFAUT: 50,50,50,50
      // labelAxisProps={[{ // os s popiskami
      //   id: "bottomAxisId", // REQUIRED ak sa inde odkazujem na tuto os, napr. v propse "rightAxis"
      //   label: "Rok", // nazov pre os s popiskami; DEFAUT: "X"
      //   labelFontSize: "1.2rem",  // velkost nazvu pre os s popiskami; DEFAUT: "1rem"
      //   scaleType: "band", // typ zobrazovania hodnot; DEFAUT: "band"
      //   dataKey: "Rok", // nazov datoveho klucla z dataSet
      //   labelStyle: { // stylovanie popisku osi
      //     transform: "translate(0px, 16px)", // posun popisku, ak sa prekryva s hodnotami na osi
      //     fontVariantCaps: "all-small-caps",
      //   },
      //   hideTooltip: false, // skrytie titulku v plavajucej bubline; DEFAULT: false
      //   valueFormatter: {
      //     tick: 'splitText(\`Rok ${value}\`,1)', // format: "`<string> ${value}`" alebo "splitText(`<string> ${value}`,1)"; DEFAULT: `${value}`
      //     defaultValue: '\`Rok: ${value}\`' // DEFAULT: `${value}`
      //   }      
      // }]}
      // valueAxisProps={[{ // os s hodnotami
      //   id: "leftAxisId", // REQUIRED ak sa inde odkazujem na tuto os, napr. v propse "rightAxis"
      //   label: "Počet",  // nazov pre os s hodnotami; DEFAUT: "Y"
      //   labelFontSize: "1.5rem", // velkost nazvu pre os s hodnotami; DEFAUT: "1rem" (BUG - nefunguje, potrebne nastavit scale() v labelStyle/transform)
      //   labelStyle: { // stylovanie popisku osi
      //     transform: "scale(1.3) translate(-300px, 150px) rotate(-90deg)",  // zvacsenie popisku a posun, ak sa prekryva s hodnotami na osi
      //     fontVariantCaps: "all-small-caps",
      //   },
      //   valueFormatter: {
      //     defaultValue: '\`${value}\`' // DEFAULT: `${value}`
      //   },
      // }]}
      // legendProps={{ // parametre legiend
      //   direction: "row", // orientacia legiend; DEFAULT: "row", allowed: "column"
      //   position: { // poloha legiend voci grafu
      //     vertical: "top", // zorovnanie legiend po vertikalnej osi; DEFAULT: "top", allowed: "middle", "bottom"
      //     horizontal: "middle", // zorovnanie legiend po horizontalnej osi; DEFAULT: "middle", allowed: "left", "right"
      //   }, //ak position/horizontal je "right", potom aj padding sa nastavuje cez right (padding left nereaguje)   
      //   padding: {
      //     //ak position/vertical je "top", potom aj padding sa nastavuje cez top (padding/bottom nereaguje), podobne pre "bottom"
      //     //ak position/horizontal je "right", potom aj padding sa nastavuje cez right (padding/left nereaguje), podobne pre "left"
      //     top: 0, // medzera nad popiskami ; DEFAULT: "0"; nema zmysel pri vertical: "middle"
      //     bottom: 0, // medzera pod popiskami ; DEFAULT: "0"; nema zmysel pri vertical: "middle"
      //     left: 0,  // medzera nalavo od popiskov ; DEFAULT: "0"; nema zmysel pri horizontal: "middle" 
      //     right: 0,  // medzera napravo od popiskov ; DEFAULT: "0"; nema zmysel pri horizontal: "middle"
      //   },
      //   itemMarkWidth: 16, // sirka farebneho symbolu legendy; DEFAULT: 16
      //   itemMarkHeight: 24, // vyska farebneho symbolu legendy; DEFAULT:  24
      //   itemGap: 20, // medzera medzi polozkami legiend; DEFAULT: 20
      //   markGap: 5, // medzera medzi farebnym symbolom legendy a textom; DEFAULT: 5
      //   labelStyle: {
      //     fontSize: "1rem", // velkost pisma pre legendu; DEFAULT: "1rem"
      //     fill: "black", // farba pisma pre legendu; DEFAULT: "black"
      //   },
      // }}
      // gridProps={{ // paramerte mriezky v grafe
      //   horizontal: true, // zobrazenie vodorovnej mriezky; DEFAULT: true
      //   vertical: true, // zobrazenie zvyslej mriezky; DEFAULT: true
      // }}
      // axisHighlightProps={{ // zobrazenie centrovacieho kriza pod kurzorom mysi
      //   x: "line", // zobrazenie x-ovej casti centrovacieho kriza(line)/pasu(band) pod kurzorom mysi; DEFAULT: "band", allowed: "none", "band". Band ide len pri 
      //   y: "line", // zobrazenie y-novej casti centrovacieho kriza(line)/pasu(band) pod kurzorom mysi; DEFAULT: "line", allowed: "none", "band"
      // }}
      // leftAxis={null} // definicia lavej Y-osi; DEFAULT: os zapnuta, ALLOWED: "<id-osi>", null
      // rightAxis={"leftAxisId"} // definicia pravej Y-osi; DEFAULT: os vypnuta, ALLOWED: "<id-osi>", null
      // bottomAxis={null} // definicia dolnej X-osi; DEFAULT: os zapnuta, ALLOWED: "<id-osi>", null
      // topAxis={"bottomAxisId"} // definicia hornej X-osi; DEFAULT: os vypnuta, ALLOWED: "<id-osi>", null
      {...chartOtherProps}
    />;

  const LineChartX = () =>
    <LineChart
      isMobile={isMobile}
      style={chartContainerStyle} // CSS styl obalujuceho kontainera
      className={chartClassName} // MUI styl grafu
      dataSet={getDataSetLineChart()} // data pre serie
      series={seriesDefinition} // definicie serii
      // width={600} // sirka celeho objektu grafu aj s popiskami; DEFAULT: 500px
      // height={400} // vyska celeho objektu grafu aj s popiskami; DEFAULT: 400px
      // colors={["orange", "red", "blue",]} //farby serii; DEFAULT: MUI colors 'mangoFusionPaletteDark'
      // marginProps={{ top: 60, right: 60, bottom: 70, left: 100 }} // okraje okolo samotneho grafu (prestor pre nazvy osi, popisky); DEFAULT: 50,50,50,50
      // labelAxisProps={[ // pole osi s popiskami
      //   {
      //     id: 'bottomAxisId', // REQUIRED ak sa inde odkazujem na tuto os, napr. v propse 'topAxis'
      //     label: 'Rok', // nazov pre os s popiskami; DEFAULT: ''
      //     labelFontSize: "1.2rem",  // velkost nazvu pre os s popiskami; DEFAULT: '1rem'
      //     scaleType: 'band', // typ zobrazovania hodnot; DEFAULT: 'point'; band=label je vykresleny v strede stlpca; point=label je vykresleny na okraji stlpca
      //     dataKey: 'year', // nazov datoveho klucla z dataSet
      //     labelStyle: { // stylovanie popisku osi
      //       transform: 'translate(0px, 16px)', // posun popisku, ak sa prekryva s hodnotami na osi
      //       fontVariantCaps: 'all-small-caps',
      //     },
      //     hideTooltip: false, // skrytie titulku v plavajucej bubline; DEFAULT: false
      //   valueFormatter: { // format hodnot pre: os s labelmi/nadpis v bulbline
      //     tick: 'splitText(\`Rok ${value}\`,1)', // format hodnoty na osi s labelmi (tick), mimo osi (default); DEFAULT: `${value}`
      //     defaultValue: '\`Rok: ${value}\`' // DEFAULT: `${value}`
      //   }      
      //   }
      // ]}
      // valueAxisProps={[
      //   { // os s hodnotami
      //     id: "leftAxisId", // REQUIRED ak sa inde odkazujem na tuto os, napr. v propse "rightAxis"
      //     label: "Počet obyvateľov",  // nazov pre os s hodnotami; DEFAULT: "Y"
      //     labelFontSize: "1.5rem", // velkost nazvu pre os s hodnotami; DEFAULT: "1rem" (BUG - nefunguje, potrebne nastavit scale() v labelStyle/transform)
      //     labelStyle: { // stylovanie popisku osi
      //       transform: "scale(1.3) translate(-300px, 150px) rotate(-90deg)",  // zvacsenie popisku a posun, ak sa prekryva s hodnotami na osi
      //       fontVariantCaps: "all-small-caps",
      //     },
      //     valueFormatter: { // format hodnot na osi s hodnotami
      //       tick: 'splitText(\`Rok ${value}\`,1)', // format hodnot na osi s hodnotami, format: "`<string> ${value}`" alebo "splitText(`<string> ${value}`,1)" // DEFAULT: `${value}`
      //       defaultValue: '\`Rok: ${value}\`' // DEFAULT: `${value}`
      //     }
      //   }
      // ]}
      // legendProps={{ // parametre legiend
      //   direction: 'row', // orientacia legiend; DEFAULT: 'row', allowed: 'column'
      //   position: { // poloha legiend voci grafu
      //     vertical: 'top', // zorovnanie legiend po vertikalnej osi; DEFAULT: 'top', allowed: 'middle', 'bottom'
      //     horizontal: 'middle', // zorovnanie legiend po horizontalnej osi; DEFAULT: 'middle', allowed: 'left', 'right'
      //   }, //ak position/horizontal je 'right', potom aj padding sa nastavuje cez right (padding left nereaguje)   
      //   padding: {
      //     //ak position/vertical je 'top', potom aj padding sa nastavuje cez top (padding/bottom nereaguje), podobne pre 'bottom'
      //     //ak position/horizontal je 'right', potom aj padding sa nastavuje cez right (padding/left nereaguje), podobne pre 'left'
      //     top: 0, // medzera nad popiskami ; DEFAULT: '0'; nema zmysel pri vertical: 'middle'
      //     bottom: 0, // medzera pod popiskami ; DEFAULT: '0'; nema zmysel pri vertical: 'middle'
      //     left: 0,  // medzera nalavo od popiskov ; DEFAULT: '0'; nema zmysel pri horizontal: 'middle' 
      //     right: 0,  // medzera napravo od popiskov ; DEFAULT: '0'; nema zmysel pri horizontal: 'middle'
      //   },
      //   itemMarkWidth: 6, // sirka farebneho symbolu legendy; DEFAULT: 16
      //   itemMarkHeight: 24, // vyska farebneho symbolu legendy; DEFAULT:  24
      //   itemGap: 20, // medzera medzi polozkami legiend; DEFAULT: 20
      //   markGap: 5, // medzera medzi farebnym symbolom legendy a textom; DEFAULT: 5
      //   labelStyle: {
      //     fontSize: "1rem", // velkost pisma pre legendu; DEFAULT: '1rem'
      //     fill: "black", // farba pisma pre legendu; DEFAULT: 'black'
      //   },
      // }}
      // gridProps={{ // paramerte mriezky v grafe
      //   horizontal: true, // zobrazenie vodorovnej mriezky; DEFAULT: true
      //   vertical: true, // zobrazenie zvyslej mriezky; DEFAULT: true
      // }}
      // axisHighlightProps={{ // zobrazenie centrovacieho kriza pod kurzorom mysi
      //   x: "band", // zobrazenie x-ovej casti centrovacieho kriza(line)/pasu(band) pod kurzorom mysi; DEFAULT: "band", allowed: 'none', 'line'.
      //   y: "line", // zobrazenie y-novej casti centrovacieho kriza(line)/pasu(band) pod kurzorom mysi; DEFAULT: "line", allowed: 'none'
      // }}
      // leftAxis={null} // definicia lavej Y-osi; DEFAULT: os zapnuta, ALLOWED: '<id-osi>', null
      // rightAxis={'leftAxisId'} // definicia pravej Y-osi; DEFAULT: os vypnuta, ALLOWED: '<id-osi>', null
      // bottomAxis={null} // definicia dolnej X-osi; DEFAULT: os zapnuta, ALLOWED: '<id-osi>', null
      // topAxis={'bottomAxisId'} // definicia hornej X-osi; DEFAULT: os vypnuta, ALLOWED: '<id-osi>', null
      {...chartOtherProps}
    />;

  const PieChartX = () =>
    <PieChart
      isMobile={isMobile}
      style={chartContainerStyle} // CSS styl obalujuceho kontainera
      className={chartClassName} // MUI styl grafu
      dataSet={getDataSetPieChart()} // data pre serie
      series={seriesDefinition} // definicie serii
      // width={700} // sirka celeho objektu grafu aj s popiskami; DEFAUT: 100% rodica
      // height={400} // vyska celeho objektu grafu aj s popiskami; DEFAUT: 400px
      // colors={cheerfulFiestaPalette} //farby serii; DEFAUT: MUI color palette 'mangoFusionPalette'
      // marginProps={{ top: 30, right: 300, bottom: 30, left: 30 }} // okraje okolo samotneho grafu; DEFAUT: 20,20,20,20
      // legendProps={{ // parametre legiend
      //   direction: 'column', // orientacia legiend; DEFAULT: 'column', allowed: 'row'
      //   position: { // poloha legiend voci grafu
      //     vertical: 'middle', // zorovnanie legiend po vertikalnej osi; DEFAULT: 'middle', allowed: 'middle', 'bottom'
      //     horizontal: 'right', // zorovnanie legiend po horizontalnej osi; DEFAULT: 'right', allowed: 'left', 'middle'
      //   }, //ak position/horizontal je 'right', potom aj padding sa nastavuje cez right (padding left nereaguje)   
      //   padding: {
      //     //ak position/vertical je 'top', potom aj padding sa nastavuje cez top (padding/bottom nereaguje), podobne pre 'bottom'
      //     //ak position/horizontal je 'right', potom aj padding sa nastavuje cez right (padding/left nereaguje), podobne pre 'left'
      //     top: 0, // medzera nad popiskami ; DEFAULT: '0'; nema zmysel pri vertical: 'middle'
      //     bottom: 0, // medzera pod popiskami ; DEFAULT: '0'; nema zmysel pri vertical: 'middle'
      //     left: 0,  // medzera nalavo od popiskov ; DEFAULT: '0'; nema zmysel pri horizontal: 'middle' 
      //     right: 0,  // medzera napravo od popiskov ; DEFAULT: '0'; nema zmysel pri horizontal: 'middle'
      //   },
      //   itemMarkWidth: 36, // sirka farebneho symbolu legendy; DEFAULT: 36
      //   itemMarkHeight: 24, // vyska farebneho symbolu legendy; DEFAULT:  20
      //   itemGap: 20, // medzera medzi polozkami legiend; DEFAULT: 8
      //   markGap: 10, // medzera medzi farebnym symbolom legendy a textom; DEFAULT: 10
      //   labelStyle: {
      //     fontSize: "1rem", // velkost pisma pre legendu; DEFAULT: '1rem'
      //     fill: "black", // farba pisma pre legendu; DEFAULT: 'black'
      //   },
      // }}
      {...chartOtherProps}
    />;

  switch (chartParams.grafTyp?.skrGrafTyp) {
    case 'barVertical':
      return < BarChartX layout="vertical" />;
    case 'barHorizontal':
      return < BarChartX layout="horizontal" />;
    case 'line':
      return < LineChartX />;
    case 'pie':
      return < PieChartX />;
    default:
      return <WarningNoData infoText="Požadovaný typ grafu nie je k dispozícii." />;
  }
};

export default ChartDetail;