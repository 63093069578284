import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import Error from '../../coraWebMComponents/feedBack/Error';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import TextField from '../../coraWebMComponents/inputs/TextField';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Switch from '../../coraWebMComponents/inputs/Switch';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentFile from '../../coraWebMComponents/inputs/AttachmentFile';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import FileInput from '../../coraWebMComponents/inputs/FileInput';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as parkingActions from '../../actions/parkingActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as validator from '../../coraWebMComponents/utils/validator';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import ImageModal from '../attachments/ImageModal';
import { downloadPDF } from '../../utils/parkingUtils';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;

let _sizeAttachs = 0;
const _initState = {
  I_ZIADOST: 0,
  I_ZIADOST_TYP: 3,
  I_ZIADOST_STAV: 1,
  I_O: null,
  I_O_TYP: 1,
  MENO: '',
  PF: '',
  D_NAR: '',
  ICO_RC: '',
  N_M: '',
  I_ADR: null,
  adresa: {
    I_ADR: 0,
    ADRESA: '',
    ZOZ_I_ZONA: ''
  },
  CB: '',
  L_VLASTNIK: true,
  L_DRZITEL: true,
  ECV: '',
  I_KARTA_TYP: null,
  I_KARTA: null,
  cardType: {
    I_KARTA_TYP: null,
    N_KARTA_TYP: ''
  },
  I_POHON: null,
  drive: {
    I_POHON: null,
    N_POHON: ''
  },
  I_KARTA_KAT: 1,
  L_ZTP: false,
  D_EVID: null,
  I_ZDROJ: 1,
  ATTACHS_TP: [],
  ATTACHS_FILE: [],
  TXT_ZMENA: '',
  errorText: '',
  errorTextEcv: '',
  errorTextDrive: '',
  errorTextZmena: '',
  errorTextAttach: '',
  errorCB: '',
  done: false,
  loading: true,
  L_REQUIRED_ADR: 0,//ak je viac ako nula, tak adresa je nepovinna
  doubleAllert: false
}

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {},
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

let _iZiadost, _iKarta;

class ParkingModify extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  };

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _iZiadost = params.get("iZadost");
    _iKarta = params.get("iKarta");
    await this.props.dispatch(parkingActions.getParkingCard(_iKarta));
    await this.props.dispatch(ciselnikyActions.getListDrive(''));
    await this.props.dispatch(parkingActions.getRequiredParkingAdr());

    this.setState({
      I_O: this.props.parkingCard.I_O,
      I_ZIADOST: this.props.parkingCard.I_ZIADOST,
      I_KARTA_KAT: this.props.parkingCard.I_KARTA_KAT,
      I_KARTA: this.props.parkingCard.I_KARTA,
      L_REQUIRED_ADR: parseInt(this.props.requiredParkingAdr.HODN),
      loading: false
    });
  }

  handleCloseDoubleAllert = () => {
    this.setState({ doubleAllert: false });
  }

  handleChange = (name, value) => {
    switch (name) {
      case 'ECV':
        value = value.toUpperCase();
        value = value.trim()
        this.setState({ errorTextEcv: value ? validator.isValidEcv(value) : '' });
        break;
      case 'I_POHON':
        this.setState({ I_POHON: value.I_POHON });
        break;
      case 'TXT_ZMENA':
        this.setState({ errorTextZmena: value ? (value.length > 3997 ? 'Maximálny počet znakov 4000!' : '') : 'Dôvod zmeny údajov je povinný údaj!' });
        break;
      case 'CB':
        this.setState({ errorCB: value.length > 5 ? 'Maximálny počet znakov 5!' : "" });
        break;
      case 'adresa':
        if (value)
          this.setState({ I_ADR: value.I_ADR, adresa: value });
        else
          this.setState({ I_ADR: null, adresa: { I_ADR: null, ADRESA: '', ZOZ_I_ZONA: '' } });

        break;
      default:
    }
    if (name === 'CB' && value.length > 5) {
      this.setState({ errorCB: 'Maximálny počet znakov 5!' });
      return;
    }
    else if (name === 'CB' && value.length <= 5) {
      this.setState({ errorCB: '' });
    }
    this.setState({ [name]: value });
  };

  handleSearchAdresa = (searchText) => {
    if (this.state.I_KARTA_KAT !== 6) {
      this.props.dispatch(ciselnikyActions.getListAddressByZoneList(searchText, this.props.parkingCard.ZOZ_I_ZONA));
    } else {
      this.props.dispatch(ciselnikyActions.getListAddress(searchText));
    }
  };

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleAdd = async () => {
    const Ecv = this.state.ECV;
    const Ipohon = this.state.I_POHON;
    const I_adr = this.state.I_ADR;
    const AttachsTp = this.state.ATTACHS_TP;
    const AttachsFile = this.state.ATTACHS_FILE;
    const TxtZmena = this.state.TXT_ZMENA;
    const CB = this.state.CB;


    let errorTxt = '';
    if (this.state.I_KARTA_KAT === 4 || this.state.I_KARTA_KAT === 5) {
      if (I_adr === null)
        errorTxt = 'Zadajte novú adresu karty!';

    } else
      errorTxt = (this.state.I_KARTA_KAT === 2 && this.state.L_REQUIRED_ADR > 0) ? (Ecv ? '' : 'Zadajte EČV !') : ((Ecv || I_adr) ? '' : 'Zadajte EČV alebo adresu karty!');

    const errorText = errorTxt;
    const errorTextEcv = Ecv && this.state.I_KARTA_KAT !== 4 && this.state.I_KARTA_KAT !== 5 ? validator.isValidEcv(Ecv) : '';
    const errorTextPohon = Ecv && this.state.I_KARTA_KAT !== 4 && this.state.I_KARTA_KAT !== 5 ? (Ipohon ? '' : 'Typ pohonu motorového vozidla je povinný údaj!') : '';
    const errorTextAttach = Ecv && _sizeAttachs === 0 && (this.state.I_KARTA_KAT !== 4 || this.state.I_KARTA_KAT !== 5) ? 'Prílohy sú povinné!' : this.state.errorTextAttach;
    const errorTextZmena = TxtZmena ? (TxtZmena.length > 3997 ? 'Maximálny počet znakov 4000!' : '') : 'Dôvod zmeny údajov je povinný údaj!';
    const errorCB = CB.length > 5 ? 'Maximálny počet znakov 10!' : '';
    const doubleAllert = await this.props.dispatch(parkingActions.unikParkingModify(this.state.I_KARTA));

    this.setState({ errorText, errorTextEcv, errorTextPohon, errorTextZmena, errorCB, errorTextAttach, doubleAllert });

    if (!errorText && !errorTextEcv && !errorTextPohon && !errorTextAttach && !errorTextZmena && !errorCB && !doubleAllert) {

      let AttachsTpNew = AttachsTp;
      for (let i = 0; i < AttachsTp.length; i++) {
        AttachsTpNew[i].data = AttachsTp[i].data.replace(/^.+,/, '');
      }

      let AttachsFileNew = AttachsFile;
      for (let i = 0; i < AttachsFile.length; i++) {
        AttachsFileNew[i].data = AttachsFile[i].data.replace(/^.+,/, '');
      }

      const data = { ...this.state, ATTACHS_TP: AttachsTpNew, ATTACHS_FILE: AttachsFileNew, D_EVID: convert.addOffset(new Date()) };

      this.setState({ loading: true });
      const res = await this.props.dispatch(parkingActions.AddParkingModify(data));
      this.setState({ done: res, loading: false });

    }
  }

  handleChangeImageTp = (attach) => {
    this.setState(prevState => ({ ATTACHS_TP: [...prevState.ATTACHS_TP, attach] }));
    this.validAttachs(true, attach.size);
  };

  handleRemoveImageTp = (index) => {
    this.setState(prevState => ({
      ATTACHS_TP: [
        ...prevState.ATTACHS_TP.slice(0, index),
        ...prevState.ATTACHS_TP.slice(index + 1)
      ]
    }));
    const item = this.state.ATTACHS_TP[index];
    this.validAttachs(false, item.size);
  };

  handleChangeFile = (attach) => {
    const ATTACHS_FILE = this.state.ATTACHS_FILE;
    for (let i = 0; i < ATTACHS_FILE.length; i++) {
      if (ATTACHS_FILE[i].name === attach.name) {
        return;
      }
    }
    this.setState(prevState => ({ ATTACHS_FILE: [...prevState.ATTACHS_FILE, attach] }));
    this.validAttachs(true, attach.size);
  };


  handleRemoveFile = (index) => {
    this.setState(prevState => ({
      ATTACHS_FILE: [
        ...prevState.ATTACHS_FILE.slice(0, index),
        ...prevState.ATTACHS_FILE.slice(index + 1)
      ]
    }));

    const item = this.state.ATTACHS_FILE[index];
    this.validAttachs(false, item.size);
  };

  validAttachs = (add, sizeItem) => {
    if (add)
      _sizeAttachs = _sizeAttachs + sizeItem;
    else
      _sizeAttachs = _sizeAttachs - sizeItem;

    let txtErr = '';
    if (_sizeAttachs <= 0)
      txtErr = 'Prílohy sú povinné!';

    if (_sizeAttachs > window.config.maxUploadSize) {
      let sizeInMB = (window.config.maxUploadSize / 1024) / 1024;
      txtErr = `Prekročený limit na prílohy! Max. ${sizeInMB}MB!`;
    }

    this.setState({ errorTextAttach: txtErr });
  }

  handleGoHome = () => {
    this.props.navigate(routes.PARKING);
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  openModal = (imgSrc) => {
    this.setState({ showModal: true, currentImage: imgSrc });
  };

  closeModal = () => {
    this.setState({ showModal: false, currentImage: '' });
  };

  handleFileClick = (file) => {
    if (file.type === 'application/pdf') {
      downloadPDF(file.data, file.name);
    } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {

      this.openModal(`${file.data}`);
    }
  };

  render() {
    const { classes, width } = this.props;
    const { showModal, currentImage } = this.state;
    return (
      <div className='container w-margin'>
        <ImageModal show={showModal} onClose={this.closeModal} image={currentImage} />
        <CircularProgress loading={this.state.loading} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
          <React.Fragment>
            {!this.state.done &&
              <div >
                <IconButton
                  className={classNames(classes.backButton, classes.mb3)}
                  color="inherit"
                  onClick={() => { this.props.navigate(-1) }}
                  toolTip="Späť"
                  toolTipPlace="right"
                >
                  <ArrowBack
                    color='secondary'
                    variant='fab'
                    style={{ fontSize: 30 }}
                  />
                </IconButton>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
                  <Typography variant="subtitle1">Žiadosť o zmenu údajov</Typography>
                </div>
                <Divider />
                {this.state.I_KARTA_KAT !== 4 && this.state.I_KARTA_KAT !== 5 &&
                  <div>
                    <div className={classes.row + ' ' + classes.alignItemsCenter}>
                      <Switch
                        color='secondary'
                        checked={this.state.L_DRZITEL}
                        onChange={this.handleChangeSwitch('L_DRZITEL')}
                        value='L_DRZITEL'
                        label={this.state.L_DRZITEL ? 'Som držiteľom vozidla' : 'Nie som držiteľom vozidla'}
                      />
                    </div>
                    <div >
                      <TextField
                        label="Nové EČV"
                        fullwidth={true}
                        value={this.state.ECV}
                        errorText={this.state.errorTextEcv}
                        error={this.state.errorTextEcv ? true : false}
                        onChange={this.handleChange}
                        name='ECV'
                      />
                    </div>
                    <div className={classes.mt0}>
                      <SelectField
                        errorText={this.state.errorTextPohon}
                        error={this.state.errorTextPohon ? true : false}
                        floatingLabelText="Pohon"
                        value={this.state.I_POHON}
                        onChange={this.handleChange}
                        fullwidth={true}
                        schema={{ label: 'N_POHON', value: 'I_POHON' }}
                        data={this.props.listDrive}
                        name='I_POHON'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                  </div>
                }
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mt3)}>
                  <AttachmentInput
                    disabled={this.props.disabled}
                    onChange={this.handleChangeImageTp}
                    iconType={1}
                  />
                  <Typography className={classes.ml1}>{this.state.I_KARTA_KAT !== 4 && this.state.I_KARTA_KAT !== 5 ? 'Foto veľký technický preukaz (obe strany)' : 'Foto prílohy'}</Typography>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_TP.map((image, index) =>
                    <Attachment
                      disabled={this.props.disabled}
                      key={index}
                      image={image}
                      onClick={() => { this.handleFileClick(image); }}
                      onRemove={() => this.handleRemoveImageTp(index)}
                    />
                  )}
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mt3)}>
                  <FileInput
                    disabled={this.props.disabled}
                    onChange={this.handleChangeFile}
                    mimetype={"application/pdf, image/jpeg, image/jpg"}
                    title={'súbory typu PDF, JPG, JPEG'}
                    iconType={2}
                  />
                  <Typography className={classes.ml1}>alebo prílohy (formát PDF)</Typography>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_FILE.map((file, index) =>
                    <AttachmentFile
                      disabled={this.props.disabled}
                      key={index}
                      image={file}
                      onClick={() => this.handleFileClick(file)}
                      onRemove={() => this.handleRemoveFile(index)}
                    />
                  )}
                </div>
                {this.state.errorTextAttach &&
                  <Typography style={{ color: 'red' }}> {this.state.errorTextAttach}</Typography>
                }

                {!(this.state.I_KARTA_KAT === 2 && this.state.L_REQUIRED_ADR > 0) &&
                  <div>
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                      Nová adresa karty
                    </Typography>
                    <div className={classes.row + ' ' + classes.alignItemsCenter} style={{ marginTop: '15px' }}>
                      <Switch
                        color='secondary'
                        checked={this.state.L_VLASTNIK}
                        onChange={this.handleChangeSwitch('L_VLASTNIK')}
                        value='L_VLASTNIK'
                        label={this.state.L_VLASTNIK ? 'Som majiteľ nehnuteľnosti' : 'Nie som majiteľ nehnuteľnosti'}
                      />
                    </div>
                    <div className={classes.mt0}>
                      <Autocomplete
                        data={this.props.listAdresa}
                        schema={{ label: 'ADRESA', value: 'I_ADR' }}
                        onChange={this.handleSearchAdresa}
                        onSelect={this.handleChange}
                        selectedItem={this.state.adresa}
                        label="Ulica (časť obce), súpisné/orientačné číslo"
                        name='adresa'
                        className={classNames(classes.mt2)}
                        countCharStart={_autocompleteCountCharStart}
                        timeDelay={_autocompleteTimeDelay}
                      />
                    </div>
                    <div className={classes.mt0}>
                      <TextField
                        label="Číslo bytu"
                        fullwidth={true}
                        value={this.state.CB}
                        onChange={this.handleChange}
                        name='CB'
                        di
                        errorText={this.state.errorCB}
                        error={this.state.errorCB ? true : false}
                        className={classNames(classes.mt2)}
                      />
                    </div>
                  </div>
                }
                <div >
                  <TextField
                    label="Dôvod zmeny"
                    fullwidth={true}
                    value={this.state.TXT_ZMENA}
                    errorText={this.state.errorTextZmena}
                    error={this.state.errorTextZmena ? true : false}
                    onChange={this.handleChange}
                    multiLine={true}
                    name='TXT_ZMENA'
                  />
                </div>
                <div className={classNames(classes.textCenter)}>
                  <Error text={this.state.errorText} />
                </div>
                <div className={classNames(classes.row, classes.justifyContentCenter, classes.mt4)}>
                  <RaisedButton
                    disabled={this.state.loading}
                    style={{ width: '80%', maxWidth: '350px', padding: '13px 30px' }}
                    onClick={this.handleAdd}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Odoslať
                      </Typography>
                      <ArrowForward
                        color='primary'
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>
            }
            {this.state.done &&
              <div>
                <div className={classNames(classes.mb4)}>
                  <Button
                    color="secondary"
                    aria-label="Späť"
                    onClick={this.handleGoHome}
                  >
                    <ArrowBack
                      color='secondary'
                      fontSize='default'
                      variant='fab'
                    />
                    <Typography variant="body2" className={classNames(classes.ml2)}>Späť na parkovacie karty</Typography>
                  </Button>
                </div>
                <div className='center'>
                  <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='ŽIADOSŤ ODOSLANÁ' />
                </div>
                <div className={classNames(classes.mt3, classes.textCenter)}>
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    VAŠA ŽIADOSŤ O <br />
                    ZMENU ÚDAJOV <br />
                    BOLA ÚSPEŠNE ODOSLANÁ
                  </Typography>
                </div>
                <div className={classNames(classes.mt4, classes.mb4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    POČKAJTE PROSÍM <br />
                    NA OVERENIE VAŠEJ ŽIADOSTI <br />
                    INFORMAČNÝM SYSTÉMOM MESTA.
                  </Typography>
                </div>
                <div className={classNames(classes.mt4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    STAV VAŠEJ ŽIADOSTI MÔŽETE <br />
                    SLEDOVAŤ NA ZÁLOŽKE <br />
                    "PARKOVACIE KARTY"
                  </Typography>
                </div>
              </div>
            }
          </React.Fragment>
        </Paper>
        <Dialog
          open={this.state.doubleAllert}
          onClose={this.handleCloseDoubleAllert}
          contentText={"Ku karte existuje zaevidovaná žiadosť o zmenu údajov!"}
          title={'Upozornenie'}
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseDoubleAllert,
              keyboardFocused: true
            }
          ]}
        />
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    person: state.person.data,
    parkingCard: state.parkingCard.data.Data,
    requiredParkingAdr: state.requiredParkingAdr.data.Data,
    listDrive: state.listDrive.data,
    listAdresa: state.listAdresa.data,
    adresa: state.adresa.data.Data,
  }
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withWidth()(ParkingModify))));