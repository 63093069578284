import actionTypes from './actionTypes';
import * as requiredPublicUtils from '../../utils/publicZone/requiredPublicUtils';

const getListOrderRequest = () => ({
  type: actionTypes.GET_LIST_ORDER_REQUEST
});

const getListOrderSuccess = (res) => ({
  type: actionTypes.GET_LIST_ORDER_SUCCESS,
  res
});

const getListOrderFailure = (error) => ({
  type: actionTypes.GET_LIST_ORDER_FAILURE,
  error
});

export const getListOrder = (filter, sort, page) => {
  return async (dispatch) => {
    try {
      dispatch(getListOrderRequest());
      let res = await requiredPublicUtils.getListOrder(filter, sort, page);
      dispatch(getListOrderSuccess(res));
    } catch (error) {
      dispatch(getListOrderFailure(error));
      console.error(error);
    }
  }
}

const getListRequestsGridRequest = () => ({
  type: actionTypes.GET_LIST_REQUEST_GRID_REQUEST
});

const getListRequestsGridSuccess = (res) => ({
  type: actionTypes.GET_LIST_REQUEST_GRID_SUCCESS,
  res
});

const getListRequestsGridFailure = (error) => ({
  type: actionTypes.GET_LIST_REQUEST_GRID_FAILURE,
  error
});

export const getListRequestGrid = (filter, sort, page) => {

  return async (dispatch) => {
    try {
      dispatch(getListRequestsGridRequest());
      let res = await requiredPublicUtils.getListRequestsGrid(filter, sort, page);
      dispatch(getListRequestsGridSuccess(res));
    } catch (error) {
      dispatch(getListRequestsGridFailure(error));
      console.error(error);
    }
  }
}

const getListContractRequest = () => ({
  type: actionTypes.GET_LIST_CONTRACT_REQUEST
});

const getListContractSuccess = (res) => ({
  type: actionTypes.GET_LIST_CONTRACT_SUCCESS,
  res
});

const getListContractFailure = (error) => ({
  type: actionTypes.GET_LIST_CONTRACT_FAILURE,
  error
});

export const getListContract = (filter, sort, page) => {
  return async (dispatch) => {
    try {
      dispatch(getListContractRequest());
      let res = await requiredPublicUtils.getListContract(filter, sort, page);
      dispatch(getListContractSuccess(res));
    } catch (error) {
      dispatch(getListContractFailure(error));
      console.error(error);
    }
  }
}

const getListContractBillRequest = () => ({
  type: actionTypes.GET_LIST_CONTRACT_BILL_REQUEST
});

const getListContractBillSuccess = (res) => ({
  type: actionTypes.GET_LIST_CONTRACT_BILL_SUCCESS,
  res
});

const getListContractBillFailure = (error) => ({
  type: actionTypes.GET_LIST_CONTRACT_BILL_FAILURE,
  error
});

export const getListContractBill = (filter, sort, page) => {
  return async (dispatch) => {
    try {
      dispatch(getListContractBillRequest());
      let res = await requiredPublicUtils.getListContractBill(filter, sort, page);
      dispatch(getListContractBillSuccess(res));
    } catch (error) {
      dispatch(getListContractBillFailure(error));
      console.error(error);
    }
  }
}

const getListPurchBillRequest = () => ({
  type: actionTypes.GET_LIST_PURCH_BILL_REQUEST
});

const getListPurchBillSuccess = (res) => ({
  type: actionTypes.GET_LIST_PURCH_BILL_SUCCESS,
  res
});

const getListPurchBillFailure = (error) => ({
  type: actionTypes.GET_LIST_PURCH_BILL_FAILURE,
  error
});

export const getListPurchBill = (filter, sort, page) => {
  return async (dispatch) => {
    try {
      dispatch(getListPurchBillRequest());
      let res = await requiredPublicUtils.getListPurchBill(filter, sort, page);
      dispatch(getListPurchBillSuccess(res));
    } catch (error) {
      dispatch(getListPurchBillFailure(error));
      console.error(error);
    }
  }
}


const getListOrgPurchBillRequest = () => ({
  type: actionTypes.GET_LIST_ORG_PURCH_BILL_REQUEST
});

const getListOrgPurchBillSuccess = (res) => ({
  type: actionTypes.GET_LIST_ORG_PURCH_BILL_SUCCESS,
  res
});

const getListOrgPurchBillFailure = (error) => ({
  type: actionTypes.GET_LIST_ORG_PURCH_BILL_FAILURE,
  error
});

export const getListOrgPurchBill = () => {
  return async (dispatch) => {
    try {
      dispatch(getListOrgPurchBillRequest());
      let res = await requiredPublicUtils.getListOrg(3);
      dispatch(getListOrgPurchBillSuccess(res));
    } catch (error) {
      dispatch(getListOrgPurchBillFailure(error));
      console.error(error);
    }
  }
}

const getListOrgContractBillRequest = () => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_BILL_REQUEST
});

const getListOrgContractBillSuccess = (res) => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_BILL_SUCCESS,
  res
});

const getListOrgContractBillFailure = (error) => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_BILL_FAILURE,
  error
});

export const getListOrgContractBill = () => {
  return async (dispatch) => {
    try {
      dispatch(getListOrgContractBillRequest());
      let res = await requiredPublicUtils.getListOrg(2);
      dispatch(getListOrgContractBillSuccess(res));
    } catch (error) {
      dispatch(getListOrgContractBillFailure(error));
      console.error(error);
    }
  }
}

const getListOrgContractRequest = () => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_REQUEST
});

const getListOrgContractSuccess = (res) => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_SUCCESS,
  res
});

const getListOrgContractFailure = (error) => ({
  type: actionTypes.GET_LIST_ORG_CONTRACT_FAILURE,
  error
});

export const getListOrgContract = () => {
  return async (dispatch) => {
    try {
      dispatch(getListOrgContractRequest());
      let res = await requiredPublicUtils.getListOrg(4);
      dispatch(getListOrgContractSuccess(res));
    } catch (error) {
      dispatch(getListOrgContractFailure(error));
      console.error(error);
    }
  }
}

const getListOrgOrderRequest = () => ({
  type: actionTypes.GET_LIST_ORG_ORDER_REQUEST
});

const getListOrgOrderSuccess = (res) => ({
  type: actionTypes.GET_LIST_ORG_ORDER_SUCCESS,
  res
});

const getListOrgOrderFailure = (error) => ({
  type: actionTypes.GET_LIST_ORG_ORDER_FAILURE,
  error
});

export const getListOrgOrder = () => {
  return async (dispatch) => {
    try {
      dispatch(getListOrgOrderRequest());
      let res = await requiredPublicUtils.getListOrg(1);
      dispatch(getListOrgOrderSuccess(res));
    } catch (error) {
      dispatch(getListOrgOrderFailure(error));
      console.error(error);
    }
  }
}

const getListSubsidiesRequest = () => ({
  type: actionTypes.GET_LIST_SUBSIDIES_REQUEST
});

const getListSubsidiesSuccess = (res) => ({
  type: actionTypes.GET_LIST_SUBSIDIES_SUCCESS,
  res
});

const getListSubsidiesFailure = (error) => ({
  type: actionTypes.GET_LIST_SUBSIDIES_FAILURE,
  error
});

export const getListSubsidies = (filter, sort, page) => {
  return async (dispatch) => {
    try {
      dispatch(getListSubsidiesRequest());
      let res = await requiredPublicUtils.getListSubsidies(filter, sort, page);
      dispatch(getListSubsidiesSuccess(res));
    } catch (error) {
      dispatch(getListSubsidiesFailure(error));
      console.error(error);
    }
  }
}