const DRAWER_EL1 = 'drawerEl1',
  DRAWER_EL2 = 'drawerEl2',
  PRELOGIN_BTNS = 'preLoginBtns',
  PUBLIC_ZONE = 'publicZone',
  MY_ZONE = 'myZone',
  PARKING = 'parking',
  PARKING_DIALOG = 'parkingDialog',
  ACCOUNT_PERMIT_DIALOG = 'accountPermitDialog',
  ISpMnu = 'ISpMnu',
  IFrmTxt = 'IFrmTxt',
  IFrmBefTxt = 'IFrmBefTxt',
  IDefOb = 'IDefOb',
  ISpDefOb = 'ISpDefOb',
  IMnu = 'IMnu',
  PUBLIC_ZONE_SUBMENU = 'publicZoneSubmenu'

export { DRAWER_EL1, DRAWER_EL2, PRELOGIN_BTNS, PUBLIC_ZONE, MY_ZONE, PARKING, ISpMnu, IFrmTxt, IFrmBefTxt, IDefOb, ISpDefOb, IMnu, PARKING_DIALOG, ACCOUNT_PERMIT_DIALOG, PUBLIC_ZONE_SUBMENU };
