import React from 'react';
import ParkingIconOutlined from '@material-ui/icons/LocalParkingOutlined';
import PropTypes from 'prop-types';

class ParkingIconOutlinedComponent extends React.Component {
  render() {
    return (
      <ParkingIconOutlined
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    )
  }
}
ParkingIconOutlinedComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default ParkingIconOutlinedComponent;