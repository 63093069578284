import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class SocialServiceIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <path fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" d="M11.2,4.2
		c1.6-1.6,4.1-1.6,5.7,0c1.6,1.6,1.6,4.1,0,5.7L10,17L2.2,9.8c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0L13,9"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="8.3" y1="14.8" x2="11.7" y2="11.4" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="6.3" y1="12.8" x2="9.7" y2="9.4" />
        </g>
      </SvgIcon>
    );
  }
}

SocialServiceIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default SocialServiceIconComponent;
