import actionTypes from './actionTypes';
import * as publicUtils from '../../utils/publicZone/publicUtils';

const addParkingShortRequest = () => ({
  type: actionTypes.ADD_PARKING_SHORT_REQUEST
});

const addParkingShortSuccess = (res) => ({
  type: actionTypes.ADD_PARKING_SHORT_SUCCESS,
  res
});

const addParkingShortFailure = (error) => ({
  type: actionTypes.ADD_PARKING_SHORT_FAILURE,
  error
});

export const addParkingShort = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addParkingShortRequest());
      let res = await publicUtils.addParkingShort(data);
      const iDocPark = res.Data.I_DOC_PARK;
      dispatch(addParkingShortSuccess(res));
      return iDocPark;
    } catch (error) {
      dispatch(addParkingShortFailure(error));
      return -1;
    }
  }
}

const checkParkingTicketSuccess = (res) => ({
  type: actionTypes.CHECK_PARKING_TICKET_SUCCESS,
  res
});

const checkParkingTicketRequest = (page) => ({
  type: actionTypes.CHECK_PARKING_TICKET_REQUEST,
  page
});

const checkParkingTicketFailure = (error) => ({
  type: actionTypes.CHECK_PARKING_TICKET_FAILURE,
  error
});

export const checkParkingTicket = (iZone, ecv) => {
  return async (dispatch) => {
    try {
      dispatch(checkParkingTicketRequest());
      let res = await publicUtils.checkParkingTicket(iZone, ecv);
      dispatch(checkParkingTicketSuccess(res));
    } catch (error) {
      dispatch(checkParkingTicketFailure(error))
      console.error(error);
    }
  }
}


const getListCardTypeEShopSuccess = (res) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_SUCCESS,
  res
});

const getListCardTypeEShopRequest = () => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_REQUEST
});

const getListCardTypeEShopFailure = (error) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_ESHOP_FAILURE,
  error
});

export const getListCardTypeEShop = (iOtyp) => {
  return async (dispatch) => {
    try {
      dispatch(getListCardTypeEShopRequest());
      let res = await publicUtils.getListCardTypeEShop(iOtyp);
      dispatch(getListCardTypeEShopSuccess(res));
    } catch (error) {
      dispatch(getListCardTypeEShopFailure(error));
      console.error(error);
    }
  }
}


const addParkCardRequest = () => ({
  type: actionTypes.ADD_PARK_CARD_REQUEST
});

const addParkCardSuccess = (res) => ({
  type: actionTypes.ADD_PARK_CARD_SUCCESS,
  res
});

const addParkCardFailure = (error) => ({
  type: actionTypes.ADD_PARK_CARD_FAILURE,
  error
});

export const addParkCard = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addParkCardRequest());
      let res = await publicUtils.addParkCard(data);
      let parkingCard = null;
      if (res && res.Data)
        parkingCard = res.Data;
      dispatch(addParkCardSuccess(res));
      return parkingCard;
    } catch (error) {
      dispatch(addParkCardFailure(error));
      return null;
    }
  }
}

const checkParkingTicketPlanSuccess = (res) => ({
  type: actionTypes.CHECK_PARKING_TICKET_SUCCESS,
  res
});

const checkParkingTicketPlanRequest = (page) => ({
  type: actionTypes.CHECK_PARKING_TICKET_REQUEST,
  page
});

const checkParkingTicketPlanFailure = (error) => ({
  type: actionTypes.CHECK_PARKING_TICKET_FAILURE,
  error
});

export const checkParkingTicketPlan = (iZone, ecv, timeFrom, timeTo, dtFrom, dtTo) => {
  return async (dispatch) => {
    try {
      dispatch(checkParkingTicketPlanRequest());
      let res = await publicUtils.checkParkingTicketPlan(iZone, ecv, timeFrom, timeTo, dtFrom, dtTo);
      dispatch(checkParkingTicketPlanSuccess(res));
    } catch (error) {
      dispatch(checkParkingTicketPlanFailure(error))
      console.error(error);
    }
  }
}

const getParkingLimitSuccess = (res) => ({
  type: actionTypes.GET_PARKING_LIMIT_SUCCESS,
  res
});

const getParkingLimitRequest = () => ({
  type: actionTypes.GET_PARKING_LIMIT_REQUEST
});

const getParkingLimitFailure = (error) => ({
  type: actionTypes.GET_PARKING_LIMIT_FAILURE,
  error
});

export const getParkingLimit = (iZona, ecv, dtFrom, dtTo) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingLimitRequest());
      let res = await publicUtils.getParkingLimit(iZona, ecv, dtFrom, dtTo);
      dispatch(getParkingLimitSuccess(res));
    } catch (error) {
      dispatch(getParkingLimitFailure(error))
    }
  }
}

const getTicketTimeSumsSuccess = (res) => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_SUCCESS,
  res
});

const getTicketTimeSumsRequest = () => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_REQUEST
});

const getTicketTimeSumsFailure = (error) => ({
  type: actionTypes.GET_TICKET_TIME_SUMS_FAILURE,
  error
});

export const getTicketTimeSums = (iZona, ecv, dtFrom, dtTo) => {
  return async (dispatch) => {
    try {
      dispatch(getTicketTimeSumsRequest());
      let res = await publicUtils.getTicketTimeSums(iZona, ecv, dtFrom, dtTo);
      dispatch(getTicketTimeSumsSuccess(res));
    } catch (error) {
      dispatch(getTicketTimeSumsFailure(error))
    }
  }
}