import React from 'react';
import Search from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

class SearchIconComponent extends React.Component {
  render() {
    return (
      <Search
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    )
  }
}

SearchIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default SearchIconComponent;