import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

class ParkingPermitComponent extends React.Component {
  render() {
    return (
      <IconButton
        aria-label="Parkovacia karta - povolenie"
      >
        <SvgIcon
          style={{ width: "45", height: "30" }}
          viewBox="0 0 49.457 65"
        >
          <g class="a" style={{ fill: "none" }}>
            <path class="e" d="M0,0H31.595L49.457,15.436V65H0Z" />
            <path class="f" d="M 2 2 L 2 63 L 47.45652008056641 63 L 47.45652008056641 16.35120391845703 L 30.85030746459961 2 L 2 2 M 0 0 L 31.59477043151855 0 L 49.45652008056641 15.43624877929688 L 49.45652008056641 65 L 0 65 L 0 0 Z" style={{ fill: "#323232", stroke: "none" }} />
          </g>
          <g class="b" transform="translate(9 21)" style={{ fill: "#fff", stroke: "#323232" }}>
            <rect class="e" width="32" height="24" rx="2" style={{ stroke: "none" }} />
            <rect class="g" x="0.5" y="0.5" width="31" height="23" rx="1.5" style={{ fill: "none" }} />
          </g>
          <path class="c" d="M14.063,2.25H1.688A1.688,1.688,0,0,0,0,3.938V16.313A1.688,1.688,0,0,0,1.688,18H14.063a1.688,1.688,0,0,0,1.688-1.687V3.938A1.688,1.688,0,0,0,14.063,2.25ZM8.438,12.375H6.75v1.688a.564.564,0,0,1-.562.563H5.063a.564.564,0,0,1-.562-.562V6.188a.564.564,0,0,1,.563-.562H8.438a3.375,3.375,0,0,1,0,6.75Zm0-4.5H6.75v2.25H8.438a1.125,1.125,0,0,0,0-2.25Z" transform="translate(12.5 22.889)" style={{ fill: "#323232" }} />
          <line class="d" x2="7.294" transform="translate(30.25 27.75)" style={{ fill: "none", stroke: "#323232", strokeLinecap: "round" }} />
          <line class="d" x2="7.294" transform="translate(30.25 31.353)" style={{ fill: "none", stroke: "#323232" }} />
          <line class="d" x2="7.294" transform="translate(30.25 35.406)" style={{ fill: "none", stroke: "#323232" }} />
          <line class="d" x1="4" transform="translate(33.544 39.009)" style={{ fill: "none", stroke: "#323232" }} />
        </SvgIcon>
      </IconButton>
    )
  }
}

ParkingPermitComponent.propTypes = {
  style: PropTypes.object
}
export default ParkingPermitComponent;






