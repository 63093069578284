import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import routes from '../../routes';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import NavigationNext from '../../coraWebMComponents/dataDisplay/icons/NavigationNext';
import * as parkingActions from '../../actions/parkingActions';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import withRouter from '../../coraWebMComponents/utils/withRouter';

const styles = theme => ({
  ...getSharedStyles(theme),

  creditBackground: {
    backgroundColor: '#bdbdbd',
    position: 'relative',
    padding: '12px',
    zIndex: '10',
    height: '45px',
    borderRadius: '7px'
  },
  credit: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.main,
    top: '0px',
    left: '0px',
    height: '100%',
    opacity: '1',
    zIndex: '11',
    borderRadius: '7px'
  },
  nextButton: {
    position: 'absolute',
    right: '3px',
    top: '9px'
  },
})

let _iZadost, _iKarta;

class Credit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoad: true, text: '', };
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _iZadost = params.get("iZadost");
    _iKarta = params.get("iKarta");

    await this.props.dispatch(parkingActions.getParkingCard(_iKarta));
    console.log("🚀 ~ file: Credit.js:63 ~ Credit ~ componentDidMount= ~ getParkingCard:", this.props.parkingCard)
    await this.props.dispatch(parkingActions.getListParkingCredit(this.props.parkingCard?.I_KARTA_TYP, this.props.parkingCard?.I_KARTA_AKTUAL));
    console.log("🚀 ~ file: Credit.js:64 ~ Credit ~ componentDidMount= ~ getListParkingCredit:", this.props.listParkingCredit)

    const txt = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_CREDIT');

    this.setState({ isLoad: false, text: txt });
  }

  handleBack = () => {
    this.props.navigate(-1);
  }

  handleDrawList = async (iKredit) => {
    this.props.navigate({ pathname: routes.PARKING_CREDIT_DRAW_LIST, search: `?iZadost=${_iZadost}&iKarta=${_iKarta}&iKredit=${iKredit}` });
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, listParkingCredit, width } = this.props;
    return (
      <div className='container w-margin'>
        <CircularProgress loading={this.props.isLoading || this.state.isLoad} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
          <React.Fragment>
            <div className={classNames(classes.dFlex, classes.alignItemsCenter)}>
              <IconButton
                className={classNames(classes.backButton, classes.p0)}
                color="inherit"
                onClick={this.handleBack}
                toolTip="Späť"
                toolTipPlace="right"
                disableRipple
                disableHover
              >
                <ArrowBack
                  color='secondary'
                  variant='fab'
                  style={{ fontSize: 30 }}
                />
              </IconButton>
            </div>
            {listParkingCredit.map(x =>
              <div key={x.I_KREDIT.toString()} >
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt3)} >
                  <div></div><Typography variant="body1">{x.C_KREDIT} {x.N_MJ ? x.N_MJ : 'hod.'}</Typography>
                </div>
                <Paper elevation={7} className={classNames(classes.creditBackground)} >
                  <React.Fragment>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <div>
                        <Typography style={{ color: '#fff', zIndex: '99', position: 'absolute', left: '20px' }} variant="body1">{x.N_KREDIT_TYP}</Typography>
                        <Typography style={{ color: '#fff', zIndex: '99', position: 'absolute', top: '33px', left: '20px' }} variant="subtitle2">{(x.C_KREDIT - x.C_CERP_SPOLU).toFixed(2)} {x.N_MJ ? x.N_MJ : 'hod.'}</Typography>
                      </div>
                    </div>
                    <IconButton
                      disabled={this.props.parkingCard ? ([4, 5, 6, 7, 10, 11, 13].includes(this.props.parkingCard.I_KARTA_STAV) ? false : true) : true}
                      className={classNames(classes.nextButton)}
                      onClick={() => this.handleDrawList(x.I_KREDIT)}
                      toolTip="Prehľad čerpaní kreditu"
                      toolTipPlace="left"
                      style={{ zIndex: '99' }}
                    >
                      <NavigationNext style={{ color: '#fff', fontSize: 40 }} />
                    </IconButton>
                    <Paper elevation={0} className={classNames(classes.credit)} style={{ width: `${x.ZOSTATOK_PERC}%`, zIndex: '11' }} ></Paper>
                  </React.Fragment>
                </Paper>
              </div>
            )}
            {this.state.text.length > 0 &&
              <div className={classNames(classes.mt4,)}>
                <Typography variant="caption" >
                  {this.state.text}
                </Typography>
              </div>
            }
          </React.Fragment>
        </Paper>
      </div >
    );
  };
};

const mapStateToProps = (state) => {
  return {
    listParkingCredit: state.listParkingCredit.data,
    parkingCard: state.parkingCard.data.Data,
    isLoading: state.listParkingCredit.isLoading,
    locale: state.locale.locale,
  }
}

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(Credit))));


