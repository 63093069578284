import * as convert from '../../coraWebMComponents/utils/convert';
import CoraError from '../../coraWebMComponents/utils/coraError';
import { UNAUTHORIZED_MSG, MAINTENANCE_MSG, processFilter, parseFilenameFromContentDisposition } from '../../coraWebMComponents/utils/requests';

/** @module src\utils\publicZone\requests.js */

/**
 * Asynchronously returns response from get list of data.
 * @function getList
 * @param {string} url url
 * @param {object} filter filter
 * @param {object} sort sort
 * @param {object} page page
 * @returns {Object}
 */
export const getList = async (url, filter = { filters: [] }, sort = [], page = { skip: 0, take: 5 }, args = null) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  const skip = Number(page.skip);
  const take = Number(page.take);
  let _url = `${window.config.url}${url}?filter=${JSON.stringify(processedFilter)}&sort=${JSON.stringify({ sorts: sort })}&page=${Math.trunc(skip / take) + 1}&limit=${take}`;
  if (args) {
    _url = _url + `&${new URLSearchParams(args)}`;
  }
  let response = await fetch(_url);

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404) {
    return { Data: { Items: [], TotalRecords: page.skip } };
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns response get data.
 * @function get
 * @param {string} url url
 * @returns {Object}
 */
export const get = async (url) => {
  let response = await fetch(`${window.config.url}${url}`);

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404) {
    return { Data: null };
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronné vygenerovanie exportu
 * @function postPrint
 * @param {string} url Serverová URI
 * @param {object} filter Filter
 * @param {object} sort Triedenie
 * @param {object} fileType Typ výstupu xlsx/pdf
 * @param {object} schema Schema formulára
 * @param {object} db DB schéma
 * @returns {Object} Vygenerovaný export blob + filename
 */
export const postPrint = async (url, filter, sort, fileType, schema, db = null) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  let _url = `${window.config.url}${url}?filter=${encodeURIComponent(JSON.stringify(processedFilter))}&sort=${JSON.stringify({ sorts: sort })}&fileType=${fileType}`;
  if (db) {
    _url = _url + `&db=${db}`
  }
  const init = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "Content-Disposition",
    },
    body: JSON.stringify(schema),
  };
  const response = await fetch(_url, init);
  const filename = parseFilenameFromContentDisposition(response.headers.get("content-disposition"));

  if (response.status === 200) {
    return { blob: await response.blob(), filename };
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = { blob: await response.blob(), filename };
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns blob.
 * @function getBlob
 * @param {string} url url
 * @returns {Object}
 */
export const getBlob = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, { method: "GET" });
  if (response.status === 200) {
    return await response.blob();
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns blob with file name.
 * @function getBlobWithFileName
 * @param {string} url url
 * @returns {Object}
 */
export const getBlobWithFileName = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, { method: "GET", });
  if (response.status === 200) {
    let contentDisposition = response.headers.get("content-disposition");
    let filename = `doc${new Date().toISOString().replace(/[^0-9]/g, "")}${convert.getDefaultExtension(response.headers.get("content-type"))}`;
    if (contentDisposition !== null) {
      filename = contentDisposition
        .split(";")
        .find((n) => n.includes("filename="))
        .replace(/"/g, "")
        .replace('filename=', "")
        .trim();
    }
    if (filename.startsWith("=?utf-8?B?")) {
      filename = convert.base64ToUnicodeString(filename.replace("=?utf-8?B?", "").replace("?=", ""));
    }
    return { blob: await response.blob(), fileName: filename };
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};
