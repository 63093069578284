import React from 'react';
import FilterList from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import Paper from '../../surfaces/Paper';

class FilterListIconComponent extends React.Component {
  render() {
    return (
      <Paper
        elevation={2}
        className={this.props.className}
      >
        <FilterList
          style={this.props.style}
          color={this.props.color}
          fontSize={this.props.fontSize}
        />
      </Paper>
    )
  }
}

FilterListIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default FilterListIconComponent;
