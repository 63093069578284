import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class DefectsIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
        style={{
          ...this.props.style,
          stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
        }}
        className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="1,16 9,1 17,16 	
		"/>
          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="9" y1="6" x2="9" y2="11" />
          <circle cx="9" cy="13" r="1" />
        </g>
      </SvgIcon>
    );
  }
}

DefectsIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default DefectsIconComponent;
