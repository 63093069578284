import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'material-ui-pickers';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';


class DatePickerComponent extends React.Component {

  onChange = (mom) => {
    const datum = mom !== null ? mom.toDate() : null;
    this.props.onChange(this.props.name, datum);
  }

  render() {
    const { keyboards } = this.props;
    return (
      <DatePicker
        label={this.props.label}
        format={'D.M.YYYY'}
        disabled={this.props.disabled}
        disableFuture={this.props.disableFuture}
        disablePast={this.props.disablePast}
        disableOpenOnEnter
        clearable={this.props.clearable}
        animateYearScrolling={false}
        value={this.props.value}
        onChange={this.onChange}
        style={this.props.style}
        keyboard={keyboards}
        clearLabel={this.props.translate('datepicker.clearLabel')}
        cancelLabel={this.props.translate('datepicker.cancelLabel')}
        okLabel={this.props.translate('datepicker.okLabel')}
        invalidDateMessage={this.props.translate('datepicker.invalidDateMessage')}
        maxDateMessage={this.props.translate('datepicker.maxDateMessage')}
        minDateMessage={this.props.translate('datepicker.minDateMessage')}
        maxDate={new Date(this.props.maxDate)}
        fullWidth={this.props.fullWidth}
        className={this.props.className}

      />
    )
  }
}

DatePickerComponent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
  keyboards: PropTypes.bool.isRequired,
  disableFuture: PropTypes.bool.isRequired,
  disablePast: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  maxDate: PropTypes.string,
}

export default withLocalizationConsumer(DatePickerComponent);

