import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

class ShopIconComponent extends React.Component {
  render() {
    return (
      <SvgIcon
      style={{
        ...this.props.style,
        stroke: this.props.style?.stroke ? this.props.style.stroke : "#000"
      }}
      className={this.props.className}
        viewBox="0 0 20 20"
      >
        <g transform={this.props.style?.transform}>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="17,18 17,4.5 
		10,1 10,18 	"/>
          <polygon fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" points="1,18 1,8.5 7,6 
		7,18 	"/>

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="6" x2="12" y2="6" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="9" x2="12" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="12" x2="12" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="13" y1="15" x2="12" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="9" x2="14" y2="9" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="12" x2="14" y2="12" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="15" y1="15" x2="14" y2="15" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="10" x2="4" y2="10" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="13" x2="4" y2="13" />

          <line fill="none" strokeWidth="2" strokeLinejoin="round" strokeMiterlimit="10" x1="5" y1="16" x2="4" y2="16" />
        </g>
      </SvgIcon>
    );
  }
}

ShopIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};
export default ShopIconComponent;
