import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

/**
 * Titulok k detailu sluzby
 *
 * @param {*} { classes, listZstSlz, selectedISlz }
 * @return {*} 
 */
const ZstSlzDetailTitle = ({ classes, listZstSlz, selectedISlz }) => {
  if (!Boolean(listZstSlz.data) || listZstSlz.data.length === 0) {
    return null;
  }

  const zstSlz = listZstSlz.data.find((x) => x.ISlz === selectedISlz);

  return (
    <>
      <Typography variant={"h6"}>
        {zstSlz?.NSlz}
      </Typography>
      {Boolean(zstSlz?.TxtSlz) && zstSlz?.TxtSlz.length > 0 &&
        <Typography variant={"caption"} className={classNames(classes.textCaption)}>
          {zstSlz?.TxtSlz}
        </Typography>
      }

    </>
  );
};

ZstSlzDetailTitle.propTypes = {
  listZstSlz: PropTypes.object,
  selectedISlz: PropTypes.number,
}

export default ZstSlzDetailTitle;