import React from 'react';
import PropTypes from 'prop-types';
import CarIcon from '@material-ui/icons/DirectionsCar';

class CarIconComponent extends React.Component {
  render() {
    return (
      <CarIcon
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        className={this.props.className}
      />
    )
  }
}

CarIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  className: PropTypes.string,
}

export default CarIconComponent;