import React from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import PropTypes from 'prop-types';

class AddCircleComponent extends React.Component {
  render() {
    return (
      <AddCircle
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

AddCircleComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default AddCircleComponent;
