import { React, useState } from "react";
import { connect } from "react-redux";
import { Icon } from "leaflet";
import L from 'leaflet';
import MarkersCluster from "./MarkersCluster";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import List from "../../coraWebMComponents/dataDisplay/List";
import ListItem from "../../coraWebMComponents/dataDisplay/ListItem";

const styles = theme => ({
  ...getSharedStyles(theme),
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  item: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    border: "solid rgba(0,0,0,0.1) 1px",
  }
});

const ParkingMachineMarkers = (props) => {
  const [activeParkingMachine, setActiveParkingMachine] = useState(null);
  const { parkMachineMarkers, classes } = props;

  if (!parkMachineMarkers) return null;

  const markerList = parkMachineMarkers.map(marker => {
    return L.marker(new L.LatLng(marker.GPS_X, marker.GPS_Y), {
      icon: new Icon({ iconUrl: 'assets/parkAMT_marker.svg', iconSize: [40, 40] })
    },
    ).on('click', function (e) {
      setActiveParkingMachine(marker);
    });
  });

  const content = (
    <List
      style={{
        width: '100%',
      }}>
      <ListItem
        style={{
          paddingBottom: 0,
        }}>
        <div
          className={classes.item}
          style={{
            borderBottom: "none",
          }}>
          <Typography>
            Názov
          </Typography>
          <Typography>
            {activeParkingMachine ? activeParkingMachine.N_PARK_AMT : null}
          </Typography>
        </div>
      </ListItem>
      <ListItem
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}>
        <div
          className={classes.item}
          style={{
            borderBottom: "none",
          }}>
          <Typography>
            Číslo
          </Typography>
          <Typography>
            {activeParkingMachine ? activeParkingMachine.C_PARK_AMT : null}
          </Typography>
        </div>
      </ListItem>
      <ListItem
        style={{
          paddingBottom: 0,
          paddingTop: 0,
        }}>
        <div
          className={classes.item}
          style={{
            borderBottom: "none",
          }}>
          <Typography>
            Ulica
          </Typography>
          <Typography>
            {activeParkingMachine ? activeParkingMachine.N_U : null}
          </Typography>
        </div>
      </ListItem>
      <ListItem
        style={{
          paddingTop: 0,
        }}>
        <div className={classes.item}>
          <Typography>
            Spôsob úhrady
          </Typography>
          <Typography>
            {activeParkingMachine ? activeParkingMachine.N_SPOS_UHR : null}
          </Typography>
        </div>
      </ListItem>
    </List>
  );

  const onClose = () => {
    setActiveParkingMachine(null);
  }

  return (
    <>
      {markerList && <MarkersCluster markerList={markerList} />}
      <Dialog
        open={activeParkingMachine !== null}
        onClose={onClose}
        title="Parkovací automat"
        titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
        actions={[]}
        contentClasses={{ root: classNames(classes.textCenter, classes.alignCenter) }}
        content={content}
        closeButton={<div style={{ textAlign: 'right' }}><IconButton size='small' onClick={onClose} ><CloseIcon fontSize='small' /></IconButton></div>}
        titleStyle={{ paddingTop: 0 }}
      />
    </>
  );
}


export default withStyles(styles)(connect()(ParkingMachineMarkers));