import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  isLoaded: false,
  sessionId: 0
}

const zstSessionId = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ZST_SESSION_ID_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ZST_SESSION_ID_SUCCESS:
      let { isLoading, isLoaded, sessionId } = _initState;
      if (Boolean(action.res)) {
        isLoaded = true;
        sessionId = action.res;
      }
      return { isLoading, isLoaded, sessionId };

    case actionTypes.GET_ZST_SESSION_ID_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default zstSessionId;
