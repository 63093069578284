import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import Chip from '../../coraWebMComponents/inputs/Chip';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Button from '../../coraWebMComponents/inputs/Button';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import LibraryBooksIcon from '../../coraWebMComponents/dataDisplay/icons/LibraryBooksIcon';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import FilterListIcon from '../../coraWebMComponents/dataDisplay/icons/FilterListIcon';
import DoneAllIcon from '../../coraWebMComponents/dataDisplay/icons/DoneAllIcon';
import PersonIcon from '../../coraWebMComponents/dataDisplay/icons/PersonIcon';
import TextFieldsIcon from '../../coraWebMComponents/dataDisplay/icons/TextFieldIcon';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import CheckBox from '../../coraWebMComponents/inputs/CheckBox';

import * as subsidiesRatingActions from '../../actions/subsidiesRatingActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import Subsidy from './Subsidy';
import SubsidyDetail from './SubsidyDetail';
import ScrollTopButton from "../info/ScrollTopButton";
import { truncateText } from "./helper";
import { SUBSIDIES_DAYS_REMAINING, C_STAV } from "../../constants/subsidiesConsts";

const styles = theme => ({
  ...getSharedStyles(theme),
  expansionRoot: {
    boxShadow: 'none',
    borderTop: '1px solid #e1e1e1',
    '&:before': {
      display: 'none',
    },
  },
  subsidiesRatingHeader: {
    paddingBottom: '5px',
  },
  expansionExpanded: {
    margin: 'auto',
  },
  summaryRoot: {
    height: '50px',
    minHeight: '50px',
    padding: '0 20px'
  },
  summaryExpanded: {
    minHeight: '50px!important',
  },
  summaryContent: {
    minHeight: 'auto!important'
  },
  detailRoot: {
    padding: '0px 20px 20px 20px'
  },
  ziadostCardContent: {
    padding: '20px',
  },
});

const SubsidiesRating = (props) => {
  const [state, setState] = useState({
    showDetail: false,
    projectName: '',
    edit: false,
    lastShowedDetailId: null,
  });

  const [scrollTopButton, setScrollTopButton] = useState({
    showScrollTopButton: false,
    showScrollTopRef: null,
  });

  const [filterState, setFilterState] = useState({
    openFilter: false,
    txtTitle: '',

    filterIClk: null,
    filterNClk: '',
    filterIOblst: null,
    filterNOblst: '',
    filterNProj: '',
    filterNOZiad: '',
    filterIStav: 0,
    filterNStav: '',
    filterLZapClk: false,

    frmIClk: null,
    frmIOblst: null,
    frmProj: '',
    frmZiad: '',
    frmIStav: null,

    errorTextNProj: '',
    errorTextNOZiad: '',
  });

  const refTitle = useRef();
  const { classes, getListSubsidiesRating } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (props.person.I_O) {
        await props.getListSubsidiesRating(
          props.person.I_O,
          filterState.filterIStav,
          filterState.filterIClk,
          filterState.filterIOblst,
          filterState.filterNProj,
          filterState.filterNOZiad);
      }

      // nacitaj configuracne data
      // ziskaj priznak pre zobrazenie ID dotácie
      if (!props.visibleIdNumberSubsidy?.isLoading) {
        await props.getVisibleIdNumberSubsidy();
      }
      // URL pre ziadost o dotaciu
      if (!props.eFormUrlSubsidy?.isLoading) {
        await props.getEFormUrlSubsidy();
      }
      //nacitaj polozky filtrov
      if (!props.listSubsidyOblst?.isLoading) {
        await props.getFilterSubsidyOblst();
      }
      if (!props.listSubsidyClk?.isLoading) {
        await props.getFilterSubsidyClk();
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScroll = async (event) => {
    const target = event.target;

    if (target.scrollTop > 10 && !scrollTopButton.showScrollTopButton) {
      setScrollTopButton((prev) => ({ ...prev, showScrollTopButton: true, showScrollTopRef: target }));
    }
    if (target.scrollTop <= 10 && scrollTopButton.showScrollTopButton) {
      setScrollTopButton((prev) => ({ ...prev, showScrollTopButton: false, showScrollTopRef: target }));
    }
  };

  const getStavColor = (stav) => {
    return (C_STAV.find(item => item.I_STAV === stav))?.color ?? C_STAV[0].color;
  };

  const handleChange = async (name, value) => {
    switch (name) {
      case 'frmIStav':
        break;
      case 'frmIClk':
        break;
      default:
    }
    setFilterState({ ...filterState, [name]: value });
  };

  const handleShowDetail = async (upd, rec) => {
    setState({
      ...state,
      edit: upd,
      showDetail: true,
      projectName: rec.N_PROJ,
      lastShowedDetailId: rec.I_DT,
    });
    refTitle.current.scrollTop = 0;

    await props.getListSubsidyDetail(rec.I_DT);
    await props.getListSubsidyRatingDetail(rec.I_DT);
  };

  const handleShowUrl = async (fileId) => {
    // URL format: https://domena.sk/xxx/EFormDbFiller.ashx?FileID=179265&mode=view&header=1&footer=1

    const params = [{ fileId: fileId }, { mode: "view" }];
    params.push({ header: 1 });
    params.push({ footer: 1 });
    // params.push({ header: props.eFormUrlHeaderSubsidy.data.Data?.HODN ? 1 : 0 });
    // params.push({ footer: props.eFormUrlFooterSubsidy.data.Data?.HODN ? 1 : 0 });
    let url = props.eFormUrlSubsidy.data.Data?.HODN ?? "http://localhost:3000/EFormDbFiller.ashx";

    if (params.length > 0) {
      url += "?";
      // Vygenerovanie URL z parametrov
      params.forEach((param, index) => {
        const paramName = Object.keys(param)[0];
        const paramValue = param[paramName];
        url += paramName + "=" + encodeURIComponent(paramValue);
        if (index < params.length - 1) {
          url += "&";
        }
      });
    }

    // console.log("🚀 ~ handleShowUrl ~ url:", url);
    // const returnUrl = window.location.origin + _RETURN_PATH;
    // console.log("🚀 ~ handleShowUrl ~ window:", window);
    window.open(url, '_blank')?.focus();
  };

  const handleCloseDetail = () => {
    setState({ ...state, showDetail: false });

    //scroll na rodicovsku polozku
    const { lastShowedDetailId } = state;
    if (lastShowedDetailId) {
      setTimeout(() => { // HACK: na spustenie az po vsetkych asynchronnych setState
        const element = document.getElementById(lastShowedDetailId);
        if (element) {
          var headerOffset = 70; // podla vysky banera nastavit + 5px
          var elementPosition = element.offsetTop;
          var offsetPosition = elementPosition - headerOffset;
          if (scrollTopButton.showScrollTopRef) {
            scrollTopButton.showScrollTopRef.scrollTop = offsetPosition;
          }
        }
      }, 0);
    }
  };

  const handleScrollTopButtonClick = async (target) => {
    if (target?.scrollTop) {
      target.scrollTop = 0;
    }

    // Skry tlačidlo po posune na vrch
    setScrollTopButton(prevState => ({ ...prevState, showScrollTopButton: false }));
  };

  // const isMobile = (width) => {
  //   if (width === "xs" || width === "sm") {
  //     return true;
  //   }
  //   return false;
  // };

  const onSave = async () => {
    await props.updSubsidyRating({ TotalRecords: props.listSubsidyRatingDetail.length, Items: props.listSubsidyRatingDetail });
    await props.getListSubsidiesRating(
      props.person.I_O,
      filterState.filterIStav,
      filterState.filterIClk,
      filterState.filterIOblst,
      filterState.filterNProj,
      filterState.filterNOZiad
    );
    setState({ ...state, showDetail: false });

    //scroll na rodicovsku polozku
    const lastShowedDetailId = state.lastShowedDetailId;
    if (lastShowedDetailId) {
      setTimeout(() => { // HACK: na spustenie az po vsetkych asynchronnych setState
        const element = document.getElementById(lastShowedDetailId);
        if (element) {
          var headerOffset = 70; // podla vysky banera nastavit + 5px
          var elementPosition = element.offsetTop;
          var offsetPosition = elementPosition - headerOffset;
          if (scrollTopButton.showScrollTopRef) {
            scrollTopButton.showScrollTopRef.scrollTop = offsetPosition;
          }
        }
      }, 0);
    }
  };

  /*onSave = async () => {
    await props.listSubsidyRatingDetail.map(
      async d => props.changedId.indexOf(d.I_HOD) !== -1 &&
        await props.updSubsidyRating(d.I_HOD, typeof d.HOD === 'string' ? d.HOD : !d.HOD ? '' : d.HOD.toString()
        ));
    await props.getListSubsidiesRating(props.person.I_O, filterState.filterIStav);
    setState({ showDetail: false });
  };*/

  const getNClk = (iClk) => {
    return (props.listSubsidyClk.data.find(item => item.I_CLK === iClk))?.N_CLK ?? '';
  };

  const getNOblst = (iOblst) => {
    return (props.listSubsidyOblst.data.find(item => item.I_OBLST === iOblst))?.N_OBLST ?? '';
  };

  const getIStav = (iStav) => {
    return (C_STAV.find(item => item.I_STAV === iStav))?.N_STAV ?? '';
  };

  const handleFilter = async () => {
    let N_CLK = getNClk(filterState.frmIClk);
    let N_OBLST = getNOblst(filterState.frmIOblst);
    let N_STAV = getIStav(filterState.frmIStav);
    setFilterState({
      ...filterState,
      filterIClk: filterState.frmIClk,
      filterNClk: N_CLK,
      filterIOblst: filterState.frmIOblst,
      filterNOblst: N_OBLST,
      filterIStav: filterState.frmIStav,
      filterNStav: N_STAV,
      filterNProj: filterState.frmProj,
      filterNOZiad: filterState.frmZiad,
      openFilter: false
    });
    await props.getListSubsidiesRating(
      props.person.I_O,
      filterState.frmIStav,
      filterState.frmIClk,
      filterState.frmIOblst,
      filterState.frmProj,
      filterState.frmZiad
    );
  };

  const handleDeleteFilter = async (opt) => {
    switch (opt) {
      case 1:
        setFilterState({ ...filterState, filterIClk: null, filterNClk: null });
        await getListSubsidiesRating(
          props.person.I_O,
          filterState.filterIStav,
          null,
          filterState.filterIOblst,
          filterState.filterNProj,
          filterState.filterNOZiad
        );
        break;
      case 2:
        setFilterState({ ...filterState, filterIOblst: null, filterNOblst: null });
        await getListSubsidiesRating(
          props.person.I_O,
          filterState.filterIStav,
          filterState.filterIClk,
          null,
          filterState.filterNProj,
          filterState.filterNOZiad
        );
        break;
      case 3:
        setFilterState({ ...filterState, filterNProj: '' });
        await getListSubsidiesRating(
          props.person.I_O,
          filterState.filterIStav,
          filterState.filterIClk,
          filterState.filterIOblst,
          '',
          filterState.filterNOZiad
        );
        break;
      case 4:
        setFilterState({ ...filterState, filterNOZiad: '' });
        await getListSubsidiesRating(
          props.person.I_O,
          filterState.filterIStav,
          filterState.filterIClk,
          filterState.filterIOblst,
          filterState.filterNProj,
          ''
        );
        break;
      case 5:
        setFilterState({ ...filterState, filterIStav: 0 });
        await getListSubsidiesRating(
          props.person.I_O,
          0,
          filterState.filterIClk,
          filterState.filterIOblst,
          filterState.filterNProj,
          filterState.filterNOZiad
        );
        break;
      default:
    }
  };

  const handleShowFilter = () => {
    setFilterState({
      ...filterState,
      frmIClk: filterState.filterIClk,
      frmIOblst: filterState.filterIOblst,
      frmProj: filterState.filterNProj,
      frmZiad: filterState.filterNOZiad,
      frmIStav: filterState.filterIStav,
      openFilter: true
    });
  };

  const handleCloseFilter = () => {
    setFilterState({
      ...filterState,
      errorTextNProj: '',
      errorTextNOZiad: '',
      openFilter: false
    });
  };

  const handleChangeDisabled = async () => {
    setFilterState({ ...filterState, filterLZapClk: !filterState.filterLZapClk });
    // await getListSubsidiesRating(props.person.I_O, 0);
  };

  const fMelements =
    <div >
      <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Filtrovanie</Typography>

      <div className={classNames(classes.w100, classes.row, classes.alignItemsCenter, classes.mb4)}>
        <DoneAllIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '7px' }} />
        <SelectField
          floatingLabelText="Stav"
          defaultLabelText="Všetky žiadosti"
          value={filterState.frmIStav}
          onChange={handleChange}
          fullwidth={true}
          schema={{ label: 'N_STAV', value: 'I_STAV' }}
          data={C_STAV}
          name='frmIStav'
          className={classNames(classes.mr4)}
        />
      </div>

      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
        <LibraryBooksIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '7px' }} />
        {
          props.listSubsidyClk.data.length > 0 && <SelectField
            floatingLabelText="Hodnotiaci celok"
            value={filterState.frmIClk}
            onChange={handleChange}
            fullwidth={true}
            schema={{ label: 'N_CLK', value: 'I_CLK' }}
            data={props.listSubsidyClk.data.map(x => ({ ...x, N_CLK: truncateText(x.N_CLK, 40) }))}
            name='frmIClk'
            className={classNames(classes.mr4)}
          />
        }
      </div>

      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
        <PlaceIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '7px' }} />
        {
          props.listSubsidyOblst.data.length > 0 && <SelectField
            floatingLabelText="Oblasť"
            value={filterState.frmIOblst}
            onChange={handleChange}
            fullwidth={true}
            schema={{ label: 'N_OBLST', value: 'I_OBLST' }}
            data={props.listSubsidyOblst.data.map(x => ({ ...x, N_OBLST: truncateText(x.N_OBLST, 41) }))}
            name='frmIOblst'
            className={classNames(classes.mr4)}
          />
        }
      </div>

      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
        <TextFieldsIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '7px' }} />
        <TextField
          label="Názov žiadosti"
          fullwidth={true}
          value={filterState.frmProj}
          onChange={handleChange}
          name='frmProj'
          className={classNames(classes.mr4)}
          errorText={filterState.errorTextNProj}
          error={filterState.errorTextNProj ? true : false}
        />
      </div>

      {/* {filterState.errorTextNProj &&
        <div>
          <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }} >{filterState.errorTextNProj}</Typography>
        </div>
      } */}

      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
        <PersonIcon style={{ fontSize: 35, marginRight: '7px', marginLeft: '7px' }} />
        <TextField
          label="Meno žiadateľa"
          fullwidth={true}
          value={filterState.frmZiad}
          onChange={handleChange}
          name='frmZiad'
          className={classNames(classes.mr4)}
          errorText={filterState.errorTextNOZiad}
          error={filterState.errorTextNOZiad ? true : false}
        />
      </div>

      {/* {filterState.errorTextNOZiad &&
        <div>
          <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }} >{filterState.errorTextNOZiad}</Typography>
        </div>
      } */}

      <Divider variant='fullWidth' style={{ height: '2px' }} />
      <div className={classNames(classes.w100, classes.row)}>
        <Button
          variant="text"
          color="primary"
          size="large"
          className={classNames(classes.w100, classes.formButton)}
          onClick={() => handleCloseFilter()}
          style={{ height: '45px' }}
        ><Typography variant="body1" >Zrušiť</Typography></Button>
        <Button
          // disabled={filterState.errorTextNProj || filterState.errorTextNOZiad ? true : false}
          disabled={false}
          variant="contained"
          color="secondary"
          size="large"
          className={classNames(classes.w100, classes.formButton)}
          onClick={() => handleFilter()}
          style={{ height: '45px' }}
        ><Typography variant="body1" color="inherit">Filtrovať</Typography></Button>
      </div>
    </div>;

  return (
    <Box ref={refTitle} onScroll={onScroll}
      style={{ height: "calc(100vh - 70px)", overflow: "auto", marginTop: "70px" }}
    >
      <CircularProgress loading={props.isLoading || props.isLoadingDet || props.isLoadingRatDet} />

      {/* Sipka pre scroll nahor */}
      {scrollTopButton.showScrollTopButton && (
        <ScrollTopButton onClick={() => handleScrollTopButtonClick(scrollTopButton.showScrollTopRef)} />
      )
      }

      {!state.showDetail &&
        <Box className='container w-padding'
        // style={{ marginTop: "70px" }}
        >
          {
            ((props.listSubsidiesRating.length > 1 && filterState.filterIStav === null) || filterState.filterIStav !== null) &&
            <div className={classNames(classes.mb3)}>
              <div
                style={{ alignItems: 'start' }}
                className={classNames(classes.w100, classes.justifyContentBetween, classes.dFlex)}
              >
                <CheckBox
                  toolTip={
                    <div>
                      Zobraziť aj hodnotenia, ktoré majú zapnutý príznak
                      <br />
                      "Nezobrazovať hodnotiaci celok"
                    </div>
                  }
                  toolTipPlace="top"
                  checked={filterState.filterLZapClk}
                  value={filterState.filterLZapClk}
                  onChange={handleChangeDisabled}
                  label="Zobraziť neaktuálne hodnotenia"
                />

                <div
                  style={{ flexWrap: 'wrap', flexDirection: 'row-reverse' }}
                  className={classNames(classes.mt1, classes.mlAuto, classes.dFlex)}
                >
                  {filterState.filterIClk &&
                    <Chip
                      icon={<LibraryBooksIcon />}
                      label={truncateText(filterState.filterNClk, 15)}
                      title={filterState.filterNClk}
                      onDelete={() => handleDeleteFilter(1)}
                      style={{ backgroundColor: '#fff' }}
                      className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                    />
                  }
                  {filterState.filterIOblst &&
                    <Chip
                      icon={<PlaceIcon />}
                      label={truncateText(filterState.filterNOblst, 15)}
                      title={filterState.filterNOblst}
                      onDelete={() => handleDeleteFilter(2)}
                      style={{ backgroundColor: '#fff' }}
                      className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                    />
                  }
                  {filterState.filterNProj &&
                    <Chip
                      icon={<TextFieldsIcon />}
                      label={truncateText(filterState.filterNProj, 15)}
                      title={filterState.filterNProj}
                      onDelete={() => handleDeleteFilter(3)}
                      style={{ backgroundColor: '#fff' }}
                      className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                    />
                  }
                  {filterState.filterNOZiad &&
                    <Chip
                      icon={<PersonIcon />}
                      label={truncateText(filterState.filterNOZiad, 15)}
                      title={filterState.filterNOZiad}
                      onDelete={() => handleDeleteFilter(4)}
                      style={{ backgroundColor: '#fff' }}
                      className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                    />
                  }
                  {filterState.filterIStav > 0 &&
                    <Chip
                      icon={<DoneAllIcon />}
                      label={truncateText(filterState.filterNStav, 11)}
                      title={filterState.filterNStav}
                      onDelete={() => handleDeleteFilter(5)}
                      style={{ backgroundColor: '#fff' }}
                      className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                    />
                  }
                </div>

                <IconButton
                  className={classNames(classes.mr0, classes.mt1, classes.mb1)}
                  color="inherit"
                  onClick={() => handleShowFilter(true)}
                  toolTip="Filter"
                  toolTipPlace="bottom"
                >
                  <FilterListIcon
                    className={classNames(classes.paperWrapper, classes.pl1, classes.pr1)}
                  />
                </IconButton>
              </div>
            </div>
          }

          {props.listSubsidiesRating.sort((a, b) => b.I_CLK_O.toString().localeCompare(a.I_CLK_O.toString()) || a.I_DT - b.I_DT).length === 0 && filterState.filterIStav !== null &&
            <div className={classNames(classes.mb3)}>
              <Typography variant="button" style={{ fontWeight: 'bold' }}>Podľa výberu stavu sa nenašla ani jedna žiadosť.</Typography>
            </div>
          }

          {
            props.visibleIdNumberSubsidy?.data && props.eFormUrlSubsidy?.data && props.listSubsidiesRating
              .map(x => {
                let today = new Date();
                // today.setDate(today.getDate() + 65); //na testovacie ucely
                // today.setHours(0, 0, 0, 0);        //na testovacie ucely 
                let d_do = new Date(x.D_DO);
                let threeDaysAfter = new Date(today);
                threeDaysAfter.setDate(today.getDate() + SUBSIDIES_DAYS_REMAINING);
                // console.log("🚀 ~ SubsidiesRating ~ today:", today);
                // console.log("🚀 ~ SubsidiesRating ~ threeDaysAfter:", threeDaysAfter);

                if ((filterState.filterLZapClk === false && x.L_ZAP_CLK === true) || filterState.filterLZapClk === true) {
                  return (
                    <Box id={x.I_DT} key={x.I_DT}>
                      <Subsidy
                        data={x}
                        classes={classes}
                        onShowDetail={(upd) => handleShowDetail(upd, x)}
                        onShowUrl={handleShowUrl}
                        getColor={() => getStavColor(x.I_STAV)}
                        dateWarning={d_do < threeDaysAfter && x.I_STAV !== 2}
                        showDateTo={x.L_D_DO}
                      />
                    </Box>);
                }
                else {
                  return null;
                }
              })
          }
        </Box>
      }

      {state.showDetail &&
        <SubsidyDetail
          key={props.listSubsidyDetail.length > 0 ? props.listSubsidyDetail[0].I_PKRT_TXT : ''}
          data={props.listSubsidyDetail}
          dataRate={props.listSubsidyRatingDetail}
          classes={classes}
          handleClose={handleCloseDetail}
          projectName={state.projectName}
          edit={state.edit}
          onSave={onSave}
          onChange={props.changeSubsidyRating}
        />
      }

      <FormModal
        open={filterState.openFilter}
        onClose={() => handleCloseFilter()}
        elements={fMelements}
      />
    </Box >
  );
};

const mapStateToProps = state => ({
  person: state.person.data,
  listSubsidiesRating: state.listSubsidiesRating.data,
  isLoading: state.listSubsidiesRating.isLoading,
  isLoadingDet: state.listSubsidyDetail.isLoading,
  isLoadingRatDet: state.listSubsidyRatingDetail.isLoading,
  listSubsidyDetail: state.listSubsidyDetail.data,
  listSubsidyRatingDetail: state.listSubsidyRatingDetail.data,
  visibleIdNumberSubsidy: state.visibleIdNumberSubsidy,
  eFormUrlSubsidy: state.eFormUrlSubsidy,
  listSubsidyClk: state.listSubsidyClk,
  listSubsidyOblst: state.listSubsidyOblst,
  //changedId: state.listSubsidyRatingDetail.changedId,
}
);

const mapDispatchToProps = dispatch => ({
  getListSubsidiesRating: (iO, iStav, iCelok, iOblast, nazovAkcie, menoZiadatela) => dispatch(subsidiesRatingActions.getListSubsidiesRating(iO, iStav, iCelok, iOblast, nazovAkcie, menoZiadatela)),
  getListSubsidyDetail: (iDt) => dispatch(subsidiesRatingActions.getListSubsidyDetail(iDt)),
  getListSubsidyRatingDetail: (iDt) => dispatch(subsidiesRatingActions.getListSubsidyRatingDetail(iDt)),
  updSubsidyRating: (data) => dispatch(subsidiesRatingActions.updSubsidyRating(data)),
  changeSubsidyRating: (id, value) => dispatch(subsidiesRatingActions.changeSubsidyRating(id, value)),
  getVisibleIdNumberSubsidy: () => dispatch(ciselnikyActions.getVisibleIdNumberSubsidy()),
  getEFormUrlSubsidy: () => dispatch(ciselnikyActions.getEFormUrlSubsidy()),
  getFilterSubsidyOblst: () => dispatch(subsidiesRatingActions.getFilterSubsidyOblst()),
  getFilterSubsidyClk: () => dispatch(subsidiesRatingActions.getFilterSubsidyClk()),
}
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(SubsidiesRating)));