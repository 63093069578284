import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import OptionsButtonAdmin from "./OptionsButtonAdmin";
import AdminModeSpFrmTxt from "./AdminModeSpFrmTxt";
import AdminModeSpColumns from "./AdminModeSpColumns";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 * Vygeneruje tlacidlo a k nemu menu s polozkamy pre editaciu v admin mode
 *
 * @class AdminModeOptionsComponent
 * @extends {React.Component}
 */
const AdminModeOptionsComponent = (props) => {
  const classes = useStyles();
  const [frmType, setFrmType] = useState(null);

  const menuItems = [
    { id: "spFrmTxt", text: "Zmena textu nad formulárom" },
    { id: "spFrmCols", text: "Zmena položiek formulára" },
  ];

  /** Udalosť Otvorenie */
  const handleDialogOpen = (frmType) => {
    setFrmType(frmType);
  };

  const handleDialogClose = () => {
    setFrmType(null);
  };

  const { primaryField, isMobile, schema, adminMode, reloadSchema } = props;
  const befTxt = schema.Args.befTxt;

  if (!isMobile && adminMode.isActive) {
    const dialogParams = {};
    const items = menuItems.map((x) => {
      dialogParams[x.id] = {
        id: x.id,
        isOpen: x.id === frmType,
        title: x.text,
      };
      return { itemText: x.text, onClick: () => handleDialogOpen(x.id) };
    });

    return (
      <>
        <OptionsButtonAdmin items={items} />

        {dialogParams["spFrmTxt"].isOpen && (
          <AdminModeSpFrmTxt
            params={dialogParams["spFrmTxt"]}
            onClose={handleDialogClose}
            befTxt={befTxt}
            reloadSchema={reloadSchema}
          />
        )}

        {dialogParams["spFrmCols"].isOpen && (
          <AdminModeSpColumns
            primaryField={primaryField}
            params={dialogParams["spFrmCols"]}
            onClose={handleDialogClose}
            reloadSchema={reloadSchema}
          />
        )}
      </>
    );
  } else {
    return "";
  }
};

AdminModeOptionsComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};

export default withWidth()(
  connect(mapStateToProps)(AdminModeOptionsComponent)
);
