import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import Switch from '@material-ui/core/Switch';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import storage from '../../coraWebMComponents/utils/withStorage';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'

const styles = theme => ({
  ...getSharedStyles(theme),
  timeControlHolder: {
    borderRadius: '30px',
    border: '1px solid #e1e1e1'
  },
  btnOK: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  },
});

class TimeDialogComponent extends React.Component {
  state = {
    fullWidth: true,
    maxWidth: 'sm',
    // time: null,
    // minutes: -1
  };

  // handleMaxWidthChange = event => {
  //   this.setState({ maxWidth: event.target.value });
  // };

  // handleFullWidthChange = event => {
  //   this.setState({ fullWidth: event.target.checked });
  // };

  // includeMinutes = (dt, mins) => {
  //   const h = Math.floor(mins / 60);
  //   dt.setHours(h);
  //   const m = mins - h * 60;
  //   dt.setMinutes(m);
  // }

  // loadProps = () => {

  //   if (this.state.minutes < 0) {

  //     let dt = this.props.data.timeValue;
  //     let minutes = this.calculateMinutes(dt, this.props.data.metaData.dtDatum);

  //     if (this.props.data.isToTime) {
  //       if (minutes > this.props.data.metaData.minutesDo) {
  //         minutes = this.props.data.metaData.minutesDo;
  //         // this.includeMinutes(dt, minutes);
  //       }
  //     } else {
  //       if (minutes < this.props.data.metaData.minutesOd) {
  //         minutes = this.props.data.metaData.minutesOd;
  //         // this.includeMinutes(dt, minutes);
  //       }
  //     }

  //     this.setState({ minutes });
  //   }
  // }

  // addMinutes = (dt2, value) => {
  //   dt2.setSeconds(0, 0);
  //   let h = dt2.getHours();
  //   let m = h * 60 + dt2.getMinutes();
  //   m += value;
  //   h = Math.floor(m / 60);
  //   dt2.setHours(h);
  //   m -= h * 60;
  //   dt2.setMinutes(m);
  //   return dt2;
  // }

  // handleAddTime = (addHour) => {

  //   this.loadProps();

  //   let { minutes, dt } = this.state;
  //   switch (addHour) {
  //     case 1:
  //       minutes += -this.props.data.metaData.C_MIN_J;
  //       break;
  //     case 2:
  //       minutes += this.props.data.metaData.C_MIN_J;
  //       break;
  //     case 3:
  //       minutes += -60;
  //       break;
  //     case 4:
  //       minutes += 60;
  //       break;
  //     default:
  //   }

  //   switch (addHour) {
  //     case 1:
  //     case 3:
  //       if (minutes < this.props.data.metaData.minutesOd) {
  //         minutes = this.props.data.metaData.minutesOd;
  //       }
  //       break;
  //     case 2:
  //     case 4:
  //       if (minutes > this.props.data.metaData.minutesDo) {
  //         minutes = this.props.data.metaData.minutesDo;
  //       }
  //       break;
  //     default:
  //   }
  //   // this.includeMinutes(dt, minutes);

  //   // time = this.validTimeNinMax(time);
  //   // const hodiny = parseInt(time / 60);
  //   // const minuty = parseInt(time - (60 * hodiny));
  //   // var time_to = new Date();

  //   // if (this.state.timeMin > time_to)
  //   //   time_to = new Date(this.state.timeMin);

  //   // time_to.setMinutes(time_to.getMinutes() + time);

  //   this.setState({ minutes });
  // };

  // dateToString = (dt) => `${('00' + dt?.getDate()).slice(-2)}.${('00' + (dt?.getMonth() + 1)).slice(-2)}.${dt?.getFullYear()}`;

  // timeToString = (dt) => `${('00' + (dt?.getHours())).slice(-2)}:${('00' + dt?.getMinutes()).slice(-2)}`;

  // calculateMinutes = (dt, dt2 = new Date(this.dateToString(dt))) => (dt.getDate() - dt2.getDate()) * 24 * 60 + dt.getHours() * 60 + dt.getMinutes();

  render() {
    const { classes } = this.props;

    // this.loadProps();

    return (
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.props.data.showTimeDialog > 0}
        onClose={this.props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h6" inline="true">{this.props.data.dateTitle}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h4" inline="true" style={{ fontWeight: 'bold' }}>{this.props.data.dateValue}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="caption">{this.props.data.captionOdDo}</Typography>
            <Typography variant="caption">{this.props.data.captionCena}</Typography>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h6" inline="true">{this.props.data.timeTitle}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h2" inline="true" style={{ fontWeight: 'bold' }}>{this.props.data.timeValue}</Typography>
          </DialogContentText>
          <div className={classNames(classes.timeControlHolder, classes.mb3)}>
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 1)}
                size='small'
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
              </ButtonFab>
              <Typography variant="body1" style={{ textAlign: 'center' }} >{this.props.data.btnMinutes}</Typography>
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 2)}
                size='small'
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
              </ButtonFab>
            </div>
          </div>
          <div className={classNames(classes.timeControlHolder, classes.mb3)}>
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 3)}
                size='small'
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
              </ButtonFab>
              <Typography variant="body1" style={{ textAlign: 'center' }} >{this.props.data.btnHours}</Typography>
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 4)}
                size="small"
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
              </ButtonFab>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            size="large"
            variant="contained"
            color="secondary"
            className={classNames(classes.btnOK, classes.w100)}
          >
            {"OK"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TimeDialogComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleModifyTime: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default storage(withLocalizationConsumer(withStyles(styles)(TimeDialogComponent)));