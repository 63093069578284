import { React, useEffect, useState } from "react";
import { connect } from 'react-redux';
import MapPolygons from "./MapPolygons";
import * as mapConsts from '../../constants/mapConsts';
import { Pane } from "react-leaflet";
import ParkingMachineMarkers from "./ParkingMachineMarkers";
import withWidth from '@material-ui/core/withWidth';
import LayersControl from "./LayersControl";
import GpsFixedIcon from "../../coraWebMComponents/dataDisplay/icons/GpsFixedIcon";
import ButtonFab from "../../coraWebMComponents/inputs/ButtonFab";

const MapContent = ({ parkPlaces, parkZones, parkMachines, layersSettings, handleLocalize }) => {
  const [zoneSwitch, setZoneSwitch] = useState(true);
  const [placeSwitch, setPlaceSwitch] = useState(true);
  const [machineSwitch, setMachineSwitch] = useState(true);
  const [showLayersButton, setShowLayersButton] = useState(false);

  useEffect(() => {
    if (layersSettings.length > 0) {
      const zoneLayer = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_ZONES);
      const placeLayer = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_PLACES);
      const machineLayer = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_TICKET_MACHINE);

      setZoneSwitch(zoneLayer?.L_BEGIN);
      setPlaceSwitch(placeLayer?.L_BEGIN);
      setMachineSwitch(machineLayer?.L_BEGIN);

      if (zoneLayer?.L_ZOBRAZ === false && placeLayer?.L_ZOBRAZ === false && machineLayer?.L_ZOBRAZ === false) {
        setShowLayersButton(false);
      } else {
        setShowLayersButton(true);
      }
    }
  }, [layersSettings])

  const onSwitch = (type) => {
    switch (type) {
      case mapConsts.MAP_ZONES:
        setZoneSwitch(!zoneSwitch);
        break;
      case mapConsts.MAP_PLACES:
        setPlaceSwitch(!placeSwitch);
        break;
      case mapConsts.MAP_TICKET_MACHINE:
        setMachineSwitch(!machineSwitch);
        break;
      default:
        break;
    }
  }

  return (
    <>
      {showLayersButton &&
        <LayersControl
          onSwitch={(type) => onSwitch(type)}
          zoneSwitch={zoneSwitch}
          placeSwitch={placeSwitch}
          machineSwitch={machineSwitch}
        />
      }
      {placeSwitch &&
        <Pane name="parkovacieMiesta" style={{ zIndex: 500 }}>
          <MapPolygons polygons={parkPlaces} type={mapConsts.MAP_PLACES} />
        </Pane>}
      {zoneSwitch &&
        <Pane>
          <MapPolygons polygons={parkZones} type={mapConsts.MAP_ZONES} />
        </Pane>}
      {machineSwitch &&
        <Pane name="automaty" style={{ zIndex: 1000 }}>
          <ParkingMachineMarkers parkMachineMarkers={parkMachines} />
        </Pane>}
      <ButtonFab
        label=""
        onClick={handleLocalize}
        size="medium"
        style={{
          backgroundColor: "#000000",
          zIndex: "500",
          position: "absolute",
          bottom: "20px",
          right: "5px",
        }}
      >
        <GpsFixedIcon style={{ color: "#ffffff" }} />
      </ButtonFab>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    layersSettings: state.mapLayersSettings.data,
  }
}

export default connect(mapStateToProps)(withWidth()(MapContent));