import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
}

const mapLayersSettings = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_MAP_LAYERS_SETTINGS_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_MAP_LAYERS_SETTINGS_SUCCESS:
      return { isLoading: false, data: action.res?.Data?.Items, total: action.res?.Data?.TotalRecords ?? 0 }

    case actionTypes.GET_MAP_LAYERS_SETTINGS_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default mapLayersSettings;