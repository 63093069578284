import * as requests from "../coraWebMComponents/utils/requests";
import { IFrmTxt, ISpMnu } from "../constants/adminConsts";

export const getMenuNoAuth = async (id) => {
  const url = `/api/pz/menu/${id}`;
  try {
    return await requests.get(url);
  } catch (error) {
    throw error;
  }
};

export const getForm = async (primaryField, id) => {
  const url = `/api/meta/form/${primaryField}/${id}`;
  try {
    return await requests.get(url);
  } catch (error) {
    throw error;
  }
};

export const updateOrAdd = async (primaryField, data) => {
  if (!data) return;
  for (const [idx, field] of data.entries()) {
    let structField = null;
    if (primaryField === ISpMnu) {
      structField = structISpMnuField(field, idx);
    } else if (primaryField === IFrmTxt) {
      structField = await structIFrmTxtField(field);
    } else {
      structField = field;
    }
    const url = `/api/meta/save/${primaryField}/${structField[primaryField]}`;
    try {
      await requests.put(url, structField);
    } catch (error) {
      throw error;
    }
  }
};

const structIFrmTxtField = async (field) => {
  const url = `/api/meta/localization?key=${field.N_KLUC}&locale=${field.locale}`;
  try {
    const frm = (await requests.get(url)).Data[0];
    return {
      ...frm,
      TxtFrm: field.TxtFrm,
    }
  } catch (error) {
    throw error;
  }

};

const structISpMnuField = (field, position) => {
  // TO:DO Chybna struktura
  const { MenuID, Title, Visible, Tooltip } = field;
  return {
    ISpMnu: MenuID,
    SpMnu: {
      IMnu: MenuID,
      NMnu: Title,
    },
    NMnu: Title,
    NNapoved: Tooltip,
    IZobraz: Visible,
    CZorad: position,
  };
};

export const updateSpDefOb = async (id, data) => {
  const url = `/api/meta/spDefOb/${id}`;
  try {
    await requests.put(url, data);
  } catch (error) {
    throw error;
  }
};

export const getSpFrm = async (primaryField) => {
  const url = `/api/meta/spFrm/${primaryField}`;
  try {
    return await requests.get(url);
  } catch (error) {
    throw error;
  }
};

export const postSpFrm = async (primaryField, data) => {
  if (!data) return;
  const url = `/api/meta/spFrm/${primaryField}`;
  try {
    return await requests.post(url, data);
  } catch (error) {
    throw error;
  }
};

export const getSpColumns = async (primaryField) => {
  const url = `/api/meta/spColumns/${primaryField}`;
  try {
    return await requests.get(url);
  } catch (error) {
    throw error;
  }
};

export const postSpColumns = async (primaryField, data) => {
  if (!data) return;
  const url = `/api/meta/spColumns/${primaryField}`;
  try {
    return await requests.post(url, data);
  } catch (error) {
    throw error;
  }
};

/**
 * Úprava záznamu v tabuľke SP_FRM_TXT, resp pridanie, ak záznam neexistuje.
 * @param {*} data Zdrojový JSon SpFrmTxtVm
 */
export const updateOrAddSpFrmTxt = async (data) => {
  if (data) {
    const url = `/api/meta/save/${IFrmTxt}/${data[IFrmTxt]}`;
    try {
      return await requests.put(url, data);
    } catch (error) {
      throw error;
    }
  }
};
