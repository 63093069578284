const actionTypes = {

  GET_LIST_CONTRACT_BILL_REQUEST: 'GET_LIST_CONTRACT_BILL_REQUEST',
  GET_LIST_CONTRACT_BILL_SUCCESS: 'GET_LIST_CONTRACT_BILL_SUCCESS',
  GET_LIST_CONTRACT_BILL_FAILURE: 'GET_LIST_CONTRACT_BILL_FAILURE',

  GET_LIST_PURCH_BILL_REQUEST: 'GET_LIST_PURCH_BILL_REQUEST',
  GET_LIST_PURCH_BILL_SUCCESS: 'GET_LIST_PURCH_BILL_SUCCESS',
  GET_LIST_PURCH_BILL_FAILURE: 'GET_LIST_PURCH_BILL_FAILURE',

  GET_LIST_CONTRACT_REQUEST: 'GET_LIST_CONTRACT_REQUEST',
  GET_LIST_CONTRACT_SUCCESS: 'GET_LIST_CONTRACT_SUCCESS',
  GET_LIST_CONTRACT_FAILURE: 'GET_LIST_CONTRACT_FAILURE',

  GET_LIST_ORDER_REQUEST: 'GET_LIST_ORDER_REQUEST',
  GET_LIST_ORDER_SUCCESS: 'GET_LIST_ORDER_SUCCESS',
  GET_LIST_ORDER_FAILURE: 'GET_LIST_ORDER_FAILURE',

  GET_LIST_REQUEST_GRID_REQUEST: 'GET_LIST_REQUEST_GRID_REQUEST',
  GET_LIST_REQUEST_GRID_SUCCESS: 'GET_LIST_REQUEST_GRID_SUCCESS',
  GET_LIST_REQUEST_GRID_FAILURE: 'GET_LIST_REQUEST_GRID_FAILURE',

  GET_LIST_ORG_CONTRACT_BILL_REQUEST: 'GET_LIST_ORG_CONTRACT_BILL_REQUEST',
  GET_LIST_ORG_CONTRACT_BILL_SUCCESS: 'GET_LIST_ORG_CONTRACT_BILL_SUCCESS',
  GET_LIST_ORG_CONTRACT_BILL_FAILURE: 'GET_LIST_ORG_CONTRACT_BILL_FAILURE',

  GET_LIST_ORG_PURCH_BILL_REQUEST: 'GET_LIST_ORG_PURCH_BILL_REQUEST',
  GET_LIST_ORG_PURCH_BILL_SUCCESS: 'GET_LIST_ORG_PURCH_BILL_SUCCESS',
  GET_LIST_ORG_PURCH_BILL_FAILURE: 'GET_LIST_ORG_PURCH_BILL_FAILURE',

  GET_LIST_ORG_CONTRACT_REQUEST: 'GET_LIST_ORG_CONTRACT_REQUEST',
  GET_LIST_ORG_CONTRACT_SUCCESS: 'GET_LIST_ORG_CONTRACT_SUCCESS',
  GET_LIST_ORG_CONTRACT_FAILURE: 'GET_LIST_ORG_CONTRACT_FAILURE',

  GET_LIST_ORG_ORDER_REQUEST: 'GET_LIST_ORG_ORDER_REQUEST',
  GET_LIST_ORG_ORDER_SUCCESS: 'GET_LIST_ORG_ORDER_SUCCESS',
  GET_LIST_ORG_ORDER_FAILURE: 'GET_LIST_ORG_ORDER_FAILURE',

  GET_LIST_SUBSIDIES_REQUEST: 'GET_LIST_SUBSIDIES_REQUEST',
  GET_LIST_SUBSIDIES_SUCCESS: 'GET_LIST_SUBSIDIES_SUCCESS',
  GET_LIST_SUBSIDIES_FAILURE: 'GET_LIST_SUBSIDIES_FAILURE',

  ADD_PARKING_SHORT_REQUEST: 'ADD_PARKING_SHORT_REQUEST',
  ADD_PARKING_SHORT_SUCCESS: 'ADD_PARKING_SHORT_SUCCESS',
  ADD_PARKING_SHORT_FAILURE: 'ADD_PARKING_SHORT_FAILURE',

  GET_LIST_CARD_TYPE_ESHOP_REQUEST: 'GET_LIST_CARD_TYPE_ESHOP_REQUEST',
  GET_LIST_CARD_TYPE_ESHOP_SUCCESS: 'GET_LIST_CARD_TYPE_ESHOP_SUCCESS',
  GET_LIST_CARD_TYPE_ESHOP_FAILURE: 'GET_LIST_CARD_TYPE_ESHOP_FAILURE',

  ADD_PARK_CARD_REQUEST: 'ADD_PARK_CARD_REQUEST',
  ADD_PARK_CARD_SUCCESS: 'ADD_PARK_CARD_SUCCESS',
  ADD_PARK_CARD_FAILURE: 'ADD_PARK_CARD_FAILURE',

  CHECK_PARKING_TICKET_REQUEST: 'CHECK_PARKING_TICKET_REQUEST',
  CHECK_PARKING_TICKET_SUCCESS: 'CHECK_PARKING_TICKET_SUCCESS',
  CHECK_PARKING_TICKET_FAILURE: 'CHECK_PARKING_TICKET_FAILURE',

  GET_PARKING_LIMIT_REQUEST: 'GET_PARKING_LIMIT_REQUEST',
  GET_PARKING_LIMIT_SUCCESS: 'GET_PARKING_LIMIT_SUCCESS',
  GET_PARKING_LIMIT_FAILURE: 'GET_PARKING_LIMIT_FAILURE',

  GET_TICKET_TIME_SUMS_REQUEST: 'GET_TICKET_TIME_SUMS_REQUEST',
  GET_TICKET_TIME_SUMS_SUCCESS: 'GET_TICKET_TIME_SUMS_SUCCESS',
  GET_TICKET_TIME_SUMS_FAILURE: 'GET_TICKET_TIME_SUMS_FAILURE',
}

export default actionTypes;