import { React, useState, useEffect } from "react";
import { connect } from 'react-redux';
import * as mapConsts from '../../constants/mapConsts';
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import List from "../../coraWebMComponents/dataDisplay/List";
import ListItem from "../../coraWebMComponents/dataDisplay/ListItem";
import { Switch } from "@material-ui/core";

const styles = theme => ({
  ...getSharedStyles(theme),
  item: {
    width: "100%",
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },

})

const LayersControl = ({ classes, onSwitch, zoneSwitch, placeSwitch, machineSwitch, layersSettings }) => {
  const [open, setOpen] = useState(false);
  const [showZones, setShowZones] = useState(false);
  const [showPlaces, setShowPlaces] = useState(false);
  const [showMachines, setShowMachines] = useState(false);

  const onClose = () => {
    setOpen(false);
  }

  const onOpen = () => {
    setOpen(true);
  }

  const title = (
    <div className={classNames(classes.dFlex, classes.justifyContentCenter, classes.alignItemsCenter)} >
      <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src={'assets/layers_icon.svg'} alt="layers_icon.svg"></img>
      Mapa
    </div>
  )

  useEffect(() => {
    if (layersSettings.length > 0) {
      const showZones = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_ZONES)?.L_ZOBRAZ;
      const showPlaces = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_PLACES)?.L_ZOBRAZ;
      const showMachines = layersSettings.find(layer => layer.I_MAP_VRST_GEO === mapConsts.MAP_TICKET_MACHINE)?.L_ZOBRAZ;
      setShowZones(showZones);
      setShowPlaces(showPlaces);
      setShowMachines(showMachines);
    }
  }, [layersSettings])

  const content = (
    <List>
      {showZones && <ListItem>
        <div className={classes.item}>
          <Typography className={classNames(classes.dFlex, classes.alignItemsCenter)}>
            <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={'assets/polygon.svg'} alt="polygon.svg"></img>
            Parkovacie zóny
          </Typography>
          <Switch
            className={classes.switch}
            checked={zoneSwitch}
            onChange={() => onSwitch(mapConsts.MAP_ZONES)}
          />
        </div>
      </ListItem>}
      {showPlaces && <ListItem>
        <div className={classes.item}>
          <Typography className={classNames(classes.dFlex, classes.alignItemsCenter)}>
            <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={'assets/polygon.svg'} alt="polygon.svg"></img>
            Parkovacie miesta
          </Typography>
          <Switch
            className={classes.switch}
            checked={placeSwitch}
            onChange={() => onSwitch(mapConsts.MAP_PLACES)}
          />
        </div>
      </ListItem>}
      {showMachines && <ListItem>
        <div className={classes.item}>
          <Typography className={classNames(classes.dFlex, classes.alignItemsCenter)}>
            <img style={{ width: "20px", height: "20px", marginRight: "10px" }} src={'assets/point.svg'} alt="point.svg"></img>
            Parkovacie automaty
          </Typography>
          <Switch
            className={classes.switch}
            checked={machineSwitch}
            onChange={() => onSwitch(mapConsts.MAP_TICKET_MACHINE)}
          />
        </div>
      </ListItem>}
    </List>
  );

  const closeButton = (
    <div style={{ textAlign: 'right' }}>
      <IconButton disableTooltip={true} size='small' onClick={onClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </div>
  )

  return (
    <>
      <div class="leaflet-top leaflet-right">
        <IconButton
          disableTooltip={true}
          className="leaflet-control"
          onClick={onOpen}
          style={{
            height: "50px",
            width: "50px",
            backgroundColor: "black",
          }}>
          <img style={{ width: "30px", height: "30px" }} src={'assets/layers_icon_white.svg'} alt="layers_icon.svg"></img>
        </IconButton>
      </div>
      <Dialog
        open={open}
        onClose={onClose}
        title={title}
        titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
        actions={[]}
        contentClasses={{ root: classNames(classes.textCenter, classes.alignCenter) }}
        content={content}
        closeButton={closeButton}
      />
    </>

  )
}

const mapStateToProps = (state) => {
  return {
    layersSettings: state.mapLayersSettings.data,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(LayersControl));
