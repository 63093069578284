import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import routes from '../../routes';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import PaymentGateway from '../../coraWebMComponents/PaymentGateway';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import * as storage from '../../coraWebMComponents/utils/storage';
import withRouter from '../../coraWebMComponents/utils/withRouter';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {},
  paperCard: {
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px',
    },
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  }
});

const PAYMENT_WAYBILL_KEY = 'PaymentWaybill';
let _vs = "";
let _transactionId = "";
let _waybill = null;
let isOk = true;

class PayRecieved extends React.Component {
  constructor(props) {
    super(props);
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _vs = params.get("VS");
    _transactionId = params.get("transactionId");

    let payType = 1;      //parkovacia karta
    isOk = true;
    let status = 0;

    if (_transactionId) { // riesenie CG HD 70888

      // nacitanie sprievodky zo storage a odstranenie zo storage
      _waybill = storage.getItem(PAYMENT_WAYBILL_KEY);
      storage.removeItem(PAYMENT_WAYBILL_KEY);
      if (null != _waybill) {

        payType = _waybill.payType;
        if (_transactionId === _waybill.transactionId) {
          // OK
        } else {
          // chyba - sem by sa to nemalo dostat
        }
      } else {
        isOk = false;
        status = 4;
      }
    } else if (_vs) { // povodne riesenie

      if (_vs.substring(0, 1) === "9") {
        payType = 2;        //parkovaci listok
      } else if (_vs.substring(0, 1) === "8") {
        payType = 3;        //poplatky a dane
      } else {
        payType = 1;        //parkovacia karta
      }
    }

    this.state = { status, payType };
  };

  /** navrat na povodnu stranku */
  handleBack = () => {
    let searchValue = routes.PRELOGIN;
    if (isOk) {
      if (this.state.payType === 1 && this.props.isAuth)
        searchValue = routes.PARKING;

      else if (this.state.payType === 2)
        searchValue = routes.PZ_PARKING_SHORT;

      else if (this.state.payType === 3)
        searchValue = routes.TAXES;
    }
    this.props.navigate(searchValue);
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  handleVerify = (res) => {
    this.setState({ status: res });
    if (res > 1)
      console.error('PaymentGateway', false);
  }

  render() {

    const { classes, width } = this.props;

    // nastavenie url pre overenie platby
    let url = null;
    const payMethod = _waybill?.payMethod ?? 0;
    switch (this.state.payType) {
      case 1:
      case 2:
      default:
        url = payMethod === 1 ? "/api/parking/payment-verification" : "/api/parking/payment-received"
        break;
      case 3:
        url = payMethod === 1 ? "/api/taxes/payment-verification" : "/api/taxes/payment-received"
        break;
    }

    return (
      <div className='container' style={{ marginTop: '70px' }}>
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
          <CircularProgress loading={this.state.status === 0 ? true : false} />
          <div className={classNames(classes.mb4)}>
            <IconButton
              className={classNames(classes.p0)}
              color="inherit"
              onClick={this.handleBack}
              toolTip={this.props.translate('back')}
              toolTipPlace="right"
              disableHover
              disableRipple
            >
              <ArrowBack
                color='secondary'
                variant='fab'
                style={{ fontSize: 30 }}
              />
            </IconButton>
          </div>
          {isOk &&
            <PaymentGateway
              onVerify={this.handleVerify}
              url={url}
              waybill={_waybill}
            />
          }
          {this.state.status === 0 &&
            <div className={classNames(classes.mt6, classes.textCenter)}>
              <Typography className={classNames(classes.textCenter, classes.fontBold)} >
                {this.props.translate('payment.progress')}
              </Typography>
            </div>
          }
          {this.state.status === 1 &&
            <div>
              <div className='center'>
                <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='PLATBA ÚSPEŠNÁ' />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    Platba za parkovaciu kartu prebehla úspešne.
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    {this.props.translate('payment.pakingSuccess')}
                  </Typography>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    Platba za dane a poplatky prebehla úspešne.
                  </Typography>
                }
              </div>
            </div>
          }
          {(this.state.status === 2 || this.state.status === 3) &&
            <div>
              <div className='center'>
                <InfoIcon style={{ width: 140, height: 140, color: '#ef6c00' }} />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    Overenie platby za parkovaciu kartu sa nepodarilo!
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    {this.props.translate('payment.pakingVerify')}
                  </Typography>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: '#ef6c00' }}>
                    Overenie platby za dane a poplatky sa nepodarilo!
                  </Typography>
                }
              </div>
            </div>
          }
          {this.state.status === 4 &&
            <div>
              <div className='center'>
                <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/failure_red.svg'} alt='PLATBA NEÚSPEŠNÁ' />
              </div>
              <div className={classNames(classes.mt3, classes.textCenter)}>
                {this.state.payType === 1 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'red' }}>
                    Platba za parkovaciu kartu sa nepodarila!
                  </Typography>
                }
                {this.state.payType === 2 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'red' }}>
                    {this.props.translate('payment.pakingFailure')}
                  </Typography>
                }
                {this.state.payType === 3 &&
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'red' }}>
                    Platba za dane a poplatky sa nepodarila!
                  </Typography>
                }
              </div>
            </div>
          }
        </Paper>
      </div >
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
  }
}

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(PayRecieved)))))