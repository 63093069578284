import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
}));

/**
 * Komponent pre vykreslenie oznamu, napr. pri neexistencii dat
 *
 * @param {*} { classes, infoText }
 * @return {*} 
 */
const WarningNoData = ({ _classes = {}, infoText = 'Požadované údaje nie sú k dispozícii.' }) => {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={1}
        className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentCenter, classes.pt3, classes.pb3, classes.navigationBackButton)}
        style={{ boxSizing: "border-box" }}
      >
        <Stack flexDirection={"row"} gap={1} p={1} alignItems="flex-start">
          <InfoIcon />
          <Typography variant={"body1"} style={{ textAlign: "center" }}>{infoText}</Typography>
        </Stack>
      </Paper>
    </>
  );
};

WarningNoData.propTypes = {
  _classes: PropTypes.object,
  infoText: PropTypes.string,
};

export default WarningNoData;