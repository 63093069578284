import React from 'react';
import _ from 'lodash';

const IsRowEqual = (prevProps, newProps) => {
  return JSON.stringify(prevProps.dataItem) === JSON.stringify(newProps.dataItem) 
    && _.isEqual(prevProps.selectedRowCells, newProps.selectedRowCells)
    && prevProps.columns === newProps.columns 
    && prevProps.multiple === newProps.multiple 
    && prevProps.hidden === newProps.hidden 
    && prevProps.disabled === newProps.disabled 
    && newProps.dataItem.inEdit === undefined
    && !newProps.isPicker;
}

export const MemoRow = React.memo((props) => {
  const {trElement} = props;
  return trElement;
}, IsRowEqual);
